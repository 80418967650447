export default class ListOrderReq {
  constructor(
    page,
    size,
    username,
    customerId,
    orderDate,
    ltOrderDate,
    gtOrderDate,
    platform,
    resultStatus,
    processStatus,
  ) {
    this.page = page
    this.size = size
    this.username = username
    this.customerId = customerId
    this.orderDate = orderDate
    this.ltOrderDate = ltOrderDate
    this.gtOrderDate = gtOrderDate
    this.platform = platform
    this.resultStatus = resultStatus
    this.processStatus = processStatus
  }

  static of(data) {
    const {
      page,
      size,
      username,
      customerId,
      orderDate,
      ltOrderDate,
      gtOrderDate,
      platform,
      resultStatus,
      processStatus,
    } = data
    return new ListOrderReq(
      page,
      size,
      username,
      customerId,
      orderDate,
      ltOrderDate,
      gtOrderDate,
      platform,
      resultStatus,
      processStatus,
    )
  }
}

import actions from 'redux/cache/actions'
import CacheList from 'model/cache/CacheList'

const initialState = {
  list: CacheList.empty(),
}

export default function cacheReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}

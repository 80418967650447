export default class UpdateCategoryReq {
  constructor(categoryId, name, desc, prio, hidden, parentId) {
    this.categoryId = categoryId
    this.name = name
    this.desc = desc
    this.prio = prio
    this.hidden = hidden
    this.parentId = parentId
  }

  static of(data) {
    const { categoryId, name, desc, prio, hidden, parentId } = data
    return new UpdateCategoryReq(categoryId, name, desc, prio, hidden, parentId)
  }
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import { history } from 'index'
import * as aboutService from 'services/about'
import actions from 'redux/about/actions'
import About from 'model/about/About'
import AboutList from 'model/about/AboutList'
import CreateAboutReq from 'model/about/req/CreateAboutReq'
import UpdateAboutReq from 'model/about/req/UpdateAboutReq'

export function* LIST_ABOUT() {
  yield put({
    type: 'about/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(aboutService.listAbout)
  if (data) {
    yield put({
      type: 'about/SET_STATE',
      payload: {
        list: AboutList.fromData(data),
        detail: About.empty()
      }
    })
  }

  yield put({
    type: 'about/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_ABOUT({ payload }) {
  yield put({
    type: 'about/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { aboutId } = payload
  const data = yield call(aboutService.readAbout, aboutId)
  if (data) {
    yield put({
      type: 'about/SET_STATE',
      payload: {
        detail: About.fromData(data)
      }
    })
  }

  yield put({
    type: 'about/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_ABOUT({ payload }) {
  yield put({
    type: 'about/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(aboutService.createAbout, CreateAboutReq.of(payload))
  if (data) {
    yield put({
      type: 'about/ADD_STATE',
      payload: {
        about: About.fromData(data)
      }
    })
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'about/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_ABOUT({ payload }) {
  yield put({
    type: 'about/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(aboutService.updateAbout, UpdateAboutReq.of(payload))
  if (success) {
    yield history.push('/company/about/view')
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'about/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_ABOUT({ payload }) {
  yield put({
    type: 'about/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { aboutId } = payload
  const success = yield call(aboutService.deleteAbout, aboutId)
  if (success) {
    yield put({
      type: 'about/DELETE_STATE',
      payload: { aboutId }
    })
  }

  yield put({
    type: 'about/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_ABOUT, LIST_ABOUT),
    takeEvery(actions.READ_ABOUT, READ_ABOUT),
    takeEvery(actions.CREATE_ABOUT, CREATE_ABOUT),
    takeEvery(actions.UPDATE_ABOUT, UPDATE_ABOUT),
    takeEvery(actions.DELETE_ABOUT, DELETE_ABOUT),
  ])
}

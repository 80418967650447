export default class CreateBannerReq {
  constructor(content, prio) {
    this.content = content
    this.prio = prio
  }

  static of(data) {
    const { content, prio } = data
    return new CreateBannerReq(content, prio)
  }
}

export default class CreateCategoryReq {
  constructor(name, desc, prio, hidden, parentId) {
    this.name = name
    this.desc = desc
    this.prio = prio
    this.hidden = hidden
    this.parentId = parentId
  }

  static of(data) {
    const { name, desc, prio, hidden, parentId } = data
    return new CreateCategoryReq(name, desc, prio, hidden || false, parentId)
  }
}

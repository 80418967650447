import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from "antd";

import * as cacheService from 'services/cache'
import actions from 'redux/cache/actions'
import { translate } from 'localization'
import CacheList from 'model/cache/CacheList'

export function* LIST_CACHE() {
  const data = yield call(cacheService.listCache)
  if (data) {
    yield put({
      type: 'cache/SET_STATE',
      payload: {
        list: CacheList.fromData(data)
      }
    })
  }
}

export function* CLEAR_ALL() {
  yield call(cacheService.clearAllCache)
  notification.success({
    message: translate("notification.info"),
    description: translate("notification.message.successOperation"),
  })
  window.location.reload()
}

export function* CLEAR_CACHE_BY_NAME({ payload }) {
  const { cacheName } = payload
  yield call(cacheService.clearCacheByName, cacheName)
  notification.success({
    message: translate("notification.info"),
    description: translate("notification.message.successOperation"),
  })
  window.location.reload()
}

export function* CLEAR_CACHE_BY_KEY({ payload }) {
  const { cacheName, cacheKey } = payload
  yield call(cacheService.clearCacheByName, cacheName, cacheKey)
  notification.success({
    message: translate("notification.info"),
    description: translate("notification.message.successOperation"),
  })
  window.location.reload()
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_CACHE, LIST_CACHE),
    takeEvery(actions.CLEAR_ALL, CLEAR_ALL),
    takeEvery(actions.CLEAR_CACHE_BY_NAME, CLEAR_CACHE_BY_NAME),
    takeEvery(actions.CLEAR_CACHE_BY_KEY, CLEAR_CACHE_BY_KEY),
  ])
}

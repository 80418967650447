import store from 'store'

// eslint-disable-next-line import/prefer-default-export
export function getLanguage() {
  const tenantLocale = store.get('app.settings.tenantLocale')
  const locale = tenantLocale || 'tr-TR'
  if (locale) return locale.substr(0, 2).toUpperCase()

  return 'TR'
}

export default class Delivery {
  constructor(id, type, name, prio) {
    this.id = id
    this.type = type
    this.name = name
    this.prio = prio
  }

  static TYPES = ["CARGO", "WAREHOUSE", "LOGISTIC", "CAR"]

  static empty() {
    return new Delivery(0, "", "", 0)
  }

  static fromData(data) {
    return this.from(data.delivery)
  }

  static from(data) {
    const { id, type, name, prio } = data
    return new Delivery(id, type, name, prio)
  }
}

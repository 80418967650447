export default class CreateToggleReq {
  constructor(key, value) {
    this.key = key
    this.value = value
  }

  static of(data) {
    const { key, value } = data
    return new CreateToggleReq(key, value)
  }
}

import OrderUser from 'model/order/OrderUser'
import OrderCharge from 'model/order/OrderCharge'
import OrderCustomer from 'model/order/OrderCustomer'
import OrderLine from 'model/order/OrderLine'
import OrderDelivery from 'model/order/OrderDelivery'

export default class Order {
  constructor(
    id,
    code,
    cartCode,
    tenantCode,
    user,
    customer,
    orderDate,
    orderPrettyDate,
    orderDateFinished,
    orderPrettyDateFinished,
    note,
    paymentNote,
    lines,
    charge,
    delivery,
    platform,
    inventoryRegionId,
    inventoryRegionName,
    lang,
    fromSalesman,
    resultStatus,
    processStatus,
  ) {
    this.id = id
    this.code = code
    this.cartCode = cartCode
    this.tenantCode = tenantCode
    this.user = user
    this.customer = customer
    this.orderDate = orderDate
    this.orderPrettyDate = orderPrettyDate
    this.orderDateFinished = orderDateFinished
    this.orderPrettyDateFinished = orderPrettyDateFinished
    this.note = note
    this.paymentNote = paymentNote
    this.lines = lines
    this.charge = charge
    this.delivery = delivery
    this.platform = platform
    this.inventoryRegionId = inventoryRegionId
    this.inventoryRegionName = inventoryRegionName
    this.lang = lang
    this.fromSalesman = fromSalesman
    this.resultStatus = resultStatus
    this.processStatus = processStatus
  }

  static empty() {
    return new Order(
      0,
      '',
      '',
      '',
      OrderUser.empty(),
      OrderCustomer.empty(),
      '',
      '',
      '',
      '',
      '',
      '',
      [],
      OrderCharge.empty(),
      OrderDelivery.empty(),
      '',
      '',
      '',
      '',
      false,
      '',
      '',
    )
  }

  static fromData(data) {
    return this.from(data.order)
  }

  static from(data) {
    const {
      id,
      code,
      cartCode,
      tenantCode,
      user,
      customer,
      orderDate,
      orderPrettyDate,
      orderDateFinished,
      orderPrettyDateFinished,
      note,
      paymentNote,
      lines,
      charge,
      delivery,
      platform,
      inventoryRegionId,
      inventoryRegionName,
      lang,
      fromSalesman,
      resultStatus,
      processStatus,
    } = data
    return new Order(
      id,
      code,
      cartCode,
      tenantCode,
      OrderUser.from(user),
      OrderCustomer.from(customer),
      orderDate,
      orderPrettyDate,
      orderDateFinished,
      orderPrettyDateFinished,
      note,
      paymentNote,
      lines.map(line => OrderLine.from(line)),
      OrderCharge.from(charge),
      OrderDelivery.from(delivery),
      platform,
      inventoryRegionId,
      inventoryRegionName,
      lang,
      fromSalesman,
      resultStatus,
      processStatus,
    )
  }

  isDelivered = () => {
    return this.processStatus === 'DELIVERED'
  }

  isCancelled = () => {
    return this.processStatus === 'CANCELLED'
  }
}

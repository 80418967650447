import ScaledImageMedia from 'model/product/ScaledImageMedia'

export default class Media {
  constructor(id, name, url, sortIndex, scaledImageList) {
    this.id = id
    this.name = name
    this.url = url
    this.sortIndex = sortIndex
    this.scaledImageList = scaledImageList
  }

  static empty() {
    return new Media(0, "", "", 0, [])
  }

  static fromData(data) {
    return this.from(data.media)
  }

  static from(data) {
    const { id, name, url, sortIndex, scaledImageList } = data

    const convertedScaledMediaList = (scaledImageList || []).map(s => ScaledImageMedia.from(s))
    return new Media(id, name, url, sortIndex, convertedScaledMediaList)
  }

  getSmallImageUrl = () => {
    const smallImage = (this.scaledImageList || []).filter(image => image.scale === ScaledImageMedia.scales.SMALL)
    if (smallImage.length > 0) {
      return smallImage[0].url
    }

    return ""
  }
}

import actions from 'redux/news/actions'
import News from 'model/news/News'
import NewsList from 'model/news/NewsList'

const initialState = {
  list: NewsList.empty(),
  detail: News.empty(),
  loading: false
}

export default function newsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.news)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.newsId)
      return state
    }
    default:
      return state
  }
}

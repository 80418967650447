export default class ParentCategory {
  constructor(id, name, parent) {
    this.id = id
    this.name = name
    this.parent = parent
  }

  static from(data) {
    if (data == null) {
      return null
    }

    const { id, name, parent } = data
    return new ParentCategory(id, name, this.from(parent))
  }

  toDepthString = () => {
    if (!this.parent) {
      return this.name
    }

    return `${this.parent.toDepthString()} / ${this.name}`
  }
}

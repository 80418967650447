export default class DefectiveReporter {
  constructor(name, city, town, district, branch) {
    this.name = name
    this.city = city
    this.town = town
    this.district = district
    this.branch = branch
  }

  static empty() {
    return new DefectiveReporter("", "", "", "", "")
  }

  static from(data) {
    const { name, city, town, district, branch } = data
    return new DefectiveReporter(name, city, town, district, branch)
  }
}

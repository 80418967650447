import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { history } from 'index'
import { translate } from 'localization'
import * as categoryGroupService from 'services/product/category-group'
import actions from 'redux/category-group/actions'
import CategoryGroup from 'model/product/CategoryGroup'
import CategoryGroupList from 'model/product/CategoryGroupList'
import CreateCategoryGroupReq from 'model/product/req/CreateCategoryGroupReq'
import UpdateCategoryGroupReq from 'model/product/req/UpdateCategoryGroupReq'

export function* LIST_CATEGORY_GROUP() {
  yield put({
    type: 'category-group/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(categoryGroupService.listCategoryGroup)
  if (data) {
    yield put({
      type: 'category-group/SET_STATE',
      payload: {
        list: CategoryGroupList.fromData(data),
        detail: CategoryGroup.empty(),
      },
    })
  }

  yield put({
    type: 'category-group/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_CATEGORY_GROUP({ payload }) {
  yield put({
    type: 'category-group/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { groupId } = payload
  const data = yield call(categoryGroupService.readCategoryGroup, groupId)
  if (data) {
    yield put({
      type: 'category-group/SET_STATE',
      payload: {
        detail: CategoryGroup.fromData(data),
      },
    })
  }

  yield put({
    type: 'category-group/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_CATEGORY_GROUP({ payload }) {
  yield put({
    type: 'category-group/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(
    categoryGroupService.createCategoryGroup,
    CreateCategoryGroupReq.of(payload),
  )
  if (data) {
    yield put({
      type: 'category-group/ADD_STATE',
      payload: {
        categoryGroup: CategoryGroup.fromData(data),
      },
    })
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'category-group/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_CATEGORY_GROUP({ payload }) {
  yield put({
    type: 'category-group/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(
    categoryGroupService.updateCategoryGroup,
    UpdateCategoryGroupReq.of(payload),
  )
  if (success) {
    yield history.push('/catalog/category-group/view')
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'category-group/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_CATEGORY_GROUP({ payload }) {
  yield put({
    type: 'category-group/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { groupId } = payload
  const success = yield call(categoryGroupService.deleteCategoryGroup, groupId)
  if (success) {
    yield put({
      type: 'category-group/DELETE_STATE',
      payload: { groupId },
    })
  }

  yield put({
    type: 'category-group/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_CATEGORY_GROUP, LIST_CATEGORY_GROUP),
    takeEvery(actions.READ_CATEGORY_GROUP, READ_CATEGORY_GROUP),
    takeEvery(actions.CREATE_CATEGORY_GROUP, CREATE_CATEGORY_GROUP),
    takeEvery(actions.UPDATE_CATEGORY_GROUP, UPDATE_CATEGORY_GROUP),
    takeEvery(actions.DELETE_CATEGORY_GROUP, DELETE_CATEGORY_GROUP),
  ])
}

export default class UpdateDiscountRuleReq {
  constructor(ruleId, discountAmount, discountType, minBoxAmount) {
    this.ruleId = ruleId
    this.discountAmount = discountAmount
    this.discountType = discountType
    this.minBoxAmount = minBoxAmount
  }

  static of(data) {
    const { ruleId, discountAmount, discountType, minBoxAmount } = data
    return new UpdateDiscountRuleReq(ruleId, discountAmount, discountType, minBoxAmount)
  }
}

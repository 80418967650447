export default class ListDeliveryReq {
  constructor(deliveryType) {
    this.deliveryType = deliveryType
  }

  static of(data) {
    const { deliveryType } = data
    return new ListDeliveryReq(deliveryType)
  }
}

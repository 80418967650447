import actions from 'redux/fault/actions'
import FaultData from 'model/fault/FaultData'
import FaultDataList from 'model/fault/FaultDataList'

const initialState = {
  list: FaultDataList.empty(),
  detail: FaultData.empty(),
  loading: false
}

export default function faultReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.SET_DETAIL: {
      state.detail = state.list.getByCorrelationId(action.payload.correlationId)
      return state
    }
    default:
      return state
  }
}

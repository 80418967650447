import Town from 'model/location/Town'

export default class City {
  constructor(name, townList) {
    this.name = name
    this.townList = townList
  }

  static fromData(data) {
    const { name, townList } = data
    return new City(name, townList.map(t => Town.from(t)))
  }

  static fromListData(data) {
    return data.cityList.map(c => this.fromData(c))
  }
}

import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'

import { format } from 'helpers/string'
import { toFormData } from 'helpers/form'

export async function listBanner() {
  return apiClient
    .get(urls.ecom.banner.list)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function createBanner(req) {
  const formData = toFormData(req)

  return apiClient
    .post(urls.ecom.banner.create, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function updateBanner(req) {
  return apiClient
    .put(urls.ecom.banner.update, req)
    .then((response) => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function deleteBanner(bannerId) {
  const url = format(urls.ecom.banner.delete, [bannerId])

  return apiClient
    .delete(url)
    .then((response) => {
      return !!response
    })
    .catch(err => console.log(err))
}

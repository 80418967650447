export default class DailyCartStatistics {
  constructor(count) {
    this.count = count
  }

  static empty() {
    return new DailyCartStatistics(0)
  }

  static from(data) {
    const { count } = data
    return new DailyCartStatistics(count)
  }
}

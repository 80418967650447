const actions = {
  READ_CART_SETTINGS: 'userSettings/READ_CART_SETTINGS',
  SAVE_CART_SETTINGS: 'userSettings/SAVE_CART_SETTINGS',
  READ_PRODUCT_SETTINGS: 'userSettings/READ_PRODUCT_SETTINGS',
  SAVE_PRODUCT_SETTINGS: 'userSettings/SAVE_PRODUCT_SETTINGS',
  READ_CATEGORY_SETTINGS: 'userSettings/READ_CATEGORY_SETTINGS',
  SAVE_CATEGORY_SETTINGS: 'userSettings/SAVE_CATEGORY_SETTINGS',
  SET_STATE: 'userSettings/SET_STATE',
}

export default actions

export default class MigrateProductReq {
  constructor(productCode) {
    this.productCode = productCode
  }

  static of(payload) {
    const { productCode } = payload
    return new MigrateProductReq(productCode)
  }
}

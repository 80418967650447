export default class UserPersonal {
  constructor(firstName, lastName, alias, showAlias, primaryEmail, phoneNo) {
    this.firstName = firstName
    this.lastName = lastName
    this.alias = alias
    this.showAlias = showAlias
    this.primaryEmail = primaryEmail
    this.phoneNo = phoneNo
  }

  static empty() {
    return new UserPersonal("", "", "", false, "", "")
  }

  static from(object) {
    const { firstName, lastName, alias, showAlias, primaryEmail, phoneNo } = object

    return new UserPersonal(firstName, lastName, alias, showAlias, primaryEmail, phoneNo)
  }
}

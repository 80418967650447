import actions from 'redux/userSettings/actions'
import CartUserSettings from 'model/cart/CartUserSettings'
import ProductUserSettings from 'model/product/ProductUserSettings'
import CategoryUserSettings from 'model/product/CategoryUserSettings'

const initialState = {
  cartSettings: CartUserSettings.empty(),
  productSettings: ProductUserSettings.empty(),
  categorySettings: CategoryUserSettings.empty(),
  loading: false
}

export default function userSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}

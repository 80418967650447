import actions from 'redux/auth/actions'
import AuthUser from 'model/auth/AuthUser'

const initialState = AuthUser.emptyUser()

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

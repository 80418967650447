import Product from 'model/product/Product'

export default class ProductList {
  constructor(products, totalCount) {
    this.products = products
    this.totalCount = totalCount
  }

  static empty() {
    return new ProductList([], 0)
  }

  static fromData(data) {
    return new ProductList(
      data.productList.map(d => Product.from(d)),
      data.totalCount
    )
  }

  getById = (id) => {
    const found = this.products.filter(p => p.id === parseInt(id, 10))
    if (found.length > 0) {
      return found[0]
    }
    return null
  }

  getByCode = (code) => {
    const found = this.products.filter(p => p.code === code)
    if (found.length > 0) {
      return found[0]
    }
    return null
  }

  add = (product) => {
    this.products.push(product)
    this.totalCount += 1

    return new ProductList(this.products, this.totalCount)
  }

  remove = (productId) => {
    this.products = this.products.filter(c => c.id !== productId)
    this.totalCount -= 1

    return new ProductList(this.products, this.totalCount)
  }
}

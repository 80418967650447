import Audit from 'model/audit/Audit'

export default class AuditList {
  constructor(auditList, totalCount) {
    this.auditList = auditList
    this.totalCount = totalCount
  }

  static empty() {
    return new AuditList([], 0)
  }

  static fromData(data) {
    return new AuditList(
      data.auditList.map(d => Audit.from(d)),
      data.auditList.totalCount
    )
  }

  getByCorrelationId = (correlationId) => {
    const found = this.auditList.filter(a => a.correlationId === correlationId)
    if (found.length > 0) {
      return found[0]
    }
    return null
  }
}

export default class BestSellerFeature {
  constructor(prio) {
    this.prio = prio
  }

  static empty() {
    return new BestSellerFeature(0)
  }

  static from(data) {
    const { prio } = data
    return new BestSellerFeature(prio)
  }
}

import qs from 'qs'

import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'
import { toFormData } from 'helpers/form'

export async function listProduct(req) {
  return apiClient
    .get(urls.product.list, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readProduct(productId) {
  const url = format(urls.product.read, [productId])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function searchProduct(req) {
  return apiClient
    .get(urls.product.search, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function exportProductByField(req) {
  return apiClient
    .get(urls.product.exportByField, {
      timeout: 60000,
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function createProduct(req) {
  const formData = toFormData(req)
  return apiClient
    .post(urls.product.create, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function updateProduct(req) {
  return apiClient
    .put(urls.product.update, req)
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function deleteProduct(productId) {
  const url = format(urls.product.read, [productId])

  return apiClient
    .delete(url)
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function saveProductTag(req) {
  return apiClient
    .post(urls.product.saveTag, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function saveProductRelationship(req) {
  return apiClient
    .post(urls.product.saveRelationship, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function readProductUserSettings(username) {
  const url = format(urls.product.readSettings, [username])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function saveProductUserSettings(req) {
  return apiClient
    .post(urls.product.saveSettings, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function loadProductLegacy(req) {
  const formData = toFormData(req)

  return apiClient
    .post(urls.product.loadLegacyUpdate, formData, {
      timeout: 60000,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function loadProductCreate(req) {
  const formData = toFormData(req)

  return apiClient
    .post(urls.product.loadCreate, formData, {
      timeout: 60000,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function migrateProduct(req) {
  return apiClient
    .post(urls.product.migrateProduct, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

import actions from 'redux/led/actions'
import Led from 'model/product/Led'
import LedList from 'model/product/LedList'

const initialState = {
  list: LedList.empty(),
  brandList: [],
  firmList: [],
  detail: Led.empty(),
  loading: false
}

export default function ledReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.led)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.ledId)
      return state
    }
    default:
      return state
  }
}

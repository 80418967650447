/* eslint-disable */
import axios from 'axios'

import apiClient from 'services/axios'
import { notification } from 'antd'

import { translate } from 'localization'
import { getLanguage } from 'helpers/lang'
import { getRefreshToken, removeTokens, setTokens } from 'helpers/token'

import ERRORS from 'constants/errors'
import packageJson from '../../../../package.json'

const apiBasicClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 3000,
  headers: {
    'X-Platform': 'ADMIN',
    'X-Language': getLanguage(),
    'X-Client-Version': packageJson.version,
  },
})

export default function responseErrorInterceptor(error) {
  const { response } = error
  if (!response) {
    notification.warning({
      message: translate('notification.error'),
      description: `${error.code} : ${error.message}`,
    })
  }

  const { data } = response
  if (data) {
    const { code, desc } = prepareError(data)

    if (shouldRefreshToken(error)) {
      const originalRequest = error.config
      originalRequest._retry = true

      apiBasicClient
        .post('/auth/refresh', { refreshToken: getRefreshToken() })
        .then(refreshTokenResponse => {
          const { value, refreshToken } = refreshTokenResponse.data.accessToken
          if (value) {
            setTokens(value, refreshToken)
            return apiClient(originalRequest)
          }
          return null
        })
        .then(() => {
          window.location.reload()
        })
        .catch(err => console.log(err))
    } else {
      notification.warning({
        key: 'error-notification',
        message: translate('notification.error'),
        description: `${desc} : ${code}`,
        duration: 7,
      })
    }

    if (data.code === ERRORS.AUTHORIZATION_INVALID) {
      removeTokens()
      window.location.href = '/auth/401'
    }
  }

  throw error
}

function prepareError(data) {
  let { code, desc } = data
  if (!code) {
    code = ERRORS.UNEXPECTED
    desc = data
  }
  return { code, desc }
}

function shouldRefreshToken(error) {
  const { data } = error.response
  const originalRequest = error.config
  return (
    error.response.status === 401 &&
    data.code === ERRORS.TOKEN_EXPIRED &&
    !originalRequest.noRefreshFlow &&
    !originalRequest._retry
  )
}

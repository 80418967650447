import BoxAmountDiscount from 'model/product/BoxAmountDiscount'

export default class Discount {
  constructor(amount, boxAmountDiscounts) {
    this.amount = amount
    this.boxAmountDiscounts = boxAmountDiscounts
  }

  static empty() {
    return new Discount(0, [])
  }

  static from(data) {
    const { amount, boxAmountDiscounts } = data
    return new Discount(amount, (boxAmountDiscounts || []).map(b => BoxAmountDiscount.from(b)))
  }
}

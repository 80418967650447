export default class SearchCustomerReq {
  constructor(page, size, searchText, authorizedUser) {
    this.page = page
    this.size = size
    this.searchText = searchText
    this.authorizedUser = authorizedUser
  }

  static of(data) {
    const { page, size, searchText, authorizedUser } = data
    return new SearchCustomerReq(page, size, searchText, authorizedUser)
  }
}

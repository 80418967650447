import qs from 'qs'

import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { isTokenExist } from 'helpers/token'
import { format } from 'helpers/string'

export async function listUser(req) {
  return apiClient
    .get(urls.user.list, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readUser(username) {
  const url = format(urls.user.read, [username])

  return apiClient
    .get(url, {
      params: {
        withoutStatus: true,
      },
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function searchUser(req) {
  return apiClient
    .get(urls.user.search, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readCurrentUser() {
  if (!isTokenExist()) return false

  return apiClient
    .get(urls.user.currentRead)
    .then(response => {
      if (response) {
        return response.data.user
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function createUser(req) {
  return apiClient
    .post(urls.user.create, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function updateUserPersonal(username, req) {
  const url = format(urls.user.updatePersonal, [username])

  return apiClient
    .put(url, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function updateUserRole(username, req) {
  const url = format(urls.user.updateRole, [username])

  return apiClient
    .put(url, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function updateUserPassword(username, req) {
  const url = format(urls.user.updatePassword, [username])

  return apiClient
    .put(url, req)
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function lockUser(username) {
  const url = format(urls.user.lock, [username])
  return apiClient
    .put(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function unlockUser(username) {
  const url = format(urls.user.unlock, [username])
  return apiClient
    .put(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function enableUser(username, req) {
  const url = format(urls.user.enable, [username])
  return apiClient
    .put(url, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function disableUser(username) {
  const url = format(urls.user.disable, [username])
  return apiClient
    .put(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function forgotPassword(req) {
  return apiClient
    .post(urls.user.password.forgot, req)
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

import Media from 'model/product/Media'

export default class MediaList {
  constructor(mediaList, totalCount) {
    this.mediaList = mediaList
    this.totalCount = totalCount
  }

  static empty() {
    return new MediaList([], 0)
  }

  static fromData(data) {
    return new MediaList(
      data.mediaList.map(d => Media.from(d)),
      data.mediaList.length
    )
  }

  getById = (id) => {
    const found = this.mediaList.filter(g => g.id === parseInt(id, 10))
    if (found.length > 0) {
      return found[0].name
    }
    return ""
  }

  add = (product) => {
    this.mediaList.push(product)
    this.totalCount += 1

    return new MediaList(this.mediaList, this.totalCount)
  }

  remove = (productId) => {
    this.mediaList = this.mediaList.filter(c => c.id !== productId)
    this.totalCount -= 1

    return new MediaList(this.mediaList, this.totalCount)
  }
}

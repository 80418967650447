import { all, call, put, takeEvery } from 'redux-saga/effects'

import * as auditService from 'services/audit'
import actions from 'redux/audit/actions'
import Audit from 'model/audit/Audit'
import AuditList from 'model/audit/AuditList'
import ListAuditReq from 'model/audit/req/ListAuditReq'

export function* LIST_AUDIT({ payload }) {
  yield put({
    type: 'audit/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(auditService.listAudit, ListAuditReq.of(payload))
  if (data) {
    yield put({
      type: 'audit/SET_STATE',
      payload: {
        list: AuditList.fromData(data),
        detail: Audit.empty()
      }
    })
  }

  yield put({
    type: 'audit/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_AUDIT({ payload }) {
  yield put({
    type: 'audit/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { correlationId } = payload
  const data = yield call(auditService.readAudit, correlationId)
  if (data) {
    yield put({
      type: 'audit/SET_STATE',
      payload: {
        detail: Audit.fromData(data)
      }
    })
  }

  yield put({
    type: 'audit/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_AUDIT, LIST_AUDIT),
    takeEvery(actions.READ_AUDIT, READ_AUDIT),
  ])
}

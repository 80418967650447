import Inventory from 'model/inventory/Inventory'

export default class InventoryList {
  constructor(inventoryList, totalCount) {
    this.inventoryList = inventoryList
    this.totalCount = totalCount
  }

  add = inventory => {
    this.inventoryList.push(inventory)
    this.totalCount += 1

    return new InventoryList(this.inventoryList, this.totalCount)
  }

  remove = inventoryId => {
    this.inventoryList = this.inventoryList.filter(c => c.id !== inventoryId)
    this.totalCount -= 1

    return new InventoryList(this.inventoryList, this.totalCount)
  }

  static empty() {
    return new InventoryList([], 0)
  }

  static fromData(data) {
    return new InventoryList(
      data.inventoryList.map(d => Inventory.from(d)),
      data.totalCount,
    )
  }
}

import Category from 'model/product/Category'

export default class CategoryList {
  constructor(categories, totalCount) {
    this.categories = categories
    this.totalCount = totalCount
  }

  static empty() {
    return new CategoryList([], 0)
  }

  static fromData(data) {
    return new CategoryList(
      data.categoryList.map(d => Category.from(d)),
      data.categoryList.length,
    )
  }

  getById = id => {
    const found = this.categories.filter(c => parseInt(c.id, 10) === parseInt(id, 10))
    if (found.length > 0) {
      return found[0]
    }
    return null
  }

  getByName = name => {
    const found = this.categories.filter(c => c.name === name)
    if (found.length > 0) {
      return found[0]
    }
    return null
  }

  getByNameOrId = value => {
    return this.getById(value) || this.getByName(value)
  }

  getNameById = id => {
    const found = this.categories.filter(c => parseInt(c.id, 10) === parseInt(id, 10))
    if (found.length > 0) {
      return found[0].name
    }
    return ''
  }

  getWithoutParents = () => {
    return this.categories.filter(c => c.parent != null)
  }

  isEmpty = () => {
    return this.categories.length === 0
  }

  search = text => {
    return this.categories.filter(c => c.name.toLowerCase().indexOf(text.toLowerCase()) > -1)
  }

  add = category => {
    this.categories.push(category)
    this.totalCount += 1

    return new CategoryList(this.categories, this.totalCount)
  }

  remove = categoryId => {
    if (this.categories.filter(c => c.id === categoryId).length > 0) {
      this.categories = this.categories.filter(c => c.id !== categoryId)
      this.totalCount -= 1

      return new CategoryList(this.categories, this.totalCount)
    }

    const foundParentCategories = this.categories.filter(
      c => c.children.filter(child => child.id === categoryId).length > 0,
    )
    if (foundParentCategories.length > 0) {
      this.categories.forEach(parent => {
        const foundParentCategory = foundParentCategories[0]
        if (parent.id === foundParentCategory.id) {
          parent.children = foundParentCategory.children.filter(child => child.id !== categoryId)
        }
      })

      return new CategoryList(this.categories, this.totalCount)
    }

    return new CategoryList(this.categories, this.totalCount)
  }
}

import Price from 'model/product/Price'

export default class DailyOrderStatistics {
  constructor(count, total, curr) {
    this.count = count
    this.total = total
    this.curr = curr
  }

  static empty() {
    return new DailyOrderStatistics(0, 0, '')
  }

  static from(data) {
    const { count, total, curr } = data
    return new DailyOrderStatistics(count, total, curr)
  }

  getTotalWithCurr() {
    return new Price(this.total, this.curr).toPrettyString()
  }
}

const actions = {
  LIST_DEFECTIVE: 'defective/LIST_DEFECTIVE',
  SEARCH_DEFECTIVE: 'defective/SEARCH_DEFECTIVE',
  READ_DEFECTIVE: 'defective/READ_DEFECTIVE',
  EXPORT_DEFECTIVE: 'defective/EXPORT_DEFECTIVE',
  CREATE_DEFECTIVE: 'defective/CREATE_DEFECTIVE',
  UPDATE_DEFECTIVE: 'defective/UPDATE_DEFECTIVE',
  COMPLETE_DEFECTIVE: 'defective/COMPLETE_DEFECTIVE',
  ASSOCIATE_DEFECTIVE: 'defective/ASSOCIATE_DEFECTIVE',
  DELETE_DEFECTIVE: 'defective/DELETE_DEFECTIVE',
  SET_STATE: 'defective/SET_STATE',
  ADD_STATE: 'defective/ADD_STATE',
  DELETE_STATE: 'defective/DELETE_STATE',
}

export default actions

import store from 'store'

export function setTokens(accessToken, refreshToken) {
  store.set('app.security.accessToken', accessToken)
  store.set('app.security.refreshToken', refreshToken)
}

export function removeTokens() {
  store.remove('app.security.accessToken')
  store.remove('app.security.refreshToken')
}

export function getAccessToken() {
  return store.get('app.security.accessToken')
}

export function getRefreshToken() {
  return store.get('app.security.refreshToken')
}

export function isTokenExist() {
  return store.get('app.security.accessToken')
}

import Media from 'model/product/Media'

export default class ProductRelationship {
  constructor(code, type, name, brandName, mediaList) {
    this.code = code
    this.type = type
    this.name = name
    this.brandName = brandName
    this.mediaList = mediaList
  }

  static empty() {
    return new ProductRelationship("", "", "", "", [])
  }

  static from(data) {
    const { code, type, name, brandName, mediaList } = data
    return new ProductRelationship(code, type, name, brandName, (mediaList || []).map(m => Media.from(m)))
  }

  static RELATION_RELATED_ITEM = "RELATED_ITEM"
}

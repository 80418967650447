export default class LoadProductCreateReq {
  constructor(file) {
    this.file = file
  }

  static of(payload) {
    const { file } = payload
    return new LoadProductCreateReq(file)
  }
}

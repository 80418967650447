export default class SaveProductTagReq {
  constructor(productId, tagList) {
    this.productId = productId
    this.tagList = tagList
  }

  static of(data) {
    const { productId, tagList } = data

    return new SaveProductTagReq(productId, tagList || [])
  }
}

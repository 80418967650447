export default class AuthUser {
  constructor(username, firstName, lastName, primaryEmail, phoneNo, role, authorized, loading) {
    this.username = username
    this.firstName = firstName
    this.lastName = lastName
    this.fullName = `${this.firstName} ${this.lastName}`
    this.primaryEmail = primaryEmail
    this.phoneNo = phoneNo
    this.role = role
    this.authorized = authorized
    this.loading = loading
  }

  static emptyUser() {
    return new AuthUser("", "", "", "", "", "", false, false)
  }
}

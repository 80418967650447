import Customer from 'model/customer/Customer'

export default class CustomerList {
  constructor(customers, totalCount) {
    this.customers = customers
    this.totalCount = totalCount
  }

  static empty() {
    return new CustomerList([], 0)
  }

  static fromData(data) {
    return new CustomerList(
      data.customerList.map(d => Customer.from(d)),
      data.totalCount
    )
  }

  getById = (id) => {
    const found = this.customers.filter(customer => customer.id === parseInt(id, 10))
    if (found.length > 0) {
      return found[0]
    }
    return null
  }

  getByCompanyName = (companyName) => {
    const found = this.customers.filter(customer => customer.companyName === companyName)
    if (found.length > 0) {
      return found[0]
    }
    return null
  }

  add = (customer) => {
    this.customers.push(customer)
    this.totalCount += 1

    return new CustomerList(this.customers, this.totalCount)
  }

  remove = (customerId) => {
    this.customers = this.customers.filter(c => c.id !== customerId)
    this.totalCount -= 1

    return new CustomerList(this.customers, this.totalCount)
  }
}

import DiscountRule from 'model/discount/DiscountRule'

export default class DiscountRuleList {
  constructor(ruleList, totalCount) {
    this.ruleList = ruleList
    this.totalCount = totalCount
  }

  add = (rule) => {
    this.ruleList.push(rule)
    this.totalCount += 1

    return new DiscountRuleList(this.ruleList, this.totalCount)
  }

  remove = (ruleId) => {
    this.ruleList = this.ruleList.filter(c => c.id !== ruleId)
    this.totalCount -= 1

    return new DiscountRuleList(this.ruleList, this.totalCount)
  }

  static empty() {
    return new DiscountRuleList([], 0)
  }

  static fromData(data) {
    return new DiscountRuleList(
      data.ruleList.map(d => DiscountRule.from(d)),
      data.totalCount
    )
  }
}

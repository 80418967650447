import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import { history } from 'index'
import * as inventoryService from 'services/inventory'
import * as regionService from 'services/inventory/region'
import actions from 'redux/inventory/actions'
import Inventory from 'model/inventory/Inventory'
import InventoryList from 'model/inventory/InventoryList'
import InventoryAdjustmentHistoryList from 'model/inventory/InventoryAdjustmentHistoryList'
import InventoryRegion from 'model/inventory/InventoryRegion'
import InventoryRegionList from 'model/inventory/InventoryRegionList'
import ListInventoryReq from 'model/inventory/req/ListInventoryReq'
import SearchInventoryReq from 'model/inventory/req/SearchInventoryReq'
import CreateInventoryReq from 'model/inventory/req/CreateInventoryReq'
import AdjustInventoryReq from 'model/inventory/req/AdjustInventoryReq'
import CreateRegionReq from 'model/inventory/req/CreateRegionReq'
import UpdateRegionReq from 'model/inventory/req/UpdateRegionReq'

export function* LIST_INVENTORY({ payload }) {
  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(inventoryService.listInventory, ListInventoryReq.of(payload))
  if (data) {
    yield put({
      type: 'inventory/SET_STATE',
      payload: {
        inventoryList: InventoryList.fromData(data),
        inventoryDetail: Inventory.empty(),
      },
    })
  }

  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LIST_ADJUSTMENT_HISTORY({ payload }) {
  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { inventoryId } = payload
  const data = yield call(inventoryService.listAdjustmentHistory, inventoryId)
  if (data) {
    yield put({
      type: 'inventory/SET_STATE',
      payload: {
        listHistory: InventoryAdjustmentHistoryList.fromData(data),
      },
    })
  }

  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SEARCH_INVENTORY({ payload }) {
  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(inventoryService.searchInventory, SearchInventoryReq.of(payload))
  if (data) {
    yield put({
      type: 'inventory/SET_STATE',
      payload: {
        inventoryList: InventoryList.fromData(data),
        inventoryDetail: Inventory.empty(),
      },
    })
  }

  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_INVENTORY({ payload }) {
  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { inventoryId } = payload
  const data = yield call(inventoryService.readInventory, inventoryId)
  if (data) {
    yield put({
      type: 'inventory/SET_STATE',
      payload: {
        inventoryDetail: Inventory.fromData(data),
      },
    })
  }

  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_INVENTORY({ payload }) {
  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(inventoryService.createInventory, CreateInventoryReq.of(payload))
  if (data) {
    yield put({
      type: 'inventory/ADD_STATE',
      payload: {
        inventory: Inventory.fromData(data),
      },
    })
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* ADJUST_INVENTORY({ payload }) {
  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(inventoryService.adjustInventory, AdjustInventoryReq.of(payload))
  if (success) {
    yield history.push('/inventory/view')
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_INVENTORY({ payload }) {
  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { inventoryId } = payload
  const success = yield call(inventoryService.deleteInventory, inventoryId)
  if (success) {
    yield put({
      type: 'inventory/DELETE_STATE',
      payload: { inventoryId },
    })
  }

  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LIST_REGION() {
  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(regionService.listRegion)
  if (data) {
    yield put({
      type: 'inventory/SET_STATE',
      payload: {
        regionList: InventoryRegionList.fromData(data),
        regionDetail: InventoryRegion.empty(),
      },
    })
  }

  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_REGION({ payload }) {
  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { regionId } = payload
  const data = yield call(regionService.readRegion, regionId)
  if (data) {
    yield put({
      type: 'inventory/SET_STATE',
      payload: {
        regionDetail: InventoryRegion.fromData(data),
      },
    })
  }

  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_REGION({ payload }) {
  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(regionService.createRegion, CreateRegionReq.of(payload))
  if (data) {
    yield put({
      type: 'inventory/ADD_STATE',
      payload: {
        region: InventoryRegion.fromData(data),
      },
    })
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_REGION({ payload }) {
  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(regionService.updateRegion, UpdateRegionReq.of(payload))
  if (success) {
    yield history.push('/inventory/region/view')
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_REGION({ payload }) {
  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { regionId } = payload
  const success = yield call(regionService.deleteRegion, regionId)
  if (success) {
    yield put({
      type: 'inventory/DELETE_STATE',
      payload: { regionId },
    })
  }

  yield put({
    type: 'inventory/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_INVENTORY, LIST_INVENTORY),
    takeEvery(actions.LIST_ADJUSTMENT_HISTORY, LIST_ADJUSTMENT_HISTORY),
    takeEvery(actions.SEARCH_INVENTORY, SEARCH_INVENTORY),
    takeEvery(actions.READ_INVENTORY, READ_INVENTORY),
    takeEvery(actions.CREATE_INVENTORY, CREATE_INVENTORY),
    takeEvery(actions.ADJUST_INVENTORY, ADJUST_INVENTORY),
    takeEvery(actions.DELETE_INVENTORY, DELETE_INVENTORY),
    takeEvery(actions.LIST_REGION, LIST_REGION),
    takeEvery(actions.READ_REGION, READ_REGION),
    takeEvery(actions.CREATE_REGION, CREATE_REGION),
    takeEvery(actions.UPDATE_REGION, UPDATE_REGION),
    takeEvery(actions.DELETE_REGION, DELETE_REGION),
  ])
}

export default class UpdateLedReq {
  constructor(id, originalCodeList, ledCountList, ledLengthList, tvModelList, tvPanelList, tvSizeList, tvBrandList, chinaCodeList, firmCodeList) {
    this.id = id
    this.originalCodeList = originalCodeList
    this.ledCountList = ledCountList
    this.ledLengthList = ledLengthList
    this.tvModelList = tvModelList
    this.tvPanelList = tvPanelList
    this.tvSizeList = tvSizeList
    this.tvBrandList = tvBrandList
    this.chinaCodeList = chinaCodeList
    this.firmCodeList = firmCodeList
  }

  static of(data) {
    const {
      ledId,
      originalCodeList,
      ledCountList,
      ledLengthList,
      tvModelList,
      tvPanelList,
      tvSizeList,
      tvBrandList,
      chinaCodeList,
      firmCodeList
    } = data
    return new UpdateLedReq(
      ledId,
      originalCodeList,
      ledCountList,
      ledLengthList,
      tvModelList,
      tvPanelList,
      tvSizeList,
      tvBrandList,
      chinaCodeList,
      firmCodeList)
  }
}

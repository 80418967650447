export default class LedFirmCode {
  constructor(name, codeList) {
    this.name = name
    this.codeList = codeList
  }

  static empty() {
    return new LedFirmCode("", [])
  }

  static from(data) {
    const { name, codeList } = data
    return new LedFirmCode(name, codeList)
  }
}

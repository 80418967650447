import actions from 'redux/discount/actions'
import DiscountRule from 'model/discount/DiscountRule'
import DiscountRuleList from 'model/discount/DiscountRuleList'

const initialState = {
  list: DiscountRuleList.empty(),
  detail: DiscountRule.empty(),
  loading: false
}

export default function discountReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.rule)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.ruleId)
      return state
    }
    default:
      return state
  }
}

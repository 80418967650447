export default class UpdateBannerReq {
  constructor(bannerId, prio) {
    this.bannerId = bannerId
    this.prio = prio
  }

  static of(data) {
    const { bannerId, prio } = data
    return new UpdateBannerReq(bannerId, prio)
  }
}

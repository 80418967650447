import CurrencyMapper from 'model/product/CurrencyMapper'

export default class Price {
  constructor(amount, curr) {
    this.amount = amount
    this.curr = curr
  }

  static empty() {
    return new Price(0, "TRY")
  }

  static from(data) {
    const { amount, curr } = data
    return new Price(amount, curr)
  }

  toPrettyString() {
    const curr = CurrencyMapper.mapToSymbol(this.curr)
    return `${this.amount} ${curr}`
  }
}

import Product from 'model/product/Product'
import LedFirmCode from 'model/product/LedFirmCode'

export default class Led {
  constructor(id, code, originalCodeList, ledCountList, ledLengthList, tvModelList, tvPanelList, tvSizeList, tvBrandList,
              chinaCodeList, firmCodeList, product) {
    this.id = id
    this.code = code
    this.originalCodeList = originalCodeList
    this.ledCountList = ledCountList
    this.ledLengthList = ledLengthList
    this.tvModelList = tvModelList
    this.tvPanelList = tvPanelList
    this.tvSizeList = tvSizeList
    this.tvBrandList = tvBrandList
    this.chinaCodeList = chinaCodeList
    this.firmCodeList = firmCodeList
    this.product = product
  }

  static empty() {
    return new Led("", [], [], [], 0, [], [], [], [], [], [], Product.empty())
  }

  static fromData(data) {
    return this.from(data.led)
  }

  static from(data) {
    const {
      id,
      code,
      originalCodeList,
      ledCountList,
      ledLengthList,
      tvModelList,
      tvPanelList,
      tvSizeList,
      tvBrandList,
      chinaCodeList,
      firmCodeList,
      product,
    } = data
    return new Led(
      id,
      code,
      originalCodeList,
      ledCountList,
      ledLengthList,
      tvModelList,
      tvPanelList,
      tvSizeList,
      tvBrandList,
      chinaCodeList,
      (firmCodeList || []).map(firmCode => LedFirmCode.from(firmCode)),
      product ? Product.from(product) : Product.empty())
  }
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { history } from 'index'
import { translate } from 'localization'
import { downloadFile } from 'helpers/file'
import * as customerService from 'services/customer'
import actions from 'redux/customer/actions'
import Customer from 'model/customer/Customer'
import CustomerList from 'model/customer/CustomerList'
import ListCustomerReq from 'model/customer/req/ListCustomerReq'
import SearchCustomerReq from 'model/customer/req/SearchCustomerReq'
import ExportCustomerReq from 'model/customer/req/ExportCustomerReq'
import CreateCustomerReq from 'model/customer/req/CreateCustomerReq'
import UpdateCustomerReq from 'model/customer/req/UpdateCustomerReq'

export function* LIST_CUSTOMER({ payload }) {
  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(customerService.listCustomer, ListCustomerReq.of(payload))
  if (data) {
    yield put({
      type: 'customer/SET_STATE',
      payload: {
        list: CustomerList.fromData(data),
        detail: Customer.empty()
      }
    })
  }

  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_CUSTOMER({ payload }) {
  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { customerId } = payload
  const data = yield call(customerService.readCustomer, customerId)
  if (data) {
    yield put({
      type: 'customer/SET_STATE',
      payload: {
        detail: Customer.fromData(data)
      }
    })
  }

  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SEARCH_CUSTOMER({ payload }) {
  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(customerService.searchCustomer, SearchCustomerReq.of(payload))
  if (data) {
    yield put({
      type: 'customer/SET_STATE',
      payload: {
        list: CustomerList.fromData(data),
        detail: Customer.empty()
      }
    })
  }

  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* EXPORT_CUSTOMER({ payload }) {
  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(customerService.exportCustomer, ExportCustomerReq.of(payload))
  if (data) {
    downloadFile(data.fileType, data.base64, data.fileName)
  }

  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_CUSTOMER({ payload }) {
  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(customerService.createCustomer, CreateCustomerReq.of(payload))
  if (data) {
    yield put({
      type: 'customer/ADD_STATE',
      payload: {
        customer: Customer.fromData(data)
      }
    })
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_CUSTOMER({ payload }) {
  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(customerService.updateCustomer, UpdateCustomerReq.of(payload))
  if (success) {
    yield history.push('/customer/view')
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_CUSTOMER({ payload }) {
  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { customerId } = payload
  const success = yield call(customerService.deleteCustomer, customerId)
  if (success) {
    yield put({
      type: 'customer/DELETE_STATE',
      payload: { customerId }
    })
  }

  yield put({
    type: 'customer/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_CUSTOMER, LIST_CUSTOMER),
    takeEvery(actions.READ_CUSTOMER, READ_CUSTOMER),
    takeEvery(actions.SEARCH_CUSTOMER, SEARCH_CUSTOMER),
    takeEvery(actions.EXPORT_CUSTOMER, EXPORT_CUSTOMER),
    takeEvery(actions.CREATE_CUSTOMER, CREATE_CUSTOMER),
    takeEvery(actions.UPDATE_CUSTOMER, UPDATE_CUSTOMER),
    takeEvery(actions.DELETE_CUSTOMER, DELETE_CUSTOMER),
  ])
}

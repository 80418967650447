import React from 'react'
import store from 'store'
import { createIntl, IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { ConfigProvider } from 'antd'

import turkish from 'locales/tr-TR'
import english from 'locales/en-US'

const locales = {
  'tr-TR': turkish,
  'en-US': english,
}

createIntl({
  locale: 'tr-TR',
  messages: turkish.messages,
})

const mapStateToProps = ({ settings }) => ({
  tenantLocale: settings.tenantLocale,
})

const Localization = ({ children, tenantLocale }) => {
  const currentLocale = locales[tenantLocale]
  return (
    <ConfigProvider locale={currentLocale.localeAntd}>
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}

export function translate(key) {
  const currentLocale = getLocale()
  return currentLocale.messages[key]
}

export function getLocale() {
  const tenantLocale = store.get('app.settings.tenantLocale')
  const locale = tenantLocale || 'tr-TR'
  return locales[locale]
}

export default connect(mapStateToProps)(Localization)

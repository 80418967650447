import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'

export default async function getTenant() {
  return apiClient
    .get(urls.tenant.read)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

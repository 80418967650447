import InventoryAdjustmentHistory from 'model/inventory/InventoryAdjustmentHistory'

export default class InventoryAdjustmentHistoryList {
  constructor(historyList, totalCount) {
    this.historyList = historyList
    this.totalCount = totalCount
  }

  add = history => {
    this.historyList.push(history)
    this.totalCount += 1

    return new InventoryAdjustmentHistoryList(this.historyList, this.totalCount)
  }

  static empty() {
    return new InventoryAdjustmentHistoryList([], 0)
  }

  static fromData(data) {
    return new InventoryAdjustmentHistoryList(
      data.historyList.map(d => InventoryAdjustmentHistory.from(d)),
      data.totalCount,
    )
  }
}

import Cache from 'model/cache/Cache'

export default class CacheList {
  constructor(cacheList, totalCount) {
    this.cacheList = cacheList
    this.totalCount = totalCount
  }

  static empty() {
    return new CacheList([], 0)
  }

  static fromData(data) {
    return new CacheList(
      data.cacheList.map(d => Cache.from(d)),
      data.cacheList.length
    )
  }
}

export default class VitrineFeature {
  constructor(prio) {
    this.prio = prio
  }

  static empty() {
    return new VitrineFeature(0)
  }

  static from(data) {
    const { prio } = data
    return new VitrineFeature(prio)
  }
}

export default class ExportCustomerGroupReq {
  constructor(exportFormat, lang) {
    this.exportFormat = exportFormat
    this.lang = lang
  }

  static of(data) {
    const { exportFormat, lang } = data
    return new ExportCustomerGroupReq(exportFormat, lang || "TR")
  }
}

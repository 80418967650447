import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { history } from 'index'
import { translate } from 'localization'
import { downloadFile } from 'helpers/file'
import * as customerGroupService from 'services/customer-group'
import actions from 'redux/customer-group/actions'
import CustomerGroup from 'model/customer/CustomerGroup'
import CustomerGroupList from 'model/customer/CustomerGroupList'
import ListCustomerGroupReq from 'model/customer/req/ListCustomerGroupReq'
import SearchCustomerGroupReq from 'model/customer/req/SearchCustomerGroupReq'
import ExportCustomerGroupReq from 'model/customer/req/ExportCustomerGroupReq'
import CreateCustomerGroupReq from 'model/customer/req/CreateCustomerGroupReq'
import UpdateCustomerGroupReq from 'model/customer/req/UpdateCustomerGroupReq'

export function* LIST_CUSTOMER_GROUP({ payload }) {
  yield put({
    type: 'customer-group/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(customerGroupService.listCustomerGroup, ListCustomerGroupReq.of(payload))
  if (data) {
    yield put({
      type: 'customer-group/SET_STATE',
      payload: {
        list: CustomerGroupList.fromData(data),
        detail: CustomerGroup.empty()
      }
    })
  }

  yield put({
    type: 'customer-group/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SEARCH_CUSTOMER_GROUP({ payload }) {
  yield put({
    type: 'customer-group/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(customerGroupService.searchCustomerGroup, SearchCustomerGroupReq.of(payload))
  if (data) {
    yield put({
      type: 'customer-group/SET_STATE',
      payload: {
        list: CustomerGroupList.fromData(data),
        detail: CustomerGroup.empty()
      }
    })
  }

  yield put({
    type: 'customer-group/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_CUSTOMER_GROUP({ payload }) {
  yield put({
    type: 'customer-group/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { groupId } = payload
  const data = yield call(customerGroupService.readCustomerGroup, groupId)
  if (data) {
    yield put({
      type: 'customer-group/SET_STATE',
      payload: {
        detail: CustomerGroup.fromData(data)
      }
    })
  }

  yield put({
    type: 'customer-group/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* EXPORT_CUSTOMER_GROUP({ payload }) {
  yield put({
    type: 'customer-group/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(customerGroupService.exportCustomerGroup, ExportCustomerGroupReq.of(payload))
  if (data) {
    downloadFile(data.fileType, data.base64, data.fileName)
  }

  yield put({
    type: 'customer-group/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_CUSTOMER_GROUP({ payload }) {
  yield put({
    type: 'customer-group/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(customerGroupService.createCustomerGroup, CreateCustomerGroupReq.of(payload))
  if (data) {
    yield put({
      type: 'customer-group/ADD_STATE',
      payload: {
        customerGroup: CustomerGroup.fromData(data)
      }
    })
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'customer-group/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_CUSTOMER_GROUP({ payload }) {
  yield put({
    type: 'customer-group/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(customerGroupService.updateCustomerGroup, UpdateCustomerGroupReq.of(payload))
  if (success) {
    yield history.push('/customer-group/view')
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'customer-group/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_CUSTOMER_GROUP({ payload }) {
  yield put({
    type: 'customer-group/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { groupId } = payload
  const success = yield call(customerGroupService.deleteCustomerGroup, groupId)
  if (success) {
    yield put({
      type: 'customer-group/DELETE_STATE',
      payload: { groupId }
    })
  }

  yield put({
    type: 'customer-group/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_CUSTOMER_GROUP, LIST_CUSTOMER_GROUP),
    takeEvery(actions.SEARCH_CUSTOMER_GROUP, SEARCH_CUSTOMER_GROUP),
    takeEvery(actions.READ_CUSTOMER_GROUP, READ_CUSTOMER_GROUP),
    takeEvery(actions.EXPORT_CUSTOMER_GROUP, EXPORT_CUSTOMER_GROUP),
    takeEvery(actions.CREATE_CUSTOMER_GROUP, CREATE_CUSTOMER_GROUP),
    takeEvery(actions.UPDATE_CUSTOMER_GROUP, UPDATE_CUSTOMER_GROUP),
    takeEvery(actions.DELETE_CUSTOMER_GROUP, DELETE_CUSTOMER_GROUP),
  ])
}

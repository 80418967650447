export default class FaultContext {
  constructor(correlationId, username, platform, environment, appVersion, clientVersion) {
    this.correlationId = correlationId
    this.username = username
    this.platform = platform
    this.environment = environment
    this.appVersion = appVersion
    this.clientVersion = clientVersion
  }

  static empty() {
    return new FaultContext("", "", "", "", "", "")
  }

  static from(data) {
    const { correlationId, username, platform, environment, appVersion, clientVersion } = data
    return new FaultContext(correlationId, username, platform, environment, appVersion, clientVersion)
  }
}

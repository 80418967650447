import { all, call, put, takeEvery } from 'redux-saga/effects'

import actions from 'redux/menu/actions'
import getMenuData from 'services/menu'
import { isTokenExist } from 'helpers/token'

export function* GET_DATA() {
  if (!isTokenExist()) return false

  const data = yield call(getMenuData)
  if (data) {
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuData: data.menuItemList,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DATA, GET_DATA),
    GET_DATA(), // run once on app load to fetch menu data
  ])
}

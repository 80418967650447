export default class ExportDefectiveClaimReq {
  constructor(page, size, claimDate, gtClaimDate, ltClaimDate, reporterName, productCode, processStatus, reportFormat, reportPattern, lang) {
    this.page = page
    this.size = size
    this.claimDate = claimDate
    this.gtClaimDate = gtClaimDate
    this.ltClaimDate = ltClaimDate
    this.reporterName = reporterName
    this.productCode = productCode
    this.processStatus = processStatus
    this.reportFormat = reportFormat
    this.reportPattern = reportPattern
    this.lang = lang
  }

  static of(data) {
    const { page, size, claimDate, gtClaimDate, ltClaimDate, reporterName, productCode, processStatus, reportFormat, reportPattern, lang } = data
    return new ExportDefectiveClaimReq(page, size, claimDate, gtClaimDate, ltClaimDate, reporterName, productCode,
      processStatus, reportFormat, reportPattern, lang || "TR")
  }
}

export default class CreateLedReq {
  constructor(code, originalCodeList, ledCountList, ledLengthList, tvModelList, tvPanelList, tvSizeList, tvBrandList, chinaCodeList, firmCodeList) {
    this.code = code
    this.originalCodeList = originalCodeList
    this.ledCountList = ledCountList
    this.ledLengthList = ledLengthList
    this.tvModelList = tvModelList
    this.tvPanelList = tvPanelList
    this.tvSizeList = tvSizeList
    this.tvBrandList = tvBrandList
    this.chinaCodeList = chinaCodeList
    this.firmCodeList = firmCodeList
  }

  static of(data) {
    const {
      code,
      originalCodeList,
      ledCountList,
      ledLengthList,
      tvModelList,
      tvPanelList,
      tvSizeList,
      tvBrandList,
      chinaCodeList,
      firmCodeList
    } = data
    return new CreateLedReq(code,
      originalCodeList,
      ledCountList,
      ledLengthList,
      tvModelList,
      tvPanelList,
      tvSizeList,
      tvBrandList,
      chinaCodeList,
      firmCodeList)
  }
}

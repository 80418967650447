export default class CreateCategoryGroupReq {
  constructor(name, prio, categoryIdList) {
    this.name = name
    this.prio = prio
    this.categoryIdList = categoryIdList
  }

  static of(data) {
    const { name, prio, categoryIdList } = data
    return new CreateCategoryGroupReq(name, prio, categoryIdList)
  }
}

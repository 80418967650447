import Banner from 'model/ecom/banner/Banner'

export default class BannerList {
  constructor(bannerList, totalCount) {
    this.bannerList = bannerList
    this.totalCount = totalCount
  }

  getById = id => {
    const found = this.bannerList.filter(b => b.id === parseInt(id, 10))
    if (found.length > 0) {
      return found[0]
    }
    return null
  }

  add = banner => {
    this.bannerList.push(banner)
    this.totalCount += 1

    return new BannerList(this.bannerList, this.totalCount)
  }

  remove = bannerId => {
    this.bannerList = this.bannerList.filter(c => c.id !== bannerId)
    this.totalCount -= 1

    return new BannerList(this.bannerList, this.totalCount)
  }

  static empty() {
    return new BannerList([], 0)
  }

  static fromData(data) {
    return new BannerList(
      data.bannerList.map(d => Banner.from(d)),
      data.totalCount,
    )
  }
}

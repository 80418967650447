export default class CreateUserReq {
  constructor(username,
              password,
              firstName,
              lastName,
              alias,
              showAlias,
              primaryEmail,
              phoneNo,
              roleId) {
    this.username = username
    this.password = password
    this.firstName = firstName
    this.lastName = lastName
    this.alias = alias
    this.showAlias = showAlias
    this.primaryEmail = primaryEmail
    this.phoneNo = phoneNo
    this.roleId = roleId
  }

  static of(data) {
    const { username, password, firstName, lastName, alias, showAlias, primaryEmail, phoneNo, roleId } = data
    return new CreateUserReq(username, password, firstName, lastName, alias, showAlias, primaryEmail, phoneNo, roleId)
  }
}

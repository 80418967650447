export default class FaultTraceFrame {
  constructor(filename, lineNumber, method, className) {
    this.filename = filename
    this.lineNumber = lineNumber
    this.method = method
    this.className = className
  }

  static empty() {
    return new FaultTraceFrame("", "", "", "")
  }

  static from(data) {
    const { filename, lineNumber, method, className } = data
    return new FaultTraceFrame(filename, lineNumber, method, className)
  }

  prettyString = () => {
    const clazz = this.className || this.filename
    const method = this.method || ""
    const line = this.lineNumber || 0
    return `${clazz}.${method}:${line}`
  }
}

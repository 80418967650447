export default class ExportCategoryReq {
  constructor(exportFormat, lang) {
    this.exportFormat = exportFormat
    this.lang = lang
  }

  static of(data) {
    const { exportFormat, lang } = data
    return new ExportCategoryReq(exportFormat, lang || "TR")
  }
}

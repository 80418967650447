import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown } from 'antd'

import styles from 'components/cleanui/layout/TopBar/LanguageSwitcher/style.module.scss'

const mapStateToProps = ({ settings }) => ({
  tenantLocale: settings.tenantLocale,
})

const LanguageSwitcher = ({ dispatch, tenantLocale }) => {
  const changeLanguage = ({ key }) => {
    tenantLocale = key
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'tenantLocale',
        value: tenantLocale,
      },
    })
  }
  const language = tenantLocale.substr(0, 2)
  const menu = (
    <Menu selectedKeys={[tenantLocale]} onClick={changeLanguage}>
      <Menu.Item key="tr-TR">
        <span className="text-uppercase font-size-12 me-2">TR</span>
        Türkçe
      </Menu.Item>
      <Menu.Item key="en-US">
        <span className="text-uppercase font-size-12 me-2">EN</span>
        English
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <div className={styles.dropdown}>
        <span className="text-uppercase">{language}</span>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(LanguageSwitcher)

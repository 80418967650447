// eslint-disable-next-line import/prefer-default-export
export function toFormData(request) {
  const buildFormData = (formData, data, parentKey) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !Array.isArray(data)) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}.${key}` : key)
      })
    } else if (Array.isArray(data)) {
      data.forEach(element => formData.append(parentKey, element))
    } else if (data != null) {
      formData.append(parentKey, data)
    }
  }

  const formData = new FormData()
  buildFormData(formData, request)

  return formData
}

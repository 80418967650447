import Price from 'model/product/Price'
import Tag from 'model/product/Tag'
import ProductRelationship from 'model/product/ProductRelationship'
import Media from 'model/product/Media'
import Discount from 'model/product/Discount'
import FinalPrice from 'model/product/FinalPrice'
import ProductGood from 'model/product/ProductGood'
import ProductFlags from 'model/product/ProductFlags'
import ProductFeatures from 'model/product/ProductFeatures'
import CartInfo from 'model/product/CartInfo'
import ProductCategory from 'model/product/ProductCategory'
import ProductBrand from 'model/product/ProductBrand'

export default class Product {
  constructor(id, code, name, desc, barcode, serialNo, good, flags, feature, cartInfo, category, brand, price,
              discount, finalPrice, tagList, relationshipList, mediaList) {
    this.id = id
    this.code = code
    this.name = name
    this.desc = desc
    this.barcode = barcode
    this.serialNo = serialNo
    this.good = good
    this.flags = flags
    this.feature = feature
    this.cartInfo = cartInfo
    this.category = category
    this.brand = brand
    this.price = price
    this.discount = discount
    this.finalPrice = finalPrice
    this.tagList = tagList
    this.relationshipList = relationshipList
    this.mediaList = mediaList
  }

  static empty() {
    return new Product(0, "", "")
  }

  static fromData(data) {
    return this.from(data.product)
  }

  static from(data) {
    const {
      id,
      code,
      name,
      desc,
      barcode,
      serialNo,
      good,
      flags,
      feature,
      cartInfo,
      category,
      brand,
      price,
      discount,
      finalPrice,
      tagList,
      relationshipList,
      mediaList
    } = data

    return new Product(
      id,
      code,
      name,
      desc,
      barcode,
      serialNo,
      ProductGood.from(good),
      ProductFlags.from(flags),
      ProductFeatures.from(feature),
      CartInfo.from(cartInfo),
      ProductCategory.from(category),
      ProductBrand.from(brand),
      Price.from(price),
      discount ? Discount.from(discount) : null,
      finalPrice ? FinalPrice.from(finalPrice) : null,
      (tagList || []).map(t => Tag.from(t)),
      (relationshipList || []).map(r => ProductRelationship.from(r)),
      (mediaList || []).map(m => Media.from(m))
    )
  }

  static getHiddenReasons() {
    return [this.HIDDEN_NONE, this.HIDDEN_NOT_IMPORTED_YET, this.HIDDEN_WILL_NOT_SUPPLIED]
  }

  getTagList = () => {
    return (this.tagList || []).map(t => t.name)
  }

  getRelatedCodeList = () => {
    return (this.relationshipList || [])
      .filter(relationship => relationship.type === Product.RELATION_RELATED_ITEM)
      .map(relatedItem => relatedItem.code)
  }

  static HIDDEN_NONE = "NONE"

  static HIDDEN_NOT_IMPORTED_YET = "NOT_IMPORTED_YET"

  static HIDDEN_WILL_NOT_SUPPLIED = "WILL_NOT_SUPPLIED"

  static RELATION_RELATED_ITEM = "RELATED_ITEM"

  static REPORT_FORMAT = { XLS: "XLS" }

  static REPORT_FORMAT_LIST = [Product.REPORT_FORMAT.XLS]
}

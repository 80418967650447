import LineProduct from 'model/order/LineProduct'

export default class OrderLine {
  constructor(id, quantity, product, subTotal, lineTotal) {
    this.id = id
    this.quantity = quantity
    this.product = product
    this.subTotal = subTotal
    this.lineTotal = lineTotal
  }

  static empty() {
    return new OrderLine(0, 0, LineProduct.empty(), 0, 0)
  }

  static from(data) {
    const { id, quantity, product, subTotal, lineTotal } = data
    return new OrderLine(id, quantity, product, subTotal, lineTotal)
  }
}

import actions from 'redux/inventory/actions'
import Inventory from 'model/inventory/Inventory'
import InventoryList from 'model/inventory/InventoryList'
import InventoryAdjustmentHistoryList from 'model/inventory/InventoryAdjustmentHistoryList'
import InventoryRegion from 'model/inventory/InventoryRegion'
import InventoryRegionList from 'model/inventory/InventoryRegionList'

const initialState = {
  inventoryList: InventoryList.empty(),
  inventoryDetail: Inventory.empty(),
  historyList: InventoryAdjustmentHistoryList.empty(),
  regionList: InventoryRegionList.empty(),
  regionDetail: InventoryRegion.empty(),
  loading: false,
}

export default function inventoryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      if (action.payload.inventory) {
        state.inventoryList = state.inventoryList.add(action.payload.inventory)
      }
      if (action.payload.region) {
        state.regionList = state.regionList.add(action.payload.region)
      }

      return state
    }
    case actions.DELETE_STATE: {
      if (action.payload.inventoryId) {
        state.inventoryList = state.inventoryList.remove(action.payload.inventoryId)
      }
      if (action.payload.regionId) {
        state.regionList = state.regionList.remove(action.payload.regionId)
      }
      return state
    }
    default:
      return state
  }
}

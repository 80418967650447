import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import { history } from 'index'
import * as deliveryService from 'services/delivery'
import actions from 'redux/delivery/actions'
import Delivery from 'model/delivery/Delivery'
import DeliveryList from 'model/delivery/DeliveryList'
import ListDeliveryReq from 'model/delivery/req/ListDeliveryReq'
import SearchDeliveryReq from 'model/delivery/req/SearchDeliveryReq'
import CreateDeliveryReq from 'model/delivery/req/CreateDeliveryReq'
import UpdateDeliveryReq from 'model/delivery/req/UpdateDeliveryReq'

export function* LIST_DELIVERY({ payload }) {
  yield put({
    type: 'delivery/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(deliveryService.listDelivery, ListDeliveryReq.of(payload))
  if (data) {
    yield put({
      type: 'delivery/SET_STATE',
      payload: {
        list: DeliveryList.fromData(data),
        detail: Delivery.empty()
      }
    })
  }

  yield put({
    type: 'delivery/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_DELIVERY({ payload }) {
  yield put({
    type: 'delivery/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { deliveryId } = payload
  const data = yield call(deliveryService.readDelivery, deliveryId)
  if (data) {
    yield put({
      type: 'delivery/SET_STATE',
      payload: {
        detail: Delivery.fromData(data)
      }
    })
  }

  yield put({
    type: 'delivery/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SEARCH_DELIVERY({ payload }) {
  yield put({
    type: 'delivery/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(deliveryService.searchDelivery, SearchDeliveryReq.of(payload))
  if (data) {
    yield put({
      type: 'delivery/SET_STATE',
      payload: {
        list: DeliveryList.fromData(data),
        detail: Delivery.empty()
      }
    })
  }

  yield put({
    type: 'delivery/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_DELIVERY({ payload }) {
  yield put({
    type: 'delivery/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(deliveryService.createDelivery, CreateDeliveryReq.of(payload))
  if (data) {
    yield put({
      type: 'delivery/ADD_STATE',
      payload: {
        delivery: Delivery.fromData(data)
      }
    })
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'delivery/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_DELIVERY({ payload }) {
  yield put({
    type: 'delivery/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(deliveryService.updateDelivery, UpdateDeliveryReq.of(payload))
  if (success) {
    yield history.push('/order/delivery/view')
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'delivery/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_DELIVERY({ payload }) {
  yield put({
    type: 'delivery/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { deliveryId } = payload
  const success = yield call(deliveryService.deleteDelivery, deliveryId)
  if (success) {
    yield put({
      type: 'delivery/DELETE_STATE',
      payload: { deliveryId }
    })
  }

  yield put({
    type: 'delivery/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_DELIVERY, LIST_DELIVERY),
    takeEvery(actions.READ_DELIVERY, READ_DELIVERY),
    takeEvery(actions.SEARCH_DELIVERY, SEARCH_DELIVERY),
    takeEvery(actions.CREATE_DELIVERY, CREATE_DELIVERY),
    takeEvery(actions.UPDATE_DELIVERY, UPDATE_DELIVERY),
    takeEvery(actions.DELETE_DELIVERY, DELETE_DELIVERY),
  ])
}

import DiscountRule from 'model/discount/DiscountRule'

export default class CreateDiscountRuleReq {
  constructor(subjectType, subjectId, objectType, objectId, discountAmount, discountType, minBoxAmount) {
    this.subjectType = subjectType
    this.subjectId = subjectId
    this.objectType = objectType
    this.objectId = objectId
    this.discountAmount = discountAmount
    this.discountType = discountType
    this.minBoxAmount = minBoxAmount
  }

  static of(data) {
    const { subjectType, subjectId, objectType, objectId, discountAmount, discountType, minBoxAmount } = data
    return new CreateDiscountRuleReq(
      subjectType || DiscountRule.SUBJECT_TYPE.ALL,
      subjectId || 0,
      objectType || DiscountRule.OBJECT_TYPE.ALL,
      objectId || 0,
      discountAmount,
      discountType,
      minBoxAmount || 0)
  }
}

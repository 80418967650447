import InventoryProduct from 'model/inventory/InventoryProduct'
import InventoryRegion from 'model/inventory/InventoryRegion'

export default class Inventory {
  constructor(id, region, product, quantity, available) {
    this.id = id
    this.region = region
    this.product = product
    this.quantity = quantity
    this.available = available
  }

  static empty() {
    return new Inventory(0, InventoryRegion.empty(), InventoryProduct.empty(), 0, false)
  }

  static fromData(data) {
    return this.from(data.inventory)
  }

  static from(data) {
    const { id, region, product, quantity, available } = data
    return new Inventory(id, region, product, quantity, available)
  }
}

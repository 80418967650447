export default class ListCustomerGroupReq {
  constructor(page, size, customerId) {
    this.page = page
    this.size = size
    this.customerId = customerId
  }

  static of(data) {
    const { page, size, customerId } = data
    return new ListCustomerGroupReq(page, size, customerId)
  }
}

export default class ContactForm {
  constructor(
    id,
    name,
    email,
    phoneNo,
    subject,
    message,
    completed,
    createdDate,
    prettyCreatedDate,
  ) {
    this.id = id
    this.name = name
    this.email = email
    this.phoneNo = phoneNo
    this.subject = subject
    this.message = message
    this.completed = completed
    this.createdDate = createdDate
    this.prettyCreatedDate = prettyCreatedDate
  }

  static empty() {
    return new ContactForm(0, '', '', '', '', '', false, '', '')
  }

  static fromData(data) {
    return this.from(data.contactForm)
  }

  static from(data) {
    const {
      id,
      name,
      email,
      phoneNo,
      subject,
      message,
      completed,
      createdDate,
      prettyCreatedDate,
    } = data
    return new ContactForm(
      id,
      name,
      email,
      phoneNo,
      subject,
      message,
      completed,
      createdDate,
      prettyCreatedDate,
    )
  }
}

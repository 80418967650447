export default class CompleteDefectiveClaimReq {
  constructor(completedDate) {
    this.completedDate = completedDate
  }

  static of(data) {
    const { completedDate } = data
    return new CompleteDefectiveClaimReq(completedDate)
  }
}

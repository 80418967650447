export default class DefectiveProduct {
  constructor(productCode) {
    this.productCode = productCode
  }

  static empty() {
    return new DefectiveProduct("")
  }

  static from(data) {
    const { productCode } = data
    return new DefectiveProduct(productCode)
  }
}

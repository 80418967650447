const actions = {
  LIST_CUSTOMER: 'customer/LIST_CUSTOMER',
  READ_CUSTOMER: 'customer/READ_CUSTOMER',
  SEARCH_CUSTOMER: 'customer/SEARCH_CUSTOMER',
  EXPORT_CUSTOMER: 'customer/EXPORT_CUSTOMER',
  CREATE_CUSTOMER: 'customer/CREATE_CUSTOMER',
  UPDATE_CUSTOMER: 'customer/UPDATE_CUSTOMER',
  DELETE_CUSTOMER: 'customer/DELETE_CUSTOMER',
  SET_STATE: 'customer/SET_STATE',
  ADD_STATE: 'customer/ADD_STATE',
  DELETE_STATE: 'customer/DELETE_STATE',
}

export default actions

import Delivery from 'model/delivery/Delivery'

export default class DeliveryList {
  constructor(deliveryList, totalCount) {
    this.deliveryList = deliveryList
    this.totalCount = totalCount
  }

  add = (news) => {
    this.deliveryList.push(news)
    this.totalCount += 1

    return new DeliveryList(this.deliveryList, this.totalCount)
  }

  remove = (newsId) => {
    this.deliveryList = this.deliveryList.filter(c => c.id !== newsId)
    this.totalCount -= 1

    return new DeliveryList(this.deliveryList, this.totalCount)
  }

  static empty() {
    return new DeliveryList([], 0)
  }

  static fromData(data) {
    return new DeliveryList(
      data.deliveryList.map(d => Delivery.from(d)),
      data.totalCount
    )
  }
}

export default class ListNewsReq {
  constructor(page, size, beginStart, beginEnd, untilStart, untilEnd) {
    this.page = page
    this.size = size
    this.beginStart = beginStart
    this.beginEnd = beginEnd
    this.untilStart = untilStart
    this.untilEnd = untilEnd
  }

  static of(data) {
    const { page, size, beginStart, beginEnd, untilStart, untilEnd } = data
    return new ListNewsReq(page, size, beginStart, beginEnd, untilStart, untilEnd)
  }
}

import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'

export async function listInventory(req) {
  return apiClient
    .get(urls.inventory.list, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function listAdjustmentHistory(id) {
  const url = format(urls.inventory.listHistory, [id])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function searchInventory(req) {
  return apiClient
    .get(urls.inventory.search, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readInventory(id) {
  const url = format(urls.inventory.read, [id])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function createInventory(req) {
  return apiClient
    .post(urls.inventory.create, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function adjustInventory(req) {
  return apiClient
    .put(urls.inventory.adjust, req)
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function deleteInventory(id) {
  const url = format(urls.inventory.delete, [id])

  return apiClient
    .delete(url)
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

const actions = {
  LIST_ORDER: 'order/LIST_ORDER',
  READ_ORDER: 'order/READ_ORDER',
  CREATE_ORDER: 'order/CREATE_ORDER',
  DELIVER_ORDER: 'order/DELIVER_ORDER',
  CANCEL_ORDER: 'order/CANCEL_ORDER',
  DELETE_ORDER: 'order/DELETE_ORDER',
  SET_STATE: 'order/SET_STATE',
  ADD_STATE: 'order/ADD_STATE',
  DELETE_STATE: 'order/DELETE_STATE',
}

export default actions

export default class LoginReq {
  constructor(username, password, platform, fromIp) {
    this.username = username
    this.password = password
    this.platform = platform
    this.fromIp = fromIp
  }

  static of(data) {
    const { username, password } = data
    return new LoginReq(username, password)
  }
}

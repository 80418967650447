export default class SaveProductRelationshipReq {
  constructor(productId, relationList) {
    this.productId = productId
    this.relationList = relationList
  }

  static of(data) {
    const { productId, relationList } = data

    return new SaveProductRelationshipReq(productId, relationList || [])
  }
}

import CustomerGroup from 'model/customer/CustomerGroup'

export default class Customer {
  constructor(
    id,
    firstName,
    lastName,
    companyName,
    email,
    phoneNo,
    countryCode,
    city,
    town,
    district,
    branch,
    fullAddress,
    isCommunicationAllowed,
    salesmanNote,
    currentCode,
    status,
    relatedUser,
    authorizedUserList,
    groupList,
  ) {
    this.id = id
    this.firstName = firstName
    this.lastName = lastName
    this.companyName = companyName
    this.email = email
    this.phoneNo = phoneNo
    this.countryCode = countryCode
    this.city = city
    this.town = town
    this.district = district
    this.branch = branch
    this.fullAddress = fullAddress
    this.isCommunicationAllowed = isCommunicationAllowed
    this.salesmanNote = salesmanNote
    this.currentCode = currentCode
    this.status = status
    this.relatedUser = relatedUser
    this.authorizedUserList = authorizedUserList
    this.groupList = groupList
  }

  static REPORT_FORMAT = {
    XLS: 'XLS',
  }

  static REPORT_FORMAT_LIST = [this.REPORT_FORMAT.XLS]

  static empty() {
    return new Customer(0, '', '', '', '', '', '', '', '', '', '', '', false, 0, '', '', '', [], [])
  }

  static fromData(data) {
    return this.from(data.customer)
  }

  static from(object) {
    const {
      id,
      firstName,
      lastName,
      companyName,
      email,
      phoneNo,
      countryCode,
      city,
      town,
      district,
      branch,
      fullAddress,
      isCommunicationAllowed,
      salesmanNote,
      currentCode,
      status,
      relatedUser,
      authorizedUserList,
      groupList,
    } = object

    return new Customer(
      id,
      firstName,
      lastName,
      companyName,
      email,
      phoneNo,
      countryCode,
      city,
      town,
      district,
      branch,
      fullAddress,
      isCommunicationAllowed,
      salesmanNote,
      currentCode,
      status,
      relatedUser,
      authorizedUserList,
      (groupList || []).map(g => CustomerGroup.from(g)),
    )
  }
}

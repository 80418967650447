import PhysicalGood from 'model/product/PhysicalGood'

export default class ProductGood {
  constructor(type, physicalGood) {
    this.type = type
    this.physicalGood = physicalGood
  }

  isPhysical = () => {
    return this.type === ProductGood.TYPE_PHYSICAL
  }

  static empty() {
    return new ProductGood("", null)
  }

  static from(data) {
    const { type, physicalGood } = data
    return new ProductGood(type, PhysicalGood.from(physicalGood))
  }

  static TYPE_PHYSICAL = "PHYSICAL"

  static getTypes() {
    return [this.TYPE_PHYSICAL]
  }
}

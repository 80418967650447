const actions = {
  LOGIN: 'auth/LOGIN',
  REGISTER: 'auth/REGISTER',
  LOGOUT: 'auth/LOGOUT',
  FORGOT_PASSWORD: 'auth/FORGOT_PASSWORD',
  SET_STATE: 'auth/SET_STATE',
  LOAD_CURRENT_USER: 'auth/LOAD_CURRENT_USER',
}

export default actions

import ParentCategory from 'model/product/ParentCategory'

export default class Category {
  constructor(id, name, desc, prio, hidden, parent, children) {
    this.id = id
    this.name = name
    this.desc = desc
    this.prio = prio
    this.hidden = hidden
    this.parent = parent
    this.children = children
  }

  static empty() {
    return new Category(0, "", "", 0, false, null, [])
  }

  static fromData(data) {
    return this.from(data.category)
  }

  static from(data) {
    const { id, name, desc, prio, hidden, parent, childList } = data

    let children = []
    if (childList && childList.length > 0) {
      children = childList.map(c => this.from(c))
    }
    return new Category(id, name, desc, prio, hidden, ParentCategory.from(parent), children)
  }

  static REPORT_FORMAT = {
    XLS: "XLS"
  }

  static REPORT_FORMAT_LIST = [this.REPORT_FORMAT.XLS]

  toDepthString = () => {
    if (!this.parent) {
      return this.name
    }

    return `${this.parent.toDepthString()} / ${this.name}`
  }
}

import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'
import qs from 'qs'

export async function listOrder(req) {
  return apiClient
    .get(urls.order.list, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readOrder(orderCode) {
  const url = format(urls.order.read, [orderCode])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function exportOrder(req) {
  return apiClient
    .get(urls.order.export, {
      timeout: 60000,
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function createOrder(req) {
  return apiClient
    .post(urls.order.create, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function createDirectOrder(req) {
  return apiClient
    .post(urls.order.createDirect, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function deliverOrder(orderCode) {
  const url = format(urls.order.deliver, [orderCode])

  return apiClient
    .put(url, {})
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function cancelOrder(orderCode) {
  const url = format(urls.order.cancel, [orderCode])

  return apiClient
    .put(url, {})
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function deleteOrder(orderCode) {
  const url = format(urls.order.delete, [orderCode])

  return apiClient
    .delete(url)
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

import UserPersonal from 'model/user/UserPersonal'
import Role from 'model/user/Role'

export default class User {
  constructor(username,
              personal,
              status,
              role) {
    this.username = username
    this.personal = personal
    this.status = status
    this.role = role
  }

  static ENABLED = "ENABLED"

  static LOCKED = "LOCKED"

  static DISABLED = "DISABLED"

  static empty() {
    return new User("",
      UserPersonal.empty(),
      "",
      Role.empty())
  }

  static fromData(data) {
    return this.from(data.user)
  }

  static from(object) {
    const {
      username,
      personal,
      status,
      role,
    } = object

    return new User(username,
      UserPersonal.from(personal),
      status,
      Role.from(role))
  }
}

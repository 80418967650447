export default class SaveCategoryUserSettingsReq {
  constructor(username, disabledCategoryList) {
    this.username = username
    this.disabledCategoryList = disabledCategoryList
  }

  static of(data) {
    const { username, disabledCategoryList } = data

    return new SaveCategoryUserSettingsReq(
      username,
      disabledCategoryList || [])
  }
}

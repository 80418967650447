import actions from 'redux/toggle/actions'
import Toggle from 'model/toggle/Toggle'
import ToggleList from 'model/toggle/ToggleList'

const initialState = {
  list: ToggleList.empty(),
  detail: Toggle.empty(),
  loading: false,
}

export default function toggleReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.toggle)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.key)
      return state
    }
    default:
      return state
  }
}

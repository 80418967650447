export default class Tag {
  constructor(id, name) {
    this.id = id
    this.name = name
  }

  static empty() {
    return new Tag(0, "")
  }

  static from(data) {
    const { id, name } = data
    return new Tag(id, name)
  }
}

export default class OrderUser {
  constructor(username, initiatorUsername) {
    this.username = username
    this.initiatorUsername = initiatorUsername
  }

  static empty() {
    return new OrderUser('', '')
  }

  static from(data) {
    const { username, initiatorUsername } = data
    return new OrderUser(username, initiatorUsername)
  }
}

export default class UpdateNewsReq {
  constructor(newsId, title, content, beginDate, untilDate, prio) {
    this.newsId = newsId
    this.title = title
    this.content = content
    this.beginDate = beginDate
    this.untilDate = untilDate
    this.prio = prio
  }

  static of(data) {
    const { newsId, title, content, beginDate, untilDate, prio } = data
    return new UpdateNewsReq(newsId, title, content, beginDate, untilDate, prio)
  }
}

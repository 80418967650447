import React from 'react'

import LanguageSwitcher from 'components/cleanui/layout/TopBar/LanguageSwitcher'
import UserMenu from 'components/cleanui/layout/TopBar/UserMenu'
import style from 'components/cleanui/layout/TopBar/style.module.scss'

const TopBar = () => {
  return (
    <div className={style.topbar}>
      <div className="me-auto" />
      <div className="me-4 d-none d-sm-block">
        <LanguageSwitcher />
      </div>
      <div className="">
        <UserMenu />
      </div>
    </div>
  )
}

export default TopBar

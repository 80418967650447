export default class UpdateBrandReq {
  constructor(brandId, name, own, logo) {
    this.brandId = brandId
    this.name = name
    this.own = own
    this.logo = logo
  }

  static of(data) {
    const { brandId, name, own, logo } = data
    return new UpdateBrandReq(brandId, name, own, logo)
  }
}

export default class ExportProductByFieldReq {
  constructor(excludeId,
              excludeCode,
              excludeName,
              excludeBarcode,
              excludeSerialNo,
              excludeGoodType,
              excludeGoodPhysical,
              excludeFlagIsHidden,
              excludeFlagHiddenReason,
              excludeFlagIsOpenCompetition,
              excludeFlagIsAllowAddToCart,
              excludeFlagIsAvailable,
              excludeFlagIsIgnoreInventory,
              excludeFeatureBestSeller,
              excludeFeatureVitrine,
              excludeFeatureRecent,
              excludeFeatureFuture,
              excludeFeatureSoldOut,
              excludeFeatureCampaign,
              excludeCartMinQuantityInCart,
              excludePrice,
              excludeCategory,
              excludeBrand,
              excludeTagList,
              excludeRelatedRelation) {
    this.excludeId = excludeId
    this.excludeCode = excludeCode
    this.excludeName = excludeName
    this.excludeBarcode = excludeBarcode
    this.excludeSerialNo = excludeSerialNo
    this.excludeGoodType = excludeGoodType
    this.excludeGoodPhysical = excludeGoodPhysical
    this.excludeFlagIsHidden = excludeFlagIsHidden
    this.excludeFlagHiddenReason = excludeFlagHiddenReason
    this.excludeFlagIsOpenCompetition = excludeFlagIsOpenCompetition
    this.excludeFlagIsAllowAddToCart = excludeFlagIsAllowAddToCart
    this.excludeFlagIsAvailable = excludeFlagIsAvailable
    this.excludeFlagIsIgnoreInventory = excludeFlagIsIgnoreInventory
    this.excludeFeatureBestSeller = excludeFeatureBestSeller
    this.excludeFeatureVitrine = excludeFeatureVitrine
    this.excludeFeatureRecent = excludeFeatureRecent
    this.excludeFeatureFuture = excludeFeatureFuture
    this.excludeFeatureSoldOut = excludeFeatureSoldOut
    this.excludeFeatureCampaign = excludeFeatureCampaign
    this.excludeCartMinQuantityInCart = excludeCartMinQuantityInCart
    this.excludePrice = excludePrice
    this.excludeCategory = excludeCategory
    this.excludeBrand = excludeBrand
    this.excludeTagList = excludeTagList
    this.excludeRelatedRelation = excludeRelatedRelation
  }

  static of(data) {
    const {
      excludeId,
      excludeCode,
      excludeName,
      excludeBarcode,
      excludeSerialNo,
      excludeGoodType,
      excludeGoodPhysical,
      excludeFlagIsHidden,
      excludeFlagHiddenReason,
      excludeFlagIsOpenCompetition,
      excludeFlagIsAllowAddToCart,
      excludeFlagIsAvailable,
      excludeFlagIsIgnoreInventory,
      excludeFeatureBestSeller,
      excludeFeatureVitrine,
      excludeFeatureRecent,
      excludeFeatureFuture,
      excludeFeatureSoldOut,
      excludeFeatureCampaign,
      excludeCartMinQuantityInCart,
      excludePrice,
      excludeCategory,
      excludeBrand,
      excludeTagList,
      excludeRelatedRelation
    } = data
    return new ExportProductByFieldReq(
      excludeId || false,
      excludeCode || false,
      excludeName || false,
      excludeBarcode || false,
      excludeSerialNo || false,
      excludeGoodType || false,
      excludeGoodPhysical || false,
      excludeFlagIsHidden || false,
      excludeFlagHiddenReason || false,
      excludeFlagIsOpenCompetition || false,
      excludeFlagIsAllowAddToCart || false,
      excludeFlagIsAvailable || false,
      excludeFlagIsIgnoreInventory || false,
      excludeFeatureBestSeller || false,
      excludeFeatureVitrine || false,
      excludeFeatureRecent || false,
      excludeFeatureFuture || false,
      excludeFeatureSoldOut || false,
      excludeFeatureCampaign || false,
      excludeCartMinQuantityInCart || false,
      excludePrice || false,
      excludeCategory || false,
      excludeBrand || false,
      excludeTagList || false,
      excludeRelatedRelation || false
    )
  }
}

export default class UpdateUserPersonalReq {
  constructor(firstName,
              lastName,
              alias,
              showAlias,
              primaryEmail,
              phoneNo) {
    this.firstName = firstName
    this.lastName = lastName
    this.alias = alias
    this.showAlias = showAlias
    this.primaryEmail = primaryEmail
    this.phoneNo = phoneNo
  }

  static of(data) {
    const { firstName, lastName, alias, showAlias, primaryEmail, phoneNo } = data
    return new UpdateUserPersonalReq(firstName, lastName, alias, showAlias, primaryEmail, phoneNo)
  }
}

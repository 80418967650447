import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import { history } from 'index'
import * as bannerService from 'services/ecom/banner'
import actions from 'redux/banner/actions'
import Banner from 'model/ecom/banner/Banner'
import BannerList from 'model/ecom/banner/BannerList'
import CreateBannerReq from 'model/ecom/banner/req/CreateBannerReq'
import UpdateBannerReq from 'model/ecom/banner/req/UpdateBannerReq'

export function* LIST_BANNER() {
  yield put({
    type: 'banner/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(bannerService.listBanner)
  if (data) {
    yield put({
      type: 'banner/SET_STATE',
      payload: {
        list: BannerList.fromData(data),
        detail: Banner.empty(),
      },
    })
  }

  yield put({
    type: 'banner/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_BANNER({ payload }) {
  yield put({
    type: 'banner/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(bannerService.createBanner, CreateBannerReq.of(payload))
  if (data) {
    yield put({
      type: 'banner/ADD_STATE',
      payload: {
        banner: Banner.fromData(data),
      },
    })
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'banner/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_BANNER({ payload }) {
  yield put({
    type: 'banner/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(bannerService.updateBanner, UpdateBannerReq.of(payload))
  if (success) {
    yield history.push('/ecom/banner/view')
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'banner/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_BANNER({ payload }) {
  yield put({
    type: 'banner/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { bannerId } = payload
  const success = yield call(bannerService.deleteBanner, bannerId)
  if (success) {
    yield put({
      type: 'banner/DELETE_STATE',
      payload: { bannerId },
    })
  }

  yield put({
    type: 'banner/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_BANNER, LIST_BANNER),
    takeEvery(actions.CREATE_BANNER, CREATE_BANNER),
    takeEvery(actions.UPDATE_BANNER, UPDATE_BANNER),
    takeEvery(actions.DELETE_BANNER, DELETE_BANNER),
  ])
}

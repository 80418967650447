export default class ListDefectiveClaimReq {
  constructor(page, size, claimDate, gtClaimDate, ltClaimDate, reporterName, productCode, processStatus) {
    this.page = page
    this.size = size
    this.claimDate = claimDate
    this.gtClaimDate = gtClaimDate
    this.ltClaimDate = ltClaimDate
    this.reporterName = reporterName
    this.productCode = productCode
    this.processStatus = processStatus
  }

  static of(data) {
    const { page, size, claimDate, gtClaimDate, ltClaimDate, reporterName, productCode, processStatus } = data
    return new ListDefectiveClaimReq(page, size, claimDate, gtClaimDate, ltClaimDate, reporterName, productCode, processStatus)
  }
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import * as userService from 'services/user'
import * as roleService from 'services/user/role'
import actions from 'redux/user/actions'
import User from 'model/user/User'
import UserList from 'model/user/UserList'
import RoleList from 'model/user/RoleList'
import ListUserReq from 'model/user/req/ListUserReq'
import SearchUserReq from 'model/user/req/SearchUserReq'
import CreateUserReq from 'model/user/req/CreateUserReq'
import UpdateUserPersonalReq from 'model/user/req/UpdateUserPersonalReq'
import UpdateUserRoleReq from 'model/user/req/UpdateUserRoleReq'
import UpdateUserPasswordReq from 'model/user/req/UpdateUserPasswordReq'
import EnableUserReq from 'model/user/req/EnableUserReq'

import { history } from '../../index'

export function* LIST_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(userService.listUser, ListUserReq.of(payload))
  if (data) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        list: UserList.fromData(data),
        detail: User.empty()
      }
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { username } = payload
  const data = yield call(userService.readUser, username)
  if (data) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        detail: User.fromData(data)
      }
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SEARCH_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(userService.searchUser, SearchUserReq.of(payload))
  if (data) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        list: UserList.fromData(data),
        detail: User.empty()
      }
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(userService.createUser, CreateUserReq.of(payload))
  if (data) {
    yield put({
      type: 'user/ADD_STATE',
      payload: {
        user: User.fromData(data)
      }
    })

    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_USER_PERSONAL({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { username } = payload
  const data = yield call(userService.updateUserPersonal, username, UpdateUserPersonalReq.of(payload))
  if (data) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        detail: User.fromData(data)
      }
    })

    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_USER_ROLE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { username } = payload
  const data = yield call(userService.updateUserRole, username, UpdateUserRoleReq.of(payload))
  if (data) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        detail: User.fromData(data)
      }
    })

    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_USER_PASSWORD({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { username } = payload
  const data = yield call(userService.updateUserPassword, username, UpdateUserPasswordReq.of(payload))
  if (data) {
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOCK_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { username } = payload
  const data = yield call(userService.lockUser, username)
  if (data) {
    yield put({
      type: 'user/SET_DETAIL_STATE',
      payload: {
        status: "LOCKED"
      },
    })

    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UNLOCK_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { username } = payload
  const data = yield call(userService.unlockUser, username)
  if (data) {
    yield put({
      type: 'user/SET_DETAIL_STATE',
      payload: {
        status: "ENABLED"
      },
    })

    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* ENABLE_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { username } = payload
  const data = yield call(userService.enableUser, username, EnableUserReq.of(payload))
  if (data) {
    yield put({
      type: 'user/SET_DETAIL_STATE',
      payload: {
        status: "ENABLED"
      },
    })

    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DISABLE_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { username } = payload
  const data = yield call(userService.disableUser, username)
  if (data) {
    yield put({
      type: 'user/SET_DETAIL_STATE',
      payload: {
        status: "DISABLED"
      },
    })

    yield history.push('/user/view')

    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LIST_ROLE() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(roleService.listRole)
  if (data) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        roleList: RoleList.fromData(data),
      }
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_USER, LIST_USER),
    takeEvery(actions.READ_USER, READ_USER),
    takeEvery(actions.SEARCH_USER, SEARCH_USER),
    takeEvery(actions.CREATE_USER, CREATE_USER),
    takeEvery(actions.UPDATE_USER_PERSONAL, UPDATE_USER_PERSONAL),
    takeEvery(actions.UPDATE_USER_PASSWORD, UPDATE_USER_PASSWORD),
    takeEvery(actions.LOCK_USER, LOCK_USER),
    takeEvery(actions.UNLOCK_USER, UNLOCK_USER),
    takeEvery(actions.ENABLE_USER, ENABLE_USER),
    takeEvery(actions.DISABLE_USER, DISABLE_USER),
    takeEvery(actions.LIST_ROLE, LIST_ROLE),
  ])
}

const actions = {
  LIST_CATEGORY: 'category/LIST_CATEGORY',
  LIST_FLAT_CATEGORY: 'category/LIST_FLAT_CATEGORY',
  LIST_PARENT_CATEGORY: 'category/LIST_PARENT_CATEGORY',
  READ_CATEGORY: 'category/READ_CATEGORY',
  SEARCH_CATEGORY: 'category/SEARCH_CATEGORY',
  EXPORT_CATEGORY: 'category/EXPORT_CATEGORY',
  CREATE_CATEGORY: 'category/CREATE_CATEGORY',
  UPDATE_CATEGORY: 'category/UPDATE_CATEGORY',
  DELETE_CATEGORY: 'category/DELETE_CATEGORY',
  SET_STATE: 'category/SET_STATE',
  ADD_STATE: 'category/ADD_STATE',
  DELETE_STATE: 'category/DELETE_STATE',
}

export default actions

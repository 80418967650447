export default class UpdateCustomerReq {
  constructor(
    customerId,
    firstName,
    lastName,
    companyName,
    email,
    phoneNo,
    city,
    town,
    district,
    branch,
    fullAddress,
    communicationAllowed,
    salesmanNote,
    currentCode,
    relatedUser,
    authorizedUserList,
    groupIdList,
  ) {
    this.customerId = customerId
    this.firstName = firstName
    this.lastName = lastName
    this.companyName = companyName
    this.email = email
    this.phoneNo = phoneNo
    this.city = city
    this.town = town
    this.district = district
    this.branch = branch
    this.fullAddress = fullAddress
    this.communicationAllowed = communicationAllowed
    this.salesmanNote = salesmanNote
    this.currentCode = currentCode
    this.relatedUser = relatedUser
    this.authorizedUserList = authorizedUserList
    this.groupIdList = groupIdList
  }

  static of(data) {
    const {
      customerId,
      firstName,
      lastName,
      companyName,
      email,
      phoneNo,
      city,
      town,
      district,
      branch,
      fullAddress,
      isCommunicationAllowed,
      salesmanNote,
      currentCode,
      relatedUser,
      authorizedUserList,
      groupIdList,
    } = data

    return new UpdateCustomerReq(
      customerId,
      firstName,
      lastName,
      companyName,
      email,
      phoneNo,
      city,
      town,
      district,
      branch,
      fullAddress,
      isCommunicationAllowed,
      salesmanNote,
      currentCode,
      relatedUser,
      authorizedUserList,
      groupIdList,
    )
  }
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import { history } from 'index'
import * as brandService from 'services/product/brand'
import actions from 'redux/brand/actions'
import Brand from 'model/product/Brand'
import BrandList from 'model/product/BrandList'
import SearchBrandReq from 'model/product/req/SearchBrandReq'
import CreateBrandReq from 'model/product/req/CreateBrandReq'
import UpdateBrandReq from 'model/product/req/UpdateBrandReq'

export function* LIST_BRAND() {
  yield put({
    type: 'brand/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(brandService.listBrand)
  if (data) {
    yield put({
      type: 'brand/SET_STATE',
      payload: {
        list: BrandList.fromData(data),
        detail: Brand.empty()
      }
    })
  }

  yield put({
    type: 'brand/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_BRAND({ payload }) {
  yield put({
    type: 'brand/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { brandId } = payload
  const data = yield call(brandService.readBrand, brandId)
  if (data) {
    yield put({
      type: 'brand/SET_STATE',
      payload: {
        detail: Brand.fromData(data)
      }
    })
  }

  yield put({
    type: 'brand/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SEARCH_BRAND({ payload }) {
  yield put({
    type: 'brand/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(brandService.searchBrand, SearchBrandReq.of(payload))
  if (data) {
    yield put({
      type: 'brand/SET_STATE',
      payload: {
        list: BrandList.fromData(data),
        detail: Brand.empty()
      }
    })
  }

  yield put({
    type: 'brand/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_BRAND({ payload }) {
  yield put({
    type: 'brand/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(brandService.createBrand, CreateBrandReq.of(payload))
  if (data) {
    yield put({
      type: 'brand/ADD_STATE',
      payload: {
        brand: Brand.fromData(data)
      }
    })
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'brand/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_BRAND({ payload }) {
  yield put({
    type: 'brand/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(brandService.updateBrand, UpdateBrandReq.of(payload))
  if (success) {
    yield history.push('/catalog/brand/view')
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'brand/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_BRAND({ payload }) {
  yield put({
    type: 'brand/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { brandId } = payload
  const success = yield call(brandService.deleteBrand, brandId)
  if (success) {
    yield put({
      type: 'brand/DELETE_STATE',
      payload: { brandId }
    })
  }

  yield put({
    type: 'brand/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_BRAND, LIST_BRAND),
    takeEvery(actions.READ_BRAND, READ_BRAND),
    takeEvery(actions.SEARCH_BRAND, SEARCH_BRAND),
    takeEvery(actions.CREATE_BRAND, CREATE_BRAND),
    takeEvery(actions.UPDATE_BRAND, UPDATE_BRAND),
    takeEvery(actions.DELETE_BRAND, DELETE_BRAND),
  ])
}

export default class SearchLedReq {
  constructor(page, size, searchText) {
    this.page = page
    this.size = size
    this.searchText = searchText
  }

  static of(data) {
    const { page, size, searchText } = data
    return new SearchLedReq(page, size, searchText)
  }
}

import Brand from 'model/product/Brand'

export default class BrandList {
  constructor(brands, totalCount) {
    this.brands = brands
    this.totalCount = totalCount
  }

  static empty() {
    return new BrandList([], 0)
  }

  static fromData(data) {
    return new BrandList(
      data.brandList.map(d => Brand.from(d)),
      data.brandList.length
    )
  }

  getById = (id) => {
    const found = this.brands.filter(b => b.id === parseInt(id, 10))
    if (found.length > 0) {
      return found[0]
    }
    return null
  }

  getByName = (name) => {
    const found = this.brands.filter(b => b.name === name)
    if (found.length > 0) {
      return found[0]
    }
    return null
  }

  getByNameOrId = (value) => {
    return this.getById(value) || this.getByName(value)
  }

  getNameById = (id) => {
    const found = this.brands.filter(b => b.id === parseInt(id, 10))
    if (found.length > 0) {
      return found[0].name
    }
    return ""
  }

  add = (brand) => {
    this.brands.push(brand)
    this.totalCount += 1

    return new BrandList(this.brands, this.totalCount)
  }

  remove = (brandId) => {
    this.brands = this.brands.filter(b => b.id !== brandId)
    this.totalCount -= 1

    return new BrandList(this.brands, this.totalCount)
  }
}

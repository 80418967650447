import FaultTraceFrame from 'model/fault/FaultTraceFrame'
import FaultClassInfo from 'model/fault/FaultClassInfo'

export default class FaultTrace {
  constructor(frames, classInfo) {
    this.frames = frames
    this.classInfo = classInfo
  }

  static empty() {
    return new FaultTrace([], "")
  }

  static from(data) {
    const { frames, classInfo } = data

    const clazzInfo = classInfo ? FaultClassInfo.from(classInfo) : FaultClassInfo.empty()
    return new FaultTrace((frames || []).map(frame => FaultTraceFrame.from(frame)), clazzInfo)
  }
}

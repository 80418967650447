import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'

// eslint-disable-next-line import/prefer-default-export
export async function listRole() {
  return apiClient
    .get(urls.user.role.list)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

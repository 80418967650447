export default class ListInventoryReq {
  constructor(page, size, regionId, productIdList, onlyZero, onlyPositive, onlyNegative) {
    this.page = page
    this.size = size
    this.regionId = regionId
    this.productIdList = productIdList
    this.onlyZero = onlyZero
    this.onlyPositive = onlyPositive
    this.onlyNegative = onlyNegative
  }

  static of(data) {
    const { page, size, regionId, productIdList, onlyZero, onlyPositive, onlyNegative } = data
    return new ListInventoryReq(
      page,
      size,
      regionId,
      productIdList,
      onlyZero,
      onlyPositive,
      onlyNegative,
    )
  }
}

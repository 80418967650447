import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import qs from "qs";

export async function listCustomerGroup(req) {
  return apiClient
    .get(urls.customerGroup.list, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function searchCustomerGroup(req) {
  return apiClient
    .get(urls.customerGroup.search, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readCustomerGroup(groupId) {
  return apiClient
    .get(urls.customerGroup.read + groupId)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function exportCustomerGroup(req) {
  return apiClient
    .get(urls.customerGroup.export, {
      timeout: 60000,
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function createCustomerGroup(req) {
  return apiClient
    .post(urls.customerGroup.create, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function updateCustomerGroup(req) {
  return apiClient
    .put(urls.customerGroup.update, req)
    .then((response) => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function deleteCustomerGroup(groupId) {
  return apiClient
    .delete(urls.customerGroup.read + groupId)
    .then((response) => {
      return !!response
    })
    .catch(err => console.log(err))
}

export default class CreateBrandReq {
  constructor(name, own, logo) {
    this.name = name
    this.own = own
    this.logo = logo
  }

  static of(data) {
    const { name, own, logo } = data
    return new CreateBrandReq(name, own || false, logo)
  }
}

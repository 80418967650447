import actions from 'redux/delivery/actions'
import Delivery from 'model/delivery/Delivery'
import DeliveryList from 'model/delivery/DeliveryList'

const initialState = {
  list: DeliveryList.empty(),
  detail: Delivery.empty(),
  loading: false
}

export default function deliveryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.delivery)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.deliveryId)
      return state
    }
    default:
      return state
  }
}

import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'

export async function listCategoryGroup() {
  return apiClient
    .get(urls.product.categoryGroup.list)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readCategoryGroup(groupId) {
  const url = format(urls.product.categoryGroup.read, [groupId])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function createCategoryGroup(req) {
  return apiClient
    .post(urls.product.categoryGroup.create, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function updateCategoryGroup(req) {
  return apiClient
    .put(urls.product.categoryGroup.update, req)
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function deleteCategoryGroup(groupId) {
  const url = format(urls.product.categoryGroup.delete, [groupId])

  return apiClient
    .delete(url)
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

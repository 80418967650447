import 'antd/lib/style/index.less' // antd core styles

import 'components/kit/vendors/antd/themes/default.less' // default theme antd components
import 'components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import 'global.scss' // app & third-party component styles

import React from 'react'
import { createRoot } from 'react-dom/client';
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { createHashHistory } from 'history'

import reducers from 'redux/reducers'
import sagas from 'redux/sagas'
import Localization from 'localization'
import Router from 'router'
import * as serviceWorker from 'serviceWorker'

// middleware
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]

const composeEnhancers =
  (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
const store = createStore(reducers(history), composeEnhancers(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }

export default class CreateRegionReq {
  constructor(name, defaultt) {
    this.name = name
    this.defaultt = defaultt
  }

  static of(data) {
    const { name, defaultt } = data
    return new CreateRegionReq(name, defaultt)
  }
}

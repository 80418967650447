import Led from 'model/product/Led'

export default class LedList {
  constructor(ledList, totalCount) {
    this.ledList = ledList
    this.totalCount = totalCount
  }

  static empty() {
    return new LedList([], 0)
  }

  static fromData(data) {
    return new LedList(
      data.ledList.map(d => Led.from(d)),
      data.totalCount
    )
  }

  search = (text) => {
    return this.ledList.filter(c => c.code.toLowerCase().indexOf(text.toLowerCase()) > -1)
  }

  add = (led) => {
    this.ledList.push(led)
    this.totalCount += 1

    return new LedList(this.ledList, this.totalCount)
  }

  remove = (ledId) => {
    this.ledList = this.ledList.filter(c => c.id !== ledId)
    this.totalCount -= 1

    return new LedList(this.ledList, this.totalCount)
  }
}

export default class CategoryUserSettings {
  constructor(username, disabledCategoryList) {
    this.username = username
    this.disabledCategoryList = disabledCategoryList
  }

  static empty() {
    return new CategoryUserSettings('', [])
  }

  static fromData(data) {
    return this.from(data.settings)
  }

  static from(data) {
    const { username, disabledCategoryList } = data

    const disabledCategoryListWithName = disabledCategoryList.map(id => {
      return { id, name: '' }
    })

    return new CategoryUserSettings(username, disabledCategoryListWithName)
  }
}

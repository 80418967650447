export default class Brand {
  constructor(id, name, own, logo) {
    this.id = id
    this.name = name
    this.own = own
    this.logo = logo
  }

  static empty() {
    return new Brand(0, "", false, "")
  }

  static fromData(data) {
    return this.from(data.brand)
  }

  static from(data) {
    const { id, name, own, logo } = data
    return new Brand(id, name, own, logo)
  }
}

import Role from 'model/user/Role'

export default class RoleList {
  constructor(roles, totalCount) {
    this.roles = roles
    this.totalCount = totalCount
  }

  static empty() {
    return new RoleList([], 0)
  }

  static fromData(data) {
    return new RoleList(
      data.roleList.map(d => Role.from(d)),
      data.totalCount
    )
  }
}

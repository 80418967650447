import actions from 'redux/order/actions'
import Order from 'model/order/Order'
import OrderList from 'model/order/OrderList'

const initialState = {
  list: OrderList.empty(),
  detail: Order.empty(),
  loading: false,
}

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.order)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.orderCode)
      return state
    }
    default:
      return state
  }
}

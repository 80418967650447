export default class PhysicalGood {
  constructor(minQuantityInCart) {
    this.minQuantityInCart = minQuantityInCart
  }

  static empty() {
    return new PhysicalGood(0)
  }

  static from(data) {
    const { minQuantityInCart } = data
    return new PhysicalGood(minQuantityInCart)
  }
}

export default class UpdateDeliveryReq {
  constructor(deliveryId, name, prio) {
    this.deliveryId = deliveryId
    this.name = name
    this.prio = prio
  }

  static of(data) {
    const { deliveryId, name, prio } = data
    return new UpdateDeliveryReq(deliveryId, name, prio)
  }
}

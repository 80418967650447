import actions from 'redux/banner/actions'
import Banner from 'model/ecom/banner/Banner'
import BannerList from 'model/ecom/banner/BannerList'

const initialState = {
  list: BannerList.empty(),
  detail: Banner.empty(),
  loading: false
}

export default function bannerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.banner)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.bannerId)
      return state
    }
    default:
      return state
  }
}

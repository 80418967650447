import DefectiveClaim from 'model/defective/DefectiveClaim'

export default class DefectiveClaimList {
  constructor(claimList, totalCount) {
    this.claimList = claimList
    this.totalCount = totalCount
  }

  add = (claim) => {
    this.claimList.push(claim)
    this.totalCount += 1

    return new DefectiveClaimList(this.claimList, this.totalCount)
  }

  remove = (claimId) => {
    this.claimList = this.claimList.filter(c => c.id !== claimId)
    this.totalCount -= 1

    return new DefectiveClaimList(this.claimList, this.totalCount)
  }

  static empty() {
    return new DefectiveClaimList([], 0)
  }

  static fromData(data) {
    return new DefectiveClaimList(
      data.claimList.map(d => DefectiveClaim.from(d)),
      data.totalCount
    )
  }
}

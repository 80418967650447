import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import { history } from 'index'
import * as discountRuleService from 'services/discount'
import * as customerService from 'services/customer'
import * as customerGroupService from 'services/customer-group'
import * as productService from 'services/product'
import * as categoryService from 'services/product/category'
import actions from 'redux/discount/actions'
import DiscountRule from 'model/discount/DiscountRule'
import DiscountRuleList from 'model/discount/DiscountRuleList'
import ListDiscountRuleReq from 'model/discount/req/ListDiscountRuleReq'
import CreateDiscountRuleReq from 'model/discount/req/CreateDiscountRuleReq'
import UpdateDiscountRuleReq from 'model/discount/req/UpdateDiscountRuleReq'

export function* LIST_DISCOUNT({ payload }) {
  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(discountRuleService.listDiscountRule, ListDiscountRuleReq.of(payload))
  if (data) {
    for (let i = 0; i < data.ruleList.length; i += 1) {
      const rule = data.ruleList[i]
      if (rule.subjectType === DiscountRule.SUBJECT_TYPE.ALL) {
        rule.subjectName = `${translate('common.label.all')} (${translate('discount.label.customer')})`
      } else if (rule.subjectType === DiscountRule.SUBJECT_TYPE.CUSTOMER) {
        const customer = yield call(customerService.readCustomer, rule.subjectId)
        rule.subjectName = `${customer.customer.companyName} (${translate('discount.label.customer')})`
      } else if (rule.subjectType === DiscountRule.SUBJECT_TYPE.CUSTOMER_GROUP) {
        const customerGroup = yield call(customerGroupService.readCustomerGroup, rule.subjectId)
        rule.subjectName = `${customerGroup.customerGroup.name} (${translate('discount.label.customerGroup')})`
      }

      if (rule.objectType === DiscountRule.OBJECT_TYPE.ALL) {
        rule.objectName = `${translate('common.label.all')} (${translate('discount.label.product')})`
      } else if (rule.objectType === DiscountRule.OBJECT_TYPE.PRODUCT) {
        const product = yield call(productService.readProduct, rule.objectId)
        rule.objectName = `${product.product.code} (${translate('discount.label.product')})`
      } else if (rule.objectType === DiscountRule.OBJECT_TYPE.CATEGORY) {
        const category = yield call(categoryService.readCategory, rule.objectId)
        rule.objectName = `${category.category.name} (${translate('discount.label.category')})`
      }
    }

    yield put({
      type: 'discount/SET_STATE',
      payload: {
        list: DiscountRuleList.fromData(data),
        detail: DiscountRule.empty()
      }
    })
  }

  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_DISCOUNT({ payload }) {
  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { ruleId } = payload
  const data = yield call(discountRuleService.readDiscountRule, ruleId)
  if (data) {
    yield put({
      type: 'discount/SET_STATE',
      payload: {
        detail: DiscountRule.fromData(data)
      }
    })
  }

  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_DISCOUNT({ payload }) {
  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(discountRuleService.createDiscountRule, CreateDiscountRuleReq.of(payload))
  if (data) {
    yield put({
      type: 'discount/ADD_STATE',
      payload: {
        rule: DiscountRule.fromData(data)
      }
    })
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_DISCOUNT({ payload }) {
  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(discountRuleService.updateDiscountRule, UpdateDiscountRuleReq.of(payload))
  if (success) {
    yield history.push('/catalog/discount/view')
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_DISCOUNT({ payload }) {
  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { ruleId } = payload
  const success = yield call(discountRuleService.deleteDiscountRule, ruleId)
  if (success) {
    yield put({
      type: 'discount/DELETE_STATE',
      payload: { ruleId }
    })
  }

  yield put({
    type: 'discount/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CLEAR_DISCOUNT() {
  yield put({
    type: 'discount/SET_STATE',
    payload: {
      list: DiscountRuleList.empty(),
      detail: DiscountRule.empty()
    }
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_DISCOUNT, LIST_DISCOUNT),
    takeEvery(actions.READ_DISCOUNT, READ_DISCOUNT),
    takeEvery(actions.CREATE_DISCOUNT, CREATE_DISCOUNT),
    takeEvery(actions.UPDATE_DISCOUNT, UPDATE_DISCOUNT),
    takeEvery(actions.DELETE_DISCOUNT, DELETE_DISCOUNT),
    takeEvery(actions.CLEAR_DISCOUNT, CLEAR_DISCOUNT),
  ])
}

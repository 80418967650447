import actions from 'redux/statistics/actions'
import DailyOrderStatistics from 'model/statistics/DailyOrderStatistics'
import DailyCartStatistics from 'model/statistics/DailyCartStatistics'

const initialState = {
  order: DailyOrderStatistics.empty(),
  cart: DailyCartStatistics.empty(),
  loading: false,
}

export default function statisticsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}

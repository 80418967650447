export default class CreateInventoryReq {
  constructor(regionId, productId, quantity, available) {
    this.regionId = regionId
    this.productId = productId
    this.quantity = quantity
    this.available = available
  }

  static of(data) {
    const { regionId, productId, quantity, available } = data
    return new CreateInventoryReq(regionId, productId, quantity, available)
  }
}

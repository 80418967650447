import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import { history } from 'index'
import * as toggleService from 'services/toggle'
import actions from 'redux/toggle/actions'
import Toggle from 'model/toggle/Toggle'
import ToggleList from 'model/toggle/ToggleList'
import CreateToggleReq from 'model/toggle/req/CreateToggleReq'
import UpdateToggleReq from 'model/toggle/req/UpdateToggleReq'

export function* LIST_TOGGLE() {
  yield put({
    type: 'toggle/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(toggleService.listToggle)
  if (data) {
    yield put({
      type: 'toggle/SET_STATE',
      payload: {
        list: ToggleList.fromData(data),
        detail: Toggle.empty(),
      },
    })
  }

  yield put({
    type: 'toggle/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_TOGGLE({ payload }) {
  yield put({
    type: 'toggle/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { key } = payload
  const data = yield call(toggleService.readToggle, key)
  if (data) {
    yield put({
      type: 'toggle/SET_STATE',
      payload: {
        detail: Toggle.fromData(data),
      },
    })
  }

  yield put({
    type: 'toggle/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_TOGGLE({ payload }) {
  yield put({
    type: 'toggle/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(toggleService.createToggle, CreateToggleReq.of(payload))
  if (data) {
    yield put({
      type: 'toggle/ADD_STATE',
      payload: {
        toggle: Toggle.fromData(data),
      },
    })
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'toggle/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_TOGGLE({ payload }) {
  yield put({
    type: 'toggle/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(toggleService.updateToggle, UpdateToggleReq.of(payload))
  if (success) {
    yield history.push('/system/toggle/view')
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'toggle/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_TOGGLE({ payload }) {
  yield put({
    type: 'toggle/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { key } = payload
  const success = yield call(toggleService.deleteToggle, key)
  if (success) {
    yield put({
      type: 'toggle/DELETE_STATE',
      payload: { key },
    })
  }

  yield put({
    type: 'toggle/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_TOGGLE, LIST_TOGGLE),
    takeEvery(actions.READ_TOGGLE, READ_TOGGLE),
    takeEvery(actions.CREATE_TOGGLE, CREATE_TOGGLE),
    takeEvery(actions.UPDATE_TOGGLE, UPDATE_TOGGLE),
    takeEvery(actions.DELETE_TOGGLE, DELETE_TOGGLE),
  ])
}

export default class InventoryRegion {
  constructor(id, name, defaultt) {
    this.id = id
    this.name = name
    this.defaultt = defaultt
  }

  static empty() {
    return new InventoryRegion(0, '', false)
  }

  static fromData(data) {
    return this.from(data.region)
  }

  static from(data) {
    const { id, name, defaultt } = data
    return new InventoryRegion(id, name, defaultt)
  }
}

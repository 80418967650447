export default class Cache {
  constructor(id, cacheName, objectCount) {
    this.id = id
    this.cacheName = cacheName
    this.objectCount = objectCount
  }

  static from(data) {
    const { cacheName, objectCount } = data
    return new Cache(cacheName, cacheName, objectCount)
  }
}

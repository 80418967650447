export default class Toggle {
  constructor(key, value) {
    this.key = key
    this.value = value
  }

  static empty() {
    return new Toggle('', false)
  }

  static fromData(data) {
    return this.from(data.toggle)
  }

  static from(data) {
    const { key, value } = data
    return new Toggle(key, value)
  }
}

export default class OrderCharge {
  constructor(subTotal, total, curr) {
    this.subTotal = subTotal
    this.total = total
    this.curr = curr
  }

  static empty() {
    return new OrderCharge(0, 0, '')
  }

  static from(data) {
    const { subTotal, total, curr } = data
    return new OrderCharge(subTotal, total, curr)
  }
}

import FaultContext from 'model/fault/FaultContext'
import FaultBody from 'model/fault/FaultBody'

export default class FaultData {
  constructor(context, body, args, dateTime, prettyDateTime, isUncaught, isSystem) {
    this.context = context
    this.body = body
    this.args = args
    this.dateTime = dateTime
    this.prettyDateTime = prettyDateTime
    this.isUncaught = isUncaught
    this.isSystem = isSystem
  }

  static empty() {
    return new FaultData(FaultContext.empty(), FaultBody.empty(), [], "", "", false, false)
  }

  static fromData(data) {
    return this.from(data.fault)
  }

  static from(data) {
    const { context, body, args, dateTime, prettyDateTime, isUncaught, isSystem } = data
    return new FaultData(FaultContext.from(context), FaultBody.from(body), args, dateTime, prettyDateTime, isUncaught, isSystem)
  }

  prettyString = () => {
    return this.body.prettyString()
  }
}

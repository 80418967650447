export default class CategoryGroup {
  constructor(id, name, prio, categoryIdList) {
    this.id = id
    this.name = name
    this.prio = prio
    this.categoryIdList = categoryIdList
  }

  static empty() {
    return new CategoryGroup(0, '', 0, [])
  }

  static fromData(data) {
    return this.from(data.group)
  }

  static from(data) {
    const { id, name, prio, categoryIdList } = data

    return new CategoryGroup(id, name, prio, categoryIdList)
  }
}

import News from 'model/news/News'

export default class NewsList {
  constructor(newsList, totalCount) {
    this.newsList = newsList
    this.totalCount = totalCount
  }

  add = (news) => {
    this.newsList.push(news)
    this.totalCount += 1

    return new NewsList(this.newsList, this.totalCount)
  }

  remove = (newsId) => {
    this.newsList = this.newsList.filter(c => c.id !== newsId)
    this.totalCount -= 1

    return new NewsList(this.newsList, this.totalCount)
  }

  static empty() {
    return new NewsList([], 0)
  }

  static fromData(data) {
    return new NewsList(
      data.newsList.map(d => News.from(d)),
      data.totalCount
    )
  }
}

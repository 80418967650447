export default class UpdateRegionReq {
  constructor(regionId, name, defaultt) {
    this.regionId = regionId
    this.name = name
    this.defaultt = defaultt
  }

  static of(data) {
    const { regionId, name, defaultt } = data
    return new UpdateRegionReq(regionId, name, defaultt)
  }
}

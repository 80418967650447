const actions = {
  LIST_CUSTOMER_GROUP: 'customer-group/LIST_CUSTOMER_GROUP',
  SEARCH_CUSTOMER_GROUP: 'customer-group/SEARCH_CUSTOMER_GROUP',
  READ_CUSTOMER_GROUP: 'customer-group/READ_CUSTOMER_GROUP',
  EXPORT_CUSTOMER_GROUP: 'customer-group/EXPORT_CUSTOMER_GROUP',
  CREATE_CUSTOMER_GROUP: 'customer-group/CREATE_CUSTOMER_GROUP',
  UPDATE_CUSTOMER_GROUP: 'customer-group/UPDATE_CUSTOMER_GROUP',
  DELETE_CUSTOMER_GROUP: 'customer-group/DELETE_CUSTOMER_GROUP',
  SET_STATE: 'customer-group/SET_STATE',
  ADD_STATE: 'customer-group/ADD_STATE',
  DELETE_STATE: 'customer-group/DELETE_STATE',
}

export default actions

export default class ProductBrand {
  constructor(brandId, brandName, prio) {
    this.brandId = brandId
    this.brandName = brandName
    this.prio = prio
  }

  static empty() {
    return new ProductBrand(0, "", 0)
  }

  static from(data) {
    const { brandId, brandName, prio } = data
    return new ProductBrand(brandId, brandName, prio)
  }
}

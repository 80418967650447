import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'

export async function listContactForm(req) {
  return apiClient
    .get(urls.ecom.contactform.list, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function searchContactForm(req) {
  return apiClient
    .get(urls.ecom.contactform.search, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readContactForm(id) {
  const url = format(urls.ecom.contactform.read, [id])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function completeContactForm(id) {
  const url = format(urls.ecom.contactform.complete, [id])

  return apiClient
    .put(url)
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function deleteContactForm(id) {
  const url = format(urls.ecom.contactform.delete, [id])

  return apiClient
    .delete(url)
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export default class UpdateAboutReq {
  constructor(aboutId, companyName, address, phoneNo, faxNo, email, note, socialAccounts, googleMap, defaultt) {
    this.aboutId = aboutId
    this.companyName = companyName
    this.address = address
    this.phoneNo = phoneNo
    this.faxNo = faxNo
    this.email = email
    this.note = note
    this.socialAccounts = socialAccounts
    this.googleMap = googleMap
    this.defaultt = defaultt
  }

  static of(data) {
    const { aboutId, companyName, address, phoneNo, faxNo, email, note, socialAccounts, googleMap, defaultt } = data
    return new UpdateAboutReq(aboutId, companyName, address, phoneNo, faxNo, email, note, socialAccounts, googleMap, defaultt)
  }
}

import User from 'model/user/User'

export default class UserList {
  constructor(users, totalCount) {
    this.users = users
    this.totalCount = totalCount
  }

  add = (user) => {
    this.users.push(user)
    this.totalCount += 1

    return new UserList(this.users, this.totalCount)
  }

  remove = (username) => {
    this.users = this.users.filter(u => u.username !== username)
    this.totalCount -= 1

    return new UserList(this.users, this.totalCount)
  }

  static empty() {
    return new UserList([], 0)
  }

  static fromData(data) {
    return new UserList(
      data.userList.map(d => User.from(d)),
      data.totalCount
    )
  }
}

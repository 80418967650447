export default class ListAuditReq {
  constructor(page, size, correlationId, begin, end, username, platform, appVersion, clientVersion, failed) {
    this.page = page
    this.size = size
    this.correlationId = correlationId
    this.begin = begin
    this.end = end
    this.username = username
    this.platform = platform
    this.appVersion = appVersion
    this.clientVersion = clientVersion
    this.failed = failed
  }

  static of(data) {
    const { page, size, correlationId, begin, end, username, platform, appVersion, clientVersion, failed } = data
    return new ListAuditReq(page, size, correlationId, begin, end, username, platform, appVersion, clientVersion, failed)
  }
}

export default class ProductCategory {
  constructor(categoryId, categoryName, prio) {
    this.categoryId = categoryId
    this.categoryName = categoryName
    this.prio = prio
  }

  static empty() {
    return new ProductCategory(0, "", 0)
  }

  static from(data) {
    const { categoryId, categoryName, prio } = data
    return new ProductCategory(categoryId, categoryName, prio)
  }
}

import Toggle from 'model/toggle/Toggle'

export default class ToggleList {
  constructor(toggleList) {
    this.toggleList = toggleList
    this.totalCount = toggleList.length
  }

  add = toggle => {
    this.toggleList.push(toggle)
    this.totalCount += 1

    return new ToggleList(this.toggleList, this.totalCount)
  }

  remove = toggleKey => {
    this.toggleList = this.toggleList.filter(c => c.key !== toggleKey)
    this.totalCount -= 1

    return new ToggleList(this.toggleList, this.totalCount)
  }

  static empty() {
    return new ToggleList([])
  }

  static fromData(data) {
    return new ToggleList(
      data.toggleList.map(d => Toggle.from(d)),
      data.toggleList.length,
    )
  }
}

import FaultData from 'model/fault/FaultData'

export default class FaultDataList {
  constructor(faultList, totalCount) {
    this.faultList = faultList
    this.totalCount = totalCount
  }

  static empty() {
    return new FaultDataList([], 0)
  }

  static fromData(data) {
    return new FaultDataList(
      data.faultList.map(d => FaultData.from(d)),
      data.faultList.totalCount
    )
  }

  getByCorrelationId = (correlationId) => {
    const found = this.faultList.filter(a => a.context.correlationId === correlationId)
    if (found.length > 0) {
      return found[0]
    }
    return null
  }
}

import localeAntd from 'antd/es/locale/en_US'

const messages = {
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}

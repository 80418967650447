export default class DefectiveRecord {
  constructor(id, process, count) {
    this.id = id
    this.process = process
    this.count = count
  }

  static empty() {
    return new DefectiveRecord(0, "", 0)
  }

  static from(data) {
    const { id, process, count } = data
    return new DefectiveRecord(id, process, count)
  }
}

export default class CartUserSettings {
  constructor(username, cartAllowed) {
    this.username = username
    this.cartAllowed = cartAllowed
  }

  static empty() {
    return new CartUserSettings("", false)
  }

  static fromData(data) {
    return this.from(data.settings)
  }

  static from(object) {
    const { username, cartAllowed } = object
    return new CartUserSettings(username, cartAllowed)
  }
}

export default class CurrencyMapper {
  static mapToSymbol(curr) {
    const currencySymbols = {
      'TRY': '₺',
      'USD': '$',
      'EUR': '€',
      'CRC': '₡',
      'GBP': '£',
      'ILS': '₪',
      'INR': '₹',
      'JPY': '¥',
      'KRW': '₩',
      'NGN': '₦',
      'PHP': '₱',
      'PLN': 'zł',
      'PYG': '₲',
      'THB': '฿',
      'UAH': '₴',
      'VND': '₫',
    }

    if (curr === "UNKNOWN") {
      return ""
    }

    return currencySymbols[curr] ? currencySymbols[curr] : curr
  }
}

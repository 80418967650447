import { all, call, put, takeEvery } from 'redux-saga/effects'

import * as faultService from 'services/fault'
import actions from 'redux/fault/actions'
import FaultData from 'model/fault/FaultData'
import FaultDataList from 'model/fault/FaultDataList'
import ListFaultReq from 'model/fault/req/ListFaultReq'

export function* LIST_FAULT({ payload }) {
  yield put({
    type: 'fault/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(faultService.listFault, ListFaultReq.of(payload))
  if (data) {
    yield put({
      type: 'fault/SET_STATE',
      payload: {
        list: FaultDataList.fromData(data),
        detail: FaultData.empty()
      }
    })
  }

  yield put({
    type: 'fault/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_FAULT({ payload }) {
  yield put({
    type: 'fault/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { correlationId } = payload
  const data = yield call(faultService.readFault, correlationId)
  if (data) {
    yield put({
      type: 'fault/SET_STATE',
      payload: {
        detail: FaultData.fromData(data)
      }
    })
  }

  yield put({
    type: 'fault/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_FAULT, LIST_FAULT),
    takeEvery(actions.READ_FAULT, READ_FAULT),
  ])
}

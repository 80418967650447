import actions from 'redux/brand/actions'
import Brand from 'model/product/Brand'
import BrandList from 'model/product/BrandList'

const initialState = {
  list: BrandList.empty(),
  detail: Brand.empty(),
  loading: false
}

export default function brandReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.brand)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.brandId)
      return state
    }
    default:
      return state
  }
}

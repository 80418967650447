export default class Tenant {
  constructor(name, currency, locale) {
    this.name = name
    this.currency = currency
    this.locale = locale
  }

  static empty() {
    return new Tenant('', '', 'tr-TR')
  }

  static fromData(data) {
    return this.from(data.tenant)
  }

  static from(data) {
    const { name, currency, locale } = data
    return new Tenant(name, currency, locale)
  }
}

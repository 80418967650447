export default class UpdateCategoryGroupReq {
  constructor(groupId, name, prio, categoryIdList) {
    this.groupId = groupId
    this.name = name
    this.prio = prio
    this.categoryIdList = categoryIdList
  }

  static of(data) {
    const { groupId, name, prio, categoryIdList } = data
    return new UpdateCategoryGroupReq(groupId, name, prio, categoryIdList)
  }
}

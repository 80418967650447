import actions from 'redux/about/actions'
import About from 'model/about/About'
import AboutList from 'model/about/AboutList'

const initialState = {
  list: AboutList.empty(),
  detail: About.empty(),
  loading: false
}

export default function aboutReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.about)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.aboutId)
      return state
    }
    default:
      return state
  }
}

export default class ListUserReq {
  constructor(page,
              size,
              statusList,
              roleName) {
    this.page = page
    this.size = size
    this.statusList = statusList
    this.roleName = roleName
  }

  static of(data) {
    const { page, size, statusList, roleName } = data
    return new ListUserReq(page, size, statusList, roleName)
  }
}

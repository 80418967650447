export default class Town {
  constructor(name) {
    this.name = name
  }

  static from(object) {
    const { name } = object
    return new Town(name)
  }
}

export default class SaveCartUserSettingsReq {
  constructor(username, cartAllowed) {
    this.username = username
    this.cartAllowed = cartAllowed
  }

  static of(data) {
    const { username, cartAllowed } = data

    return new SaveCartUserSettingsReq(
      username,
      cartAllowed || false)
  }
}

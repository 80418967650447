import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'

export async function listAbout() {
  return apiClient
    .get(urls.about.list)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readAbout(aboutId) {
  const url = format(urls.about.read, [aboutId])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function createAbout(req) {
  return apiClient
    .post(urls.about.create, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function updateAbout(req) {
  return apiClient
    .put(urls.about.update, req)
    .then((response) => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function deleteAbout(aboutId) {
  const url = format(urls.about.delete, [aboutId])

  return apiClient
    .delete(url)
    .then((response) => {
      return !!response
    })
    .catch(err => console.log(err))
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import { history } from 'index'
import * as orderService from 'services/order'
import actions from 'redux/order/actions'
import Order from 'model/order/Order'
import OrderList from 'model/order/OrderList'
import CreateDirectOrderReq from 'model/order/req/CreateDirectOrderReq'
import ListOrderReq from 'model/order/req/ListOrderReq'

export function* LIST_ORDER({ payload }) {
  yield put({
    type: 'order/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(orderService.listOrder, ListOrderReq.of(payload))
  if (data) {
    yield put({
      type: 'order/SET_STATE',
      payload: {
        list: OrderList.fromData(data),
        detail: Order.empty(),
      },
    })
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_ORDER({ payload }) {
  yield put({
    type: 'order/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { orderCode } = payload
  const data = yield call(orderService.readOrder, orderCode)
  if (data) {
    yield put({
      type: 'order/SET_STATE',
      payload: {
        detail: Order.fromData(data),
      },
    })
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_ORDER({ payload }) {
  yield put({
    type: 'order/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(orderService.createDirectOrder, CreateDirectOrderReq.of(payload))
  if (data) {
    yield put({
      type: 'order/ADD_STATE',
      payload: {
        order: Order.fromData(data),
      },
    })
    yield history.push('/order/view')
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELIVER_ORDER({ payload }) {
  yield put({
    type: 'order/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { orderCode } = payload
  const success = yield call(orderService.deliverOrder, orderCode)
  if (success) {
    yield history.push('/order/view')
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CANCEL_ORDER({ payload }) {
  yield put({
    type: 'order/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { orderCode } = payload
  const success = yield call(orderService.cancelOrder, orderCode)
  if (success) {
    yield history.push('/order/view')
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_ORDER({ payload }) {
  yield put({
    type: 'order/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { orderCode } = payload
  const success = yield call(orderService.deleteOrder, orderCode)
  if (success) {
    yield put({
      type: 'order/DELETE_STATE',
      payload: { orderCode },
    })
  }

  yield put({
    type: 'order/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_ORDER, LIST_ORDER),
    takeEvery(actions.READ_ORDER, READ_ORDER),
    takeEvery(actions.CREATE_ORDER, CREATE_ORDER),
    takeEvery(actions.DELIVER_ORDER, DELIVER_ORDER),
    takeEvery(actions.CANCEL_ORDER, CANCEL_ORDER),
    takeEvery(actions.DELETE_ORDER, DELETE_ORDER),
  ])
}

import CustomerGroup from 'model/customer/CustomerGroup'

export default class CustomerGroupList {
  constructor(groupList, totalCount) {
    this.groupList = groupList
    this.totalCount = totalCount
  }

  static empty() {
    return new CustomerGroupList([], 0)
  }

  static fromData(data) {
    return new CustomerGroupList(
      data.customerGroupList.map(d => CustomerGroup.from(d)),
      data.totalCount
    )
  }

  getById = (id) => {
    const found = this.groupList.filter(g => g.id === parseInt(id, 10))
    if (found.length > 0) {
      return found[0]
    }
    return null
  }

  getByName = (name) => {
    const found = this.groupList.filter(g => g.name === name)
    if (found.length > 0) {
      return found[0]
    }
    return null
  }

  getNameById = (id) => {
    const found = this.groupList.filter(g => g.id === parseInt(id, 10))
    if (found.length > 0) {
      return found[0].name
    }
    return ""
  }

  add = (group) => {
    this.groupList.push(group)
    this.totalCount += 1

    return new CustomerGroupList(this.groupList, this.totalCount)
  }

  remove = (groupId) => {
    this.groupList = this.groupList.filter(g => g.id !== groupId)
    this.totalCount -= 1

    return new CustomerGroupList(this.groupList, this.totalCount)
  }
}

import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'

export async function listDelivery(req) {
  return apiClient
    .get(urls.order.delivery.list, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readDelivery(deliveryId) {
  const url = format(urls.order.delivery.read, [deliveryId])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function searchDelivery(req) {
  return apiClient
    .get(urls.order.delivery.search, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function createDelivery(req) {
  return apiClient
    .post(urls.order.delivery.create, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function updateDelivery(req) {
  return apiClient
    .put(urls.order.delivery.update, req)
    .then((response) => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function deleteDelivery(deliveryId) {
  const url = format(urls.order.delivery.delete, [deliveryId])

  return apiClient
    .delete(url)
    .then((response) => {
      return !!response
    })
    .catch(err => console.log(err))
}

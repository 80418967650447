import actions from 'redux/contactform/actions'
import ContactForm from 'model/ecom/contactform/ContactForm'
import ContactFormList from 'model/ecom/contactform/ContactFormList'

const initialState = {
  list: ContactFormList.empty(),
  detail: ContactForm.empty(),
  loading: false,
}

export default function contactFormReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.UPDATE_STATE: {
      const { updated } = action.payload
      state.detail = Object.assign(state.detail, updated)
      state.list = state.list.updateItem(action.payload.updated.id, updated)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.contactFormId)
      return state
    }
    default:
      return state
  }
}

import actions from 'redux/customer/actions'
import Customer from 'model/customer/Customer'
import CustomerList from 'model/customer/CustomerList'

const initialState = {
  list: CustomerList.empty(),
  detail: Customer.empty(),
  loading: false
}

export default function customerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.customer)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.customerId)
      return state
    }
    default:
      return state
  }
}

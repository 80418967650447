import BestSellerFeature from 'model/product/BestSellerFeature'
import VitrineFeature from 'model/product/VitrineFeature'
import RecentFeature from 'model/product/RecentFeature'
import FutureFeature from 'model/product/FutureFeature'
import SoldOutFeature from 'model/product/SoldOutFeature'
import CampaignFeature from 'model/product/CampaignFeature'

export default class ProductFeatures {
  constructor(bestSellerFeature,
              vitrineFeature,
              recentFeature,
              futureFeature,
              soldOutFeature,
              campaignFeature) {
    this.bestSellerFeature = bestSellerFeature
    this.vitrineFeature = vitrineFeature
    this.recentFeature = recentFeature
    this.futureFeature = futureFeature
    this.soldOutFeature = soldOutFeature
    this.campaignFeature = campaignFeature
  }

  static empty() {
    return new ProductFeatures(null, null, null, null, null, null)
  }

  static from(data) {
    const { bestSellerFeature, vitrineFeature, recentFeature, futureFeature, soldOutFeature, campaignFeature } = data
    return new ProductFeatures(
      bestSellerFeature ? BestSellerFeature.from(bestSellerFeature) : null,
      vitrineFeature ? VitrineFeature.from(vitrineFeature) : null,
      recentFeature ? RecentFeature.from(recentFeature) : null,
      futureFeature ? FutureFeature.from(futureFeature) : null,
      soldOutFeature ? SoldOutFeature.from(soldOutFeature) : null,
      campaignFeature ? CampaignFeature.from(campaignFeature) : null
    )
  }

  isBestSeller = () => {
    return this.bestSellerFeature !== null && this.bestSellerFeature !== undefined
  }

  isVitrine = () => {
    return this.vitrineFeature !== null && this.vitrineFeature !== undefined
  }

  isRecent = () => {
    return this.recentFeature !== null && this.recentFeature !== undefined
  }

  isFuture = () => {
    return this.futureFeature !== null && this.futureFeature !== undefined
  }

  isSoldOut = () => {
    return this.soldOutFeature !== null && this.soldOutFeature !== undefined
  }

  isCampaign = () => {
    return this.campaignFeature !== null && this.campaignFeature !== undefined
  }

  getBestSellerPrio = () => {
    return this.bestSellerFeature ? this.bestSellerFeature.prio : null
  }

  getVitrinePrio = () => {
    return this.vitrineFeature ? this.vitrineFeature.prio : null
  }

  getRecentPrio = () => {
    return this.recentFeature ? this.recentFeature.prio : null
  }

  getFuturePrio = () => {
    return this.futureFeature ? this.futureFeature.prio : null
  }

  getSoldOutPrio = () => {
    return this.soldOutFeature ? this.soldOutFeature.prio : null
  }

  getCampaignPrio = () => {
    return this.campaignFeature ? this.campaignFeature.prio : null
  }
}

export default class OrderCharge {
  constructor(deliveryType, deliveryName) {
    this.deliveryType = deliveryType
    this.deliveryName = deliveryName
  }

  static empty() {
    return new OrderCharge('', '')
  }

  static from(data) {
    const { deliveryType, deliveryName } = data
    return new OrderCharge(deliveryType, deliveryName)
  }
}

import About from 'model/about/About'

export default class AboutList {
  constructor(aboutList, totalCount) {
    this.aboutList = aboutList
    this.totalCount = totalCount
  }

  add = (news) => {
    this.aboutList.push(news)
    this.totalCount += 1

    return new AboutList(this.aboutList, this.totalCount)
  }

  remove = (newsId) => {
    this.aboutList = this.aboutList.filter(c => c.id !== newsId)
    this.totalCount -= 1

    return new AboutList(this.aboutList, this.totalCount)
  }

  static empty() {
    return new AboutList([], 0)
  }

  static fromData(data) {
    return new AboutList(
      data.aboutList.map(d => About.from(d)),
      data.aboutList.length
    )
  }
}

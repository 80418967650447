export default class ProductFlags {
  constructor(
    isHidden,
    hiddenReason,
    isOpenCompetition,
    isAllowAddToCart,
    isAvailable,
    isIgnoreInventory,
  ) {
    this.isHidden = isHidden
    this.hiddenReason = hiddenReason
    this.isOpenCompetition = isOpenCompetition
    this.isAllowAddToCart = isAllowAddToCart
    this.isAvailable = isAvailable
    this.isIgnoreInventory = isIgnoreInventory
  }

  static empty() {
    return new ProductFlags(false, '', false, false, false, false, false)
  }

  static from(data) {
    const {
      isHidden,
      hiddenReason,
      isOpenCompetition,
      isAllowAddToCart,
      isAvailable,
      isIgnoreInventory,
    } = data
    return new ProductFlags(
      isHidden,
      hiddenReason,
      isOpenCompetition,
      isAllowAddToCart,
      isAvailable,
      isIgnoreInventory,
    )
  }
}

import qs from 'qs'

import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'

export async function listAudit(req) {
  return apiClient
    .get(urls.audit.list, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readAudit(correlationId) {
  const url = format(urls.audit.read, [correlationId])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

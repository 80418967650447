import CategoryGroup from 'model/product/CategoryGroup'

export default class CategoryGroupList {
  constructor(groupList, totalCount) {
    this.groupList = groupList
    this.totalCount = totalCount
  }

  static empty() {
    return new CategoryGroupList([], 0)
  }

  static fromData(data) {
    return new CategoryGroupList(
      data.groupList.map(d => CategoryGroup.from(d)),
      data.groupList.length,
    )
  }

  getById = id => {
    const found = this.groupList.filter(g => g.id === parseInt(id, 10))
    if (found.length > 0) {
      return found[0]
    }
    return null
  }

  getByName = name => {
    const found = this.groupList.filter(g => g.name === name)
    if (found.length > 0) {
      return found[0]
    }
    return null
  }

  getByNameOrId = value => {
    return this.getById(value) || this.getByName(value)
  }

  getNameById = id => {
    const found = this.groupList.filter(g => g.id === parseInt(id, 10))
    if (found.length > 0) {
      return found[0].name
    }
    return ''
  }

  search = text => {
    return this.groupList.filter(g => g.name.toLowerCase().indexOf(text.toLowerCase()) > -1)
  }

  add = group => {
    this.groupList.push(group)
    this.totalCount += 1

    return new CategoryGroupList(this.groupList, this.totalCount)
  }

  remove = groupId => {
    this.groupList = this.groupList.filter(g => g.id !== groupId)
    this.totalCount -= 1

    return new CategoryGroupList(this.groupList, this.totalCount)
  }
}

export default class Audit {
  constructor(correlationId, dateTime, prettyDateTime, type, message, reqBody, resBody, isFailed, failedReason, username,
              platform, client, method, url, lang, appVersion, clientVersion) {
    this.correlationId = correlationId
    this.dateTime = dateTime
    this.prettyDateTime = prettyDateTime
    this.type = type
    this.message = message
    this.reqBody = reqBody
    this.resBody = resBody
    this.isFailed = isFailed
    this.failedReason = failedReason
    this.username = username
    this.platform = platform
    this.client = client
    this.method = method
    this.url = url
    this.lang = lang
    this.appVersion = appVersion
    this.clientVersion = clientVersion
  }

  static empty() {
    return new Audit("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "")
  }

  static fromData(data) {
    return this.from(data.audit)
  }

  static from(data) {
    const {
      correlationId, dateTime, prettyDateTime, type, message, reqBody, resBody, isFailed, failedReason, username, platform, client,
      method, url, lang, appVersion, clientVersion
    } = data
    return new Audit(correlationId, dateTime, prettyDateTime, type, message, reqBody, resBody, isFailed, failedReason, username,
      platform, client, method, url, lang, appVersion, clientVersion)
  }
}

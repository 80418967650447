import actions from 'redux/customer-group/actions'
import CustomerGroup from 'model/customer/CustomerGroup'
import CustomerGroupList from 'model/customer/CustomerGroupList'

const initialState = {
  list: CustomerGroupList.empty(),
  detail: CustomerGroup.empty(),
  loading: false
}

export default function customerGroupReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.customerGroup)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.groupId)
      return state
    }
    default:
      return state
  }
}

import SocialAccount from 'model/about/SocialAccount'

export default class About {
  constructor(id, companyName, address, phoneNo, faxNo, email, note, socialAccounts, googleMap, defaultt) {
    this.id = id
    this.companyName = companyName
    this.address = address
    this.phoneNo = phoneNo
    this.faxNo = faxNo
    this.email = email
    this.note = note
    this.socialAccounts = socialAccounts
    this.googleMap = googleMap
    this.defaultt = defaultt
  }

  static empty() {
    return new About(0, "", "", "", "", "", "", [], "", false)
  }

  static fromData(data) {
    return this.from(data.about)
  }

  static from(data) {
    const { id, companyName, address, phoneNo, faxNo, email, note, socialAccounts, googleMap, defaultt } = data
    return new About(id,
      companyName,
      address,
      phoneNo,
      faxNo,
      email,
      note,
      (socialAccounts || []).map(account => SocialAccount.from(account)) ,
      googleMap,
      defaultt)
  }
}

const actions = {
  LIST_CONTACTFORM: 'contactform/LIST_CONTACTFORM',
  SEARCH_CONTACTFORM: 'contactform/SEARCH_CONTACTFORM',
  READ_CONTACTFORM: 'contactform/READ_CONTACTFORM',
  COMPLETE_CONTACTFORM: 'contactform/COMPLETE_CONTACTFORM',
  DELETE_CONTACTFORM: 'contactform/DELETE_CONTACTFORM',
  SET_STATE: 'contactform/SET_STATE',
  UPDATE_STATE: 'contactform/UPDATE_STATE',
  DELETE_STATE: 'contactform/DELETE_STATE',
}

export default actions

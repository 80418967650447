export default class SaveProductUserSettingsReq {
  constructor(
    username,
    hidePrice,
    hideAvailability,
    hideDiscount,
    hideOpenCompetition,
    hideCampaign,
    hideBestSeller,
    hideFuture,
    hideRecent,
    hideVitrine,
    hideLedSearch,
    filterIfStockOut,
    filterIfOpenCompetition,
    allowMarketplaceExport,
  ) {
    this.username = username
    this.hidePrice = hidePrice
    this.hideAvailability = hideAvailability
    this.hideDiscount = hideDiscount
    this.hideOpenCompetition = hideOpenCompetition
    this.hideCampaign = hideCampaign
    this.hideBestSeller = hideBestSeller
    this.hideFuture = hideFuture
    this.hideRecent = hideRecent
    this.hideVitrine = hideVitrine
    this.hideLedSearch = hideLedSearch
    this.filterIfStockOut = filterIfStockOut
    this.filterIfOpenCompetition = filterIfOpenCompetition
    this.allowMarketplaceExport = allowMarketplaceExport
  }

  static of(data) {
    const {
      username,
      hidePrice,
      hideAvailability,
      hideDiscount,
      hideOpenCompetition,
      hideCampaign,
      hideFuture,
      hideRecent,
      hideLedSearch,
      filterIfStockOut,
      filterIfOpenCompetition,
      allowMarketplaceExport,
    } = data

    return new SaveProductUserSettingsReq(
      username,
      hidePrice || false,
      hideAvailability || false,
      hideDiscount || false,
      hideOpenCompetition || false,
      hideCampaign || false,
      false,
      hideFuture || false,
      hideRecent || false,
      false,
      hideLedSearch || false,
      filterIfStockOut || false,
      filterIfOpenCompetition || false,
      allowMarketplaceExport || false,
    )
  }
}

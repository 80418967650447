export default class UpdateDefectiveClaimReq {
  constructor(claimId, itemCount, arrivalNote, note, arrival) {
    this.claimId = claimId
    this.itemCount = itemCount
    this.arrivalNote = arrivalNote
    this.note = note
    this.arrival = arrival
  }

  static of(data) {
    const { claimId, itemCount, arrivalNote, note, arrival } = data
    return new UpdateDefectiveClaimReq(claimId, itemCount, arrivalNote, note, arrival)
  }
}

const actions = {
  LIST_CATEGORY_GROUP: 'category-group/LIST_CATEGORY_GROUP',
  READ_CATEGORY_GROUP: 'category-group/READ_CATEGORY_GROUP',
  CREATE_CATEGORY_GROUP: 'category-group/CREATE_CATEGORY_GROUP',
  UPDATE_CATEGORY_GROUP: 'category-group/UPDATE_CATEGORY_GROUP',
  DELETE_CATEGORY_GROUP: 'category-group/DELETE_CATEGORY_GROUP',
  SET_STATE: 'category-group/SET_STATE',
  ADD_STATE: 'category-group/ADD_STATE',
  DELETE_STATE: 'category-group/DELETE_STATE',
}

export default actions

const actions = {
  LIST_DELIVERY: 'delivery/LIST_DELIVERY',
  READ_DELIVERY: 'delivery/READ_DELIVERY',
  SEARCH_DELIVERY: 'delivery/SEARCH_DELIVERY',
  CREATE_DELIVERY: 'delivery/CREATE_DELIVERY',
  UPDATE_DELIVERY: 'delivery/UPDATE_DELIVERY',
  DELETE_DELIVERY: 'delivery/DELETE_DELIVERY',
  SET_STATE: 'delivery/SET_STATE',
  ADD_STATE: 'delivery/ADD_STATE',
  DELETE_STATE: 'delivery/DELETE_STATE',
}

export default actions

import qs from 'qs'

import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'

import { format } from 'helpers/string'
import { toFormData } from 'helpers/form'

export async function listLed(req) {
  return apiClient
    .get(urls.product.led.list, {
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readLed(ledId) {
  const url = format(urls.product.led.read, [ledId])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function searchLed(req) {
  return apiClient
    .get(urls.product.led.search, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function createLed(req) {
  return apiClient
    .post(urls.product.led.create, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function updateLed(req) {
  return apiClient
    .put(urls.product.led.update, req)
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function deleteLed(ledId) {
  const url = format(urls.product.led.delete, [ledId])

  return apiClient
    .delete(url)
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function loadLed(req) {
  const formData = toFormData(req)

  return apiClient
    .post(urls.product.led.load, formData, {
      timeout: 60000,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function initPageData() {
  return apiClient
    .get(urls.product.led.initialPage)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

import ContactForm from 'model/ecom/contactform/ContactForm'

export default class ContactFormList {
  constructor(contactFormList, totalCount) {
    this.contactFormList = contactFormList
    this.totalCount = totalCount
  }

  add = contactForm => {
    this.contactFormList.push(contactForm)
    this.totalCount += 1

    return new ContactFormList(this.contactFormList, this.totalCount)
  }

  remove = contractFormId => {
    this.contactFormList = this.contactFormList.filter(c => c.id !== contractFormId)
    this.totalCount -= 1

    return new ContactFormList(this.contactFormList, this.totalCount)
  }

  updateItem = (contractFormId, item) => {
    for (let i = 0; i < this.contactFormList.length; i += 1) {
      if (this.contactFormList[i].id === contractFormId) {
        this.contactFormList[i] = Object.assign(this.contactFormList[i], item)
      }
    }

    return new ContactFormList(this.contactFormList, this.totalCount)
  }

  static empty() {
    return new ContactFormList([], 0)
  }

  static fromData(data) {
    return new ContactFormList(
      data.contactFormList.map(d => ContactForm.from(d)),
      data.totalCount,
    )
  }
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import * as cartService from 'services/cart'
import * as productService from 'services/product'
import * as categoryService from 'services/product/category'
import actions from 'redux/userSettings/actions'
import CartUserSettings from 'model/cart/CartUserSettings'
import ProductUserSettings from 'model/product/ProductUserSettings'
import CategoryUserSettings from 'model/product/CategoryUserSettings'
import SaveCartUserSettingsReq from 'model/cart/req/SaveCartUserSettingsReq'
import SaveProductUserSettingsReq from 'model/product/req/SaveProductUserSettingsReq'
import SaveCategoryUserSettingsReq from 'model/product/req/SaveCategoryUserSettingsReq'

export function* READ_CART_SETTINGS({ payload }) {
  yield put({
    type: 'userSettings/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { username } = payload
  const data = yield call(cartService.readCartUserSettings, username)
  if (data.settings) {
    yield put({
      type: 'userSettings/SET_STATE',
      payload: {
        cartSettings: CartUserSettings.fromData(data)
      }
    })
  }

  yield put({
    type: 'userSettings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_PRODUCT_SETTINGS({ payload }) {
  yield put({
    type: 'userSettings/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { username } = payload
  const data = yield call(productService.readProductUserSettings, username)
  if (data.settings) {
    yield put({
      type: 'userSettings/SET_STATE',
      payload: {
        productSettings: ProductUserSettings.fromData(data)
      }
    })
  }

  yield put({
    type: 'userSettings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_CATEGORY_SETTINGS({ payload }) {
  yield put({
    type: 'userSettings/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { username } = payload
  const data = yield call(categoryService.readCategoryUserSettings, username)
  if (data.settings) {
    yield put({
      type: 'userSettings/SET_STATE',
      payload: {
        categorySettings: CategoryUserSettings.fromData(data)
      }
    })
  }

  yield put({
    type: 'userSettings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SAVE_CART_SETTINGS({ payload }) {
  yield put({
    type: 'userSettings/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(cartService.saveCartUserSettings, SaveCartUserSettingsReq.of(payload))
  if (data) {
    yield put({
      type: 'userSettings/SET_STATE',
      payload: {
        cartSettings: CartUserSettings.fromData(data),
      },
    })

    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'userSettings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SAVE_PRODUCT_SETTINGS({ payload }) {
  yield put({
    type: 'userSettings/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(productService.saveProductUserSettings, SaveProductUserSettingsReq.of(payload))
  if (data) {
    yield put({
      type: 'userSettings/SET_STATE',
      payload: {
        productSettings: ProductUserSettings.fromData(data),
      },
    })

    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'userSettings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SAVE_CATEGORY_SETTINGS({ payload }) {
  yield put({
    type: 'userSettings/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(categoryService.saveCategoryUserSettings, SaveCategoryUserSettingsReq.of(payload))
  if (data) {
    yield put({
      type: 'userSettings/SET_STATE',
      payload: {
        categorySettings: CategoryUserSettings.fromData(data),
      },
    })

    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'userSettings/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.READ_CART_SETTINGS, READ_CART_SETTINGS),
    takeEvery(actions.SAVE_CART_SETTINGS, SAVE_CART_SETTINGS),
    takeEvery(actions.READ_PRODUCT_SETTINGS, READ_PRODUCT_SETTINGS),
    takeEvery(actions.SAVE_PRODUCT_SETTINGS, SAVE_PRODUCT_SETTINGS),
    takeEvery(actions.READ_CATEGORY_SETTINGS, READ_CATEGORY_SETTINGS),
    takeEvery(actions.SAVE_CATEGORY_SETTINGS, SAVE_CATEGORY_SETTINGS),
  ])
}

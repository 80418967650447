const actions = {
  LIST_TOGGLE: 'toggle/LIST_TOGGLE',
  READ_TOGGLE: 'toggle/READ_TOGGLE',
  CREATE_TOGGLE: 'toggle/CREATE_TOGGLE',
  UPDATE_TOGGLE: 'toggle/UPDATE_TOGGLE',
  DELETE_TOGGLE: 'toggle/DELETE_TOGGLE',
  SET_STATE: 'toggle/SET_STATE',
  ADD_STATE: 'toggle/ADD_STATE',
  DELETE_STATE: 'toggle/DELETE_STATE',
}

export default actions

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import { history } from 'index'
import * as newsService from 'services/news'
import actions from 'redux/news/actions'
import News from 'model/news/News'
import NewsList from 'model/news/NewsList'
import ListNewsReq from 'model/news/req/ListNewsReq'
import SearchNewsReq from 'model/news/req/SearchNewsReq'
import CreateNewsReq from 'model/news/req/CreateNewsReq'
import UpdateNewsReq from 'model/news/req/UpdateNewsReq'

export function* LIST_NEWS({ payload }) {
  yield put({
    type: 'news/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(newsService.listNews, ListNewsReq.of(payload))
  if (data) {
    yield put({
      type: 'news/SET_STATE',
      payload: {
        list: NewsList.fromData(data),
        detail: News.empty()
      }
    })
  }

  yield put({
    type: 'news/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_NEWS({ payload }) {
  yield put({
    type: 'news/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { newsId } = payload
  const data = yield call(newsService.readNews, newsId)
  if (data) {
    yield put({
      type: 'news/SET_STATE',
      payload: {
        detail: News.fromData(data)
      }
    })
  }

  yield put({
    type: 'news/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SEARCH_NEWS({ payload }) {
  yield put({
    type: 'news/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(newsService.searchNews, SearchNewsReq.of(payload))
  if (data) {
    yield put({
      type: 'news/SET_STATE',
      payload: {
        list: NewsList.fromData(data),
        detail: News.empty()
      }
    })
  }

  yield put({
    type: 'news/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_NEWS({ payload }) {
  yield put({
    type: 'news/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(newsService.createNews, CreateNewsReq.of(payload))
  if (data) {
    yield put({
      type: 'news/ADD_STATE',
      payload: {
        news: News.fromData(data)
      }
    })
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'news/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_NEWS({ payload }) {
  yield put({
    type: 'news/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(newsService.updateNews, UpdateNewsReq.of(payload))
  if (success) {
    yield history.push('/news/view')
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'news/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_NEWS({ payload }) {
  yield put({
    type: 'news/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { newsId } = payload
  const success = yield call(newsService.deleteNews, newsId)
  if (success) {
    yield put({
      type: 'news/DELETE_STATE',
      payload: { newsId }
    })
  }

  yield put({
    type: 'news/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_NEWS, LIST_NEWS),
    takeEvery(actions.READ_NEWS, READ_NEWS),
    takeEvery(actions.SEARCH_NEWS, SEARCH_NEWS),
    takeEvery(actions.CREATE_NEWS, CREATE_NEWS),
    takeEvery(actions.UPDATE_NEWS, UPDATE_NEWS),
    takeEvery(actions.DELETE_NEWS, DELETE_NEWS),
  ])
}

import FaultTrace from 'model/fault/FaultTrace'

export default class FaultTraceChain {
  constructor(traces) {
    this.traces = traces
  }

  static empty() {
    return new FaultTraceChain([])
  }

  static from(data) {
    const { traces } = data
    return new FaultTraceChain((traces || []).map(trace => FaultTrace.from(trace)))
  }
}

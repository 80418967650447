export default class CustomerGroup {
  constructor(id, name, description) {
    this.id = id
    this.name = name
    this.description = description
  }

  static REPORT_FORMAT = {
    XLS: "XLS"
  }

  static REPORT_FORMAT_LIST = [this.REPORT_FORMAT.XLS]

  static empty() {
    return new CustomerGroup(0, "", "")
  }

  static fromData(data) {
    return this.from(data.customerGroup)
  }

  static from(object) {
    const { id, name, description } = object

    return new CustomerGroup(id, name, description)
  }
}

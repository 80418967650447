export default class InventoryAdjustmentHistory {
  constructor(id, refNumber, reason, quantity) {
    this.id = id
    this.refNumber = refNumber
    this.reason = reason
    this.quantity = quantity
  }

  static empty() {
    return new InventoryAdjustmentHistory(0, '', '', 0)
  }

  static fromData(data) {
    return this.from(data.history)
  }

  static from(data) {
    const { id, refNumber, reason, quantity } = data
    return new InventoryAdjustmentHistory(id, refNumber, reason, quantity)
  }

  static getReasons() {
    return ['InventoryRevaluation']
  }
}

const actions = {
  LIST_NEWS: 'news/LIST_NEWS',
  READ_NEWS: 'news/READ_NEWS',
  SEARCH_NEWS: 'news/SEARCH_NEWS',
  CREATE_NEWS: 'news/CREATE_NEWS',
  UPDATE_NEWS: 'news/UPDATE_NEWS',
  DELETE_NEWS: 'news/DELETE_NEWS',
  SET_STATE: 'news/SET_STATE',
  ADD_STATE: 'news/ADD_STATE',
  DELETE_STATE: 'news/DELETE_STATE',
}

export default actions

export default class InventoryProduct {
  constructor(id, code, name, categoryName, brandName) {
    this.id = id
    this.code = code
    this.name = name
    this.categoryName = categoryName
    this.brandName = brandName
  }

  static empty() {
    return new InventoryProduct(0, '', '', '', '')
  }

  static from(data) {
    const { id, code, name, categoryName, brandName } = data
    return new InventoryProduct(id, code, name, categoryName, brandName)
  }
}

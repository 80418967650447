export default class UpdateCustomerGroupReq {
  constructor(groupId, name, description) {
    this.groupId = groupId
    this.name = name
    this.description = description
  }

  static of(data) {
    const { groupId, name, description } = data
    return new UpdateCustomerGroupReq(groupId, name, description)
  }
}

import Price from 'model/product/Price'

export default class FinalPrice {
  constructor(unitPrice, discountedPrice) {
    this.unitPrice = unitPrice
    this.discountedPrice = discountedPrice
  }

  static empty() {
    return new FinalPrice(Price.empty(), Price.empty())
  }

  static from(data) {
    const { unitPrice, discountedPrice } = data
    return new FinalPrice(Price.from(unitPrice), Price.from(discountedPrice))
  }
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import { downloadFile } from 'helpers/file'
import * as defectiveService from 'services/defective'
import actions from 'redux/defective/actions'
import DefectiveClaim from 'model/defective/DefectiveClaim'
import DefectiveClaimList from 'model/defective/DefectiveClaimList'
import ListDefectiveClaimReq from 'model/defective/req/ListDefectiveClaimReq'
import SearchDefectiveClaimReq from 'model/defective/req/SearchDefectiveClaimReq'
import ExportDefectiveClaimReq from 'model/defective/req/ExportDefectiveClaimReq'
import CreateDefectiveClaimReq from 'model/defective/req/CreateDefectiveClaimReq'
import UpdateDefectiveClaimReq from 'model/defective/req/UpdateDefectiveClaimReq'
import CompleteDefectiveClaimReq from 'model/defective/req/CompleteDefectiveClaimReq'
import AssociateRecordToDefectiveClaimReq from 'model/defective/req/AssociateRecordToDefectiveClaimReq'
import { history } from '../../index'

export function* LIST_DEFECTIVE({ payload }) {
  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(defectiveService.listDefectiveClaim, ListDefectiveClaimReq.of(payload))
  if (data) {
    yield put({
      type: 'defective/SET_STATE',
      payload: {
        list: DefectiveClaimList.fromData(data),
        detail: DefectiveClaim.empty(),
      },
    })
  }

  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SEARCH_DEFECTIVE({ payload }) {
  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(
    defectiveService.searchDefectiveClaim,
    SearchDefectiveClaimReq.of(payload),
  )
  if (data) {
    yield put({
      type: 'defective/SET_STATE',
      payload: {
        list: DefectiveClaimList.fromData(data),
        detail: DefectiveClaim.empty(),
      },
    })
  }

  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_DEFECTIVE({ payload }) {
  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { claimId } = payload
  const data = yield call(defectiveService.readDefectiveClaim, claimId)
  if (data) {
    yield put({
      type: 'defective/SET_STATE',
      payload: {
        detail: DefectiveClaim.fromData(data),
      },
    })
  }

  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* EXPORT_DEFECTIVE({ payload }) {
  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(
    defectiveService.exportDefectiveClaim,
    ExportDefectiveClaimReq.of(payload),
  )
  if (data) {
    downloadFile(data.fileType, data.base64, data.fileName)
  }

  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_DEFECTIVE({ payload }) {
  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(
    defectiveService.createDefectiveClaim,
    CreateDefectiveClaimReq.of(payload),
  )
  if (data) {
    yield put({
      type: 'defective/ADD_STATE',
      payload: {
        claim: DefectiveClaim.fromData(data),
      },
    })
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_DEFECTIVE({ payload }) {
  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(
    defectiveService.updateDefectiveClaim,
    UpdateDefectiveClaimReq.of(payload),
  )
  if (data) {
    yield put({
      type: 'defective/SET_STATE',
      payload: {
        detail: DefectiveClaim.fromData(data),
      },
    })
    yield history.push('/defective/view')
  }

  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* COMPLETE_DEFECTIVE({ payload }) {
  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { claimId } = payload
  const data = yield call(
    defectiveService.completeDefectiveClaim,
    claimId,
    CompleteDefectiveClaimReq.of(payload),
  )
  if (data) {
    yield put({
      type: 'defective/SET_STATE',
      payload: {
        detail: DefectiveClaim.fromData(data),
      },
    })

    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* ASSOCIATE_DEFECTIVE({ payload }) {
  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { claimId } = payload
  const data = yield call(
    defectiveService.associateRecordToDefectiveClaim,
    claimId,
    AssociateRecordToDefectiveClaimReq.of(payload),
  )
  if (data) {
    yield put({
      type: 'defective/SET_STATE',
      payload: {
        detail: DefectiveClaim.fromData(data),
      },
    })

    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_DEFECTIVE({ payload }) {
  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { claimId } = payload
  const data = yield call(defectiveService.deleteDefectiveClaim, claimId)
  if (data) {
    yield put({
      type: 'defective/DELETE_STATE',
      payload: { claimId },
    })
  }

  yield put({
    type: 'defective/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_DEFECTIVE, LIST_DEFECTIVE),
    takeEvery(actions.SEARCH_DEFECTIVE, SEARCH_DEFECTIVE),
    takeEvery(actions.EXPORT_DEFECTIVE, EXPORT_DEFECTIVE),
    takeEvery(actions.CREATE_DEFECTIVE, CREATE_DEFECTIVE),
    takeEvery(actions.UPDATE_DEFECTIVE, UPDATE_DEFECTIVE),
    takeEvery(actions.COMPLETE_DEFECTIVE, COMPLETE_DEFECTIVE),
    takeEvery(actions.ASSOCIATE_DEFECTIVE, ASSOCIATE_DEFECTIVE),
    takeEvery(actions.READ_DEFECTIVE, READ_DEFECTIVE),
    takeEvery(actions.DELETE_DEFECTIVE, DELETE_DEFECTIVE),
  ])
}

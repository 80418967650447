const actions = {
  LIST_BRAND: 'brand/LIST_BRAND',
  READ_BRAND: 'brand/READ_BRAND',
  SEARCH_BRAND: 'brand/SEARCH_BRAND',
  CREATE_BRAND: 'brand/CREATE_BRAND',
  UPDATE_BRAND: 'brand/UPDATE_BRAND',
  DELETE_BRAND: 'brand/DELETE_BRAND',
  SET_STATE: 'brand/SET_STATE',
  ADD_STATE: 'brand/ADD_STATE',
  DELETE_STATE: 'brand/DELETE_STATE',
}

export default actions

const actions = {
  LIST_BANNER: 'banner/LIST_BANNER',
  CREATE_BANNER: 'banner/CREATE_BANNER',
  UPDATE_BANNER: 'banner/UPDATE_BANNER',
  DELETE_BANNER: 'banner/DELETE_BANNER',
  SET_STATE: 'banner/SET_STATE',
  ADD_STATE: 'banner/ADD_STATE',
  DELETE_STATE: 'banner/DELETE_STATE',
}

export default actions

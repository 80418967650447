import actions from 'redux/category-group/actions'
import CategoryGroup from 'model/product/CategoryGroup'
import CategoryGroupList from 'model/product/CategoryGroupList'

const initialState = {
  list: CategoryGroupList.empty(),
  detail: CategoryGroup.empty(),
  loading: false,
}

export default function categoryGroupReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.categoryGroup)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.groupId)
      return state
    }
    default:
      return state
  }
}

export default class AdjustInventoryReq {
  constructor(inventoryId, adjustmentReason, quantity, available) {
    this.inventoryId = inventoryId
    this.adjustmentReason = adjustmentReason
    this.quantity = quantity
    this.available = available
  }

  static of(data) {
    const { inventoryId, adjustmentReason, quantity, available } = data
    return new AdjustInventoryReq(inventoryId, adjustmentReason, quantity, available)
  }
}

export default class OrderCustomer {
  constructor(
    customerId,
    customerCompanyName,
    customerEmail,
    customerCity,
    customerTown,
    customerDistrict,
    customerBranch,
    customerSalesmanNote,
  ) {
    this.customerId = customerId
    this.customerCompanyName = customerCompanyName
    this.customerEmail = customerEmail
    this.customerCity = customerCity
    this.customerTown = customerTown
    this.customerDistrict = customerDistrict
    this.customerBranch = customerBranch
    this.customerSalesmanNote = customerSalesmanNote
  }

  static empty() {
    return new OrderCustomer(0, '', '', '', '', '', '', 0)
  }

  static from(data) {
    const {
      customerId,
      customerCompanyName,
      customerEmail,
      customerCity,
      customerTown,
      customerDistrict,
      customerBranch,
      customerSalesmanNote,
    } = data
    return new OrderCustomer(
      customerId,
      customerCompanyName,
      customerEmail,
      customerCity,
      customerTown,
      customerDistrict,
      customerBranch,
      customerSalesmanNote,
    )
  }
}

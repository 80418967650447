export default class BoxAmountDiscount {
  constructor(amount, count) {
    this.amount = amount
    this.count = count
  }

  static empty() {
    return new BoxAmountDiscount(0, 0)
  }

  static from(data) {
    const { amount, count } = data
    return new BoxAmountDiscount(amount, count)
  }
}

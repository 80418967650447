export default class Role {
  constructor(id, name) {
    this.id = id
    this.name = name
  }

  static empty() {
    return new Role(0, "")
  }

  static fromData(data) {
    return this.from(data.role)
  }

  static from(object) {
    const { id, name, } = object
    return new Role(id, name)
  }

  static ROLE_SUPERADMIN = "SUPERADMIN"

  static ROLE_ADMIN = "ADMIN"

  static ROLE_SALESMAN = "SALESMAN"

  static ROLE_CUSTOMER = "CUSTOMER"

  static getRoles() {
    return [this.ROLE_SUPERADMIN, this.ROLE_ADMIN, this.ROLE_SALESMAN, this.ROLE_CUSTOMER]
  }
}

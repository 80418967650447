import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import { history } from 'index'
import * as ledService from 'services/product/led'
import actions from 'redux/led/actions'
import Led from 'model/product/Led'
import LedList from 'model/product/LedList'
import ListLedReq from 'model/product/req/ListLedReq'
import SearchLedReq from 'model/product/req/SearchLedReq'
import CreateLedReq from 'model/product/req/CreateLedReq'
import UpdateLedReq from 'model/product/req/UpdateLedReq'
import LoadLedReq from 'model/product/req/LoadLedReq'

export function* INIT_LED() {
  const data = yield call(ledService.initPageData)
  if (data) {
    yield put({
      type: 'led/SET_STATE',
      payload: {
        brandList: data.brandList,
        firmList: data.firmList
      }
    })
  }
}

export function* LIST_LED({ payload }) {
  yield put({
    type: 'led/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(ledService.listLed, ListLedReq.of(payload))
  if (data) {
    yield put({
      type: 'led/SET_STATE',
      payload: {
        list: LedList.fromData(data),
        detail: Led.empty()
      }
    })
  }

  yield put({
    type: 'led/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_LED({ payload }) {
  yield put({
    type: 'led/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { ledId } = payload
  const data = yield call(ledService.readLed, ledId)
  if (data) {
    yield put({
      type: 'led/SET_STATE',
      payload: {
        detail: Led.fromData(data)
      }
    })
  }

  yield put({
    type: 'led/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SEARCH_LED({ payload }) {
  yield put({
    type: 'led/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(ledService.searchLed, SearchLedReq.of(payload))
  if (data) {
    yield put({
      type: 'led/SET_STATE',
      payload: {
        list: LedList.fromData(data),
        detail: Led.empty()
      }
    })
  }

  yield put({
    type: 'led/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_LED({ payload }) {
  yield put({
    type: 'led/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(ledService.createLed, CreateLedReq.of(payload))
  if (data) {
    yield put({
      type: 'led/ADD_STATE',
      payload: {
        led: Led.fromData(data)
      }
    })
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'led/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_LED({ payload }) {
  yield put({
    type: 'led/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(ledService.updateLed, UpdateLedReq.of(payload))
  if (success) {
    yield put({
      type: 'led/SET_STATE',
      payload: {
        detail: Led.from(payload)
      }
    })
    yield history.push('/catalog/product/led/view')
    notification.success({
      message: translate("notification.info"),
      description: translate("notification.message.successOperation"),
    })
  }

  yield put({
    type: 'led/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_LED({ payload }) {
  yield put({
    type: 'led/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { ledId } = payload
  const success = yield call(ledService.deleteLed, ledId)
  if (success) {
    yield put({
      type: 'led/DELETE_STATE',
      payload: { ledId }
    })
  }

  yield put({
    type: 'led/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_LED({ payload, callback }) {
  yield put({
    type: 'led/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(ledService.loadLed, LoadLedReq.of(payload))
  if (data) {
    callback.onSuccess()
  } else {
    callback.onError()
  }

  yield put({
    type: 'led/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.INIT_LED, INIT_LED),
    takeEvery(actions.LIST_LED, LIST_LED),
    takeEvery(actions.READ_LED, READ_LED),
    takeEvery(actions.SEARCH_LED, SEARCH_LED),
    takeEvery(actions.CREATE_LED, CREATE_LED),
    takeEvery(actions.UPDATE_LED, UPDATE_LED),
    takeEvery(actions.DELETE_LED, DELETE_LED),
    takeEvery(actions.LOAD_LED, LOAD_LED),
  ])
}

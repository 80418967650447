export default class CreateDeliveryReq {
  constructor(name, type, prio) {
    this.name = name
    this.type = type
    this.prio = prio
  }

  static of(data) {
    const { name, type, prio } = data
    return new CreateDeliveryReq(name, type, prio)
  }
}

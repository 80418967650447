export default class ListProductReq {
  constructor(page,
              size,
              code,
              originalCode,
              firm,
              firmCode,
              chinaCode,
              ledCount,
              ledLengthStart,
              ledLengthEnd,
              tvModel,
              tvPanel,
              tvSize,
              tvBrand) {
    this.page = page
    this.size = size
    this.code = code
    this.originalCode = originalCode
    this.firm = firm
    this.firmCode = firmCode
    this.chinaCode = chinaCode
    this.ledCount = ledCount
    this.ledLengthStart = ledLengthStart
    this.ledLengthEnd = ledLengthEnd
    this.tvModel = tvModel
    this.tvPanel = tvPanel
    this.tvSize = tvSize
    this.tvBrand = tvBrand
  }

  static of(data) {
    const {
      page,
      size,
      code,
      originalCode,
      firm,
      firmCode,
      chinaCode,
      ledCount,
      ledLengthStart,
      ledLengthEnd,
      tvModel,
      tvPanel,
      tvSize,
      tvBrand,
    } = data
    return new ListProductReq(
      page,
      size,
      code,
      originalCode,
      firm,
      firmCode,
      chinaCode,
      ledCount,
      ledLengthStart,
      ledLengthEnd,
      tvModel,
      tvPanel,
      tvSize,
      tvBrand)
  }
}

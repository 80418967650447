import { all, call, put, takeEvery } from 'redux-saga/effects'

import * as statisticsService from 'services/statistics'
import actions from 'redux/statistics/actions'
import DailyOrderStatistics from 'model/statistics/DailyOrderStatistics'
import DailyCartStatistics from 'model/statistics/DailyCartStatistics'

export function* GET_ORDER_STATISTICS() {
  yield put({
    type: 'statistics/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(statisticsService.getDailyOrderStatistics)
  if (data) {
    yield put({
      type: 'statistics/SET_STATE',
      payload: {
        order: DailyOrderStatistics.from(data),
      },
    })
  }

  yield put({
    type: 'statistics/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* GET_CART_STATISTICS() {
  yield put({
    type: 'statistics/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(statisticsService.getDailyCartStatistics)
  if (data) {
    yield put({
      type: 'statistics/SET_STATE',
      payload: {
        cart: DailyCartStatistics.from(data),
      },
    })
  }

  yield put({
    type: 'statistics/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ORDER_STATISTICS, GET_ORDER_STATISTICS),
    takeEvery(actions.GET_CART_STATISTICS, GET_CART_STATISTICS),
  ])
}

export default class ListDiscountRuleReq {
  constructor(page, size, subjectType, subjectIdList, objectType, objectIdList) {
    this.page = page
    this.size = size
    this.subjectType = subjectType
    this.subjectIdList = subjectIdList
    this.objectType = objectType
    this.objectIdList = objectIdList
  }

  static of(data) {
    const { page, size, subjectType, subjectIdList, objectType, objectIdList } = data
    return new ListDiscountRuleReq(page, size, subjectType, subjectIdList, objectType, objectIdList)
  }
}

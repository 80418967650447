import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'

export async function listCache() {
  return apiClient
    .get(urls.cache.list)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function clearAllCache() {
  return apiClient
    .delete(urls.cache.clearAll)
    .then(() => {
    })
    .catch(err => console.log(err))
}

export async function clearCacheByName(cacheName) {
  const url = format(urls.cache.clearByName, [cacheName])

  return apiClient
    .delete(url)
    .then(() => {
    })
    .catch(err => console.log(err))
}

export async function clearCacheByKey(cacheName, cacheKey) {
  const url = format(urls.cache.clearByKey, [cacheName, cacheKey])

  return apiClient
    .delete(url)
    .then(() => {
    })
    .catch(err => console.log(err))
}

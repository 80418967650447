const actions = {
  LIST_INVENTORY: 'inventory/LIST_INVENTORY',
  LIST_ADJUSTMENT_HISTORY: 'inventory/LIST_ADJUSTMENT_HISTORY',
  SEARCH_INVENTORY: 'inventory/SEARCH_INVENTORY',
  READ_INVENTORY: 'inventory/READ_INVENTORY',
  CREATE_INVENTORY: 'inventory/CREATE_INVENTORY',
  ADJUST_INVENTORY: 'inventory/ADJUST_INVENTORY',
  DELETE_INVENTORY: 'inventory/DELETE_INVENTORY',
  LIST_REGION: 'inventory/LIST_REGION',
  READ_REGION: 'inventory/READ_REGION',
  CREATE_REGION: 'inventory/CREATE_REGION',
  UPDATE_REGION: 'inventory/UPDATE_REGION',
  DELETE_REGION: 'inventory/DELETE_REGION',
  SET_STATE: 'inventory/SET_STATE',
  ADD_STATE: 'inventory/ADD_STATE',
  DELETE_STATE: 'inventory/DELETE_STATE',
}

export default actions

import actions from 'redux/category/actions'
import Category from 'model/product/Category'
import CategoryList from 'model/product/CategoryList'

const initialState = {
  list: CategoryList.empty(),
  listFlat: CategoryList.empty(),
  listParent: CategoryList.empty(),
  detail: Category.empty(),
  loading: false,
}

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.category)
      state.listFlat = state.listFlat.add(action.payload.category)
      if (action.payload.category.children) {
        state.listParent = state.listParent.add(action.payload.category)
      }
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.categoryId)
      state.listParent = state.listParent.remove(action.payload.categoryId)
      state.listFlat = state.listFlat.remove(action.payload.categoryId)
      return state
    }
    default:
      return state
  }
}

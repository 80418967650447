import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { translate } from 'localization'
import { history } from 'index'
import * as contactFormService from 'services/ecom/contactform'
import actions from 'redux/contactform/actions'
import ContactForm from 'model/ecom/contactform/ContactForm'
import ContactFormList from 'model/ecom/contactform/ContactFormList'
import ListContactFormReq from 'model/ecom/contactform/req/ListContactFormReq'
import SearchContactFormReq from 'model/ecom/contactform/req/SearchContactFormReq'

export function* LIST_CONTACTFORM({ payload }) {
  yield put({
    type: 'contactform/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(contactFormService.listContactForm, ListContactFormReq.of(payload))
  if (data) {
    yield put({
      type: 'contactform/SET_STATE',
      payload: {
        list: ContactFormList.fromData(data),
        detail: ContactForm.empty(),
      },
    })
  }

  yield put({
    type: 'contactform/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* SEARCH_CONTACTFORM({ payload }) {
  yield put({
    type: 'contactform/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const data = yield call(contactFormService.searchContactForm, SearchContactFormReq.of(payload))
  if (data) {
    yield put({
      type: 'contactform/SET_STATE',
      payload: {
        list: ContactFormList.fromData(data),
        detail: ContactForm.empty(),
      },
    })
  }

  yield put({
    type: 'contactform/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* READ_CONTACTFORM({ payload }) {
  yield put({
    type: 'contactform/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { contactFormId } = payload
  const data = yield call(contactFormService.readContactForm, contactFormId)
  if (data) {
    yield put({
      type: 'contactform/SET_STATE',
      payload: {
        detail: ContactForm.fromData(data),
      },
    })
  }

  yield put({
    type: 'contactform/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* COMPLETE_CONTACTFORM({ payload }) {
  yield put({
    type: 'contactform/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { contactFormId } = payload
  const success = yield call(contactFormService.completeContactForm, contactFormId)
  if (success) {
    yield put({
      type: 'contactform/UPDATE_STATE',
      payload: {
        updated: {
          id: contactFormId,
          completed: true,
        },
      },
    })

    yield history.push('/ecom/contactform/view')
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successOperation'),
    })
  }

  yield put({
    type: 'contactform/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DELETE_CONTACTFORM({ payload }) {
  yield put({
    type: 'contactform/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { contactFormId } = payload
  const success = yield call(contactFormService.deleteContactForm, contactFormId)
  if (success) {
    yield put({
      type: 'contactform/DELETE_STATE',
      payload: { contactFormId },
    })
  }

  yield put({
    type: 'contactform/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST_CONTACTFORM, LIST_CONTACTFORM),
    takeEvery(actions.SEARCH_CONTACTFORM, SEARCH_CONTACTFORM),
    takeEvery(actions.READ_CONTACTFORM, READ_CONTACTFORM),
    takeEvery(actions.COMPLETE_CONTACTFORM, COMPLETE_CONTACTFORM),
    takeEvery(actions.DELETE_CONTACTFORM, DELETE_CONTACTFORM),
  ])
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'

import { history } from 'index'
import { translate } from 'localization'
import { removeTokens, setTokens } from 'helpers/token'
import { clearMenuSettings } from 'helpers/menu'
import * as authService from 'services/auth'
import * as userService from 'services/user'
import actions from 'redux/auth/actions'
import AuthUser from 'model/auth/AuthUser'
import LoginReq from 'model/auth/req/LoginReq'
import ForgotPasswordReq from 'model/auth/req/ForgotPasswordReq'

export function* LOGIN({ payload }) {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const data = yield call(authService.login, LoginReq.of(payload))
  if (data) {
    yield put({
      type: 'auth/LOAD_CURRENT_USER',
    })

    const { value, refreshToken } = data.accessToken
    setTokens(value, refreshToken)

    yield history.push('/')
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.successLogin'),
    })

    yield put({
      type: 'menu/GET_DATA',
    })

    yield put({
      type: 'tenant/GET_DATA',
    })
  }
  if (!data) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOGOUT() {
  const success = yield call(authService.logout)
  if (success) {
    yield put({
      type: 'auth/SET_STATE',
      payload: AuthUser.emptyUser(),
    })

    removeTokens()
    clearMenuSettings()
  }
}

export function* FORGOT_PASSWORD({ payload }) {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: false,
    },
  })

  const success = yield call(userService.forgotPassword, ForgotPasswordReq.of(payload))
  if (success) {
    notification.success({
      message: translate('notification.info'),
      description: translate('notification.message.checkMail'),
    })
  }
  if (!success) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_USER() {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(userService.readCurrentUser)
  if (response) {
    const { username, personal, role } = response
    yield put({
      type: 'auth/SET_STATE',
      payload: new AuthUser(
        username,
        personal.firstName,
        personal.lastName,
        personal.primaryEmail,
        personal.phoneNo,
        role.name,
        true,
      ),
    })
  }
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.LOAD_CURRENT_USER, LOAD_CURRENT_USER),
    LOAD_CURRENT_USER(), // run once on app load to check user auth
  ])
}

export default class SearchCategoryReq {
  constructor(searchText) {
    this.searchText = searchText
  }

  static of(data) {
    const { searchText } = data
    return new SearchCategoryReq(searchText)
  }
}

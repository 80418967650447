export default class ListCustomerReq {
  constructor(page,
              size,
              companyName,
              email,
              city,
              status,
              groupId,
              relatedUser,
              authorizedUser) {
    this.page = page
    this.size = size
    this.companyName = companyName
    this.email = email
    this.city = city
    this.status = status
    this.groupId = groupId
    this.relatedUser = relatedUser
    this.authorizedUser = authorizedUser
  }

  static of(data) {
    const { page, size, companyName, email, city, status, groupId, relatedUser, authorizedUser } = data
    return new ListCustomerReq(page, size, companyName, email, city, status, groupId, relatedUser, authorizedUser)
  }
}

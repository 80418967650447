export default class Banner {
  constructor(id, url, prio) {
    this.id = id
    this.url = url
    this.prio = prio
  }

  static empty() {
    return new Banner(0, "", "")
  }

  static fromData(data) {
    return this.from(data.banner)
  }

  static from(data) {
    const { id, url, prio } = data
    return new Banner(id, url, prio)
  }
}

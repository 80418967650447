import FaultTraceChain from 'model/fault/FaultTraceChain'

export default class FaultBody {
  constructor(contents) {
    this.contents = contents
  }

  static empty() {
    return new FaultBody([])
  }

  static from(data) {
    const { contents } = data
    return new FaultBody(FaultTraceChain.from(contents))
  }

  prettyString = () => {
    const traceList = (this.contents.traces || []).map(trace => trace.frames.map(frame => `${frame.prettyString()}\n`))
    return traceList.join("\n==================\n")
  }
}

import axios from 'axios'

import RequestAccessTokenInterceptor from 'services/axios/interceptor/RequestAccessTokenInterceptor'
import ResponseErrorInterceptor from 'services/axios/interceptor/ResponseErrorInterceptor'

import { getLanguage } from 'helpers/lang'
import packageJson from '../../../package.json'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 60000,
  headers: {
    'X-Platform': 'ADMIN',
    'X-Language': getLanguage(),
    'X-Customer': '',
    'X-Client-Version': packageJson.version,
  },
})

apiClient.interceptors.request.use(request => {
  return RequestAccessTokenInterceptor(request)
})

apiClient.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return ResponseErrorInterceptor(error)
  },
)

export default apiClient

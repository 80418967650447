export default class ListFaultReq {
  constructor(page, size, correlationId, begin, end, username, platform, environment, appVersion) {
    this.page = page
    this.size = size
    this.correlationId = correlationId
    this.begin = begin
    this.end = end
    this.username = username
    this.platform = platform
    this.environment = environment
    this.appVersion = appVersion
  }

  static of(data) {
    const { page, size, correlationId, begin, end, username, platform, environment, appVersion } = data
    return new ListFaultReq(page, size, correlationId, begin, end, username, platform, environment, appVersion)
  }
}

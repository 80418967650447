import store from 'store'

import actions from 'redux/settings/actions'

const STORED_SETTINGS = storedSettings => {
  const settings = {}
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.settings.${key}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return settings
}

const initialState = {
  ...STORED_SETTINGS({
    tenantLocale: 'tr-TR',
    initialPage: 'dashboard/home',
    isMobileView: false,
    isMobileMenuOpen: false,
    isMenuCollapsed: false,
    isMenuSingleOpenKey: true,
    routerAnimation: 'slide-fadein-up',
    menuColor: 'white',
    theme: 'default',
    primaryColor: '#4b7cf3',
    leftMenuWidth: 256,
  }),
}

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

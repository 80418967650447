export default class ForgotPasswordReq {
  constructor(email) {
    this.email = email
  }

  static of(data) {
    const { email } = data
    return new ForgotPasswordReq(email)
  }
}

export default class FutureFeature {
  constructor(prio) {
    this.prio = prio
  }

  static empty() {
    return new FutureFeature(0)
  }

  static from(data) {
    const { prio } = data
    return new FutureFeature(prio)
  }
}

export default class CreateProductReq {
  constructor(
    code,
    name,
    desc,
    barcode,
    serialNo,
    goodType,
    boxAmount,
    boxAmountDesc,
    hidden,
    hiddenReason,
    openCompetition,
    allowAddToCart,
    available,
    ignoreInventory,
    publicPrice,
    bestSellerPrio,
    vitrinePrio,
    recentPrio,
    futurePrio,
    soldOutPrio,
    campaignPrio,
    minQuantityInCart,
    categoryId,
    categoryPrio,
    brandId,
    brandPrio,
    price,
    curr,
    tagList,
    relatedItemList,
    mediaList,
  ) {
    this.code = code
    this.name = name
    this.desc = desc
    this.barcode = barcode
    this.serialNo = serialNo
    this.goodType = goodType
    this.boxAmount = boxAmount
    this.boxAmountDesc = boxAmountDesc
    this.hidden = hidden
    this.hiddenReason = hiddenReason
    this.openCompetition = openCompetition
    this.allowAddToCart = allowAddToCart
    this.available = available
    this.ignoreInventory = ignoreInventory
    this.publicPrice = publicPrice
    this.bestSellerPrio = bestSellerPrio
    this.vitrinePrio = vitrinePrio
    this.recentPrio = recentPrio
    this.futurePrio = futurePrio
    this.soldOutPrio = soldOutPrio
    this.campaignPrio = campaignPrio
    this.minQuantityInCart = minQuantityInCart
    this.categoryId = categoryId
    this.categoryPrio = categoryPrio
    this.brandId = brandId
    this.brandPrio = brandPrio
    this.price = price
    this.curr = curr
    this.tagList = tagList
    this.relatedItemList = relatedItemList
    this.mediaList = mediaList
  }

  static of(data) {
    const {
      code,
      name,
      desc,
      barcode,
      serialNo,
      goodType,
      boxAmount,
      boxAmountDesc,
      hidden,
      hiddenReason,
      openCompetition,
      allowAddToCart,
      available,
      ignoreInventory,
      bestSellerPrio,
      vitrinePrio,
      recentPrio,
      futurePrio,
      soldOutPrio,
      campaignPrio,
      minQuantityInCart,
      categoryId,
      categoryPrio,
      brandId,
      brandPrio,
      price,
      curr,
      tagList,
      relatedItemList,
      mediaList,
    } = data
    return new CreateProductReq(
      code,
      name,
      desc,
      barcode,
      serialNo,
      goodType,
      boxAmount,
      boxAmountDesc,
      hidden || false,
      hiddenReason,
      openCompetition || false,
      allowAddToCart || true,
      available || true,
      ignoreInventory || true,
      false,
      bestSellerPrio,
      vitrinePrio,
      recentPrio,
      futurePrio,
      soldOutPrio,
      campaignPrio,
      minQuantityInCart || 0,
      categoryId,
      categoryPrio,
      brandId,
      brandPrio,
      price,
      curr,
      tagList || [],
      relatedItemList || [],
      mediaList || [],
    )
  }
}

export default class UpdateUserPasswordReq {
  constructor(password) {
    this.password = password
  }

  static of(data) {
    const { password } = data
    return new UpdateUserPasswordReq(password)
  }
}

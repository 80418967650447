import actions from 'redux/place/actions'

const initialState = {
  cities: [],
  loading: false
}

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    default:
      return state
  }
}

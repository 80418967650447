export default class ListProductReq {
  constructor(page,
              size,
              idList,
              codeList,
              barcode,
              serialNo,
              hidden,
              hiddenReason,
              openCompetition,
              available,
              ignoreInventory,
              bestSeller,
              vitrine,
              recent,
              future,
              soldOut,
              campaign,
              categoryId,
              brandId,
              relatedCode) {
    this.page = page
    this.size = size
    this.idList = idList
    this.codeList = codeList
    this.barcode = barcode
    this.serialNo = serialNo
    this.hidden = hidden
    this.hiddenReason = hiddenReason
    this.openCompetition = openCompetition
    this.available = available
    this.ignoreInventory = ignoreInventory
    this.bestSeller = bestSeller
    this.vitrine = vitrine
    this.recent = recent
    this.future = future
    this.soldOut = soldOut
    this.campaign = campaign
    this.categoryId = categoryId
    this.brandId = brandId
    this.relatedCode = relatedCode
  }

  static of(data) {
    const {
      page,
      size,
      idList,
      codeList,
      barcode,
      serialNo,
      hidden,
      hiddenReason,
      openCompetition,
      available,
      ignoreInventory,
      bestSeller,
      vitrine,
      recent,
      future,
      soldOut,
      campaign,
      categoryId,
      brandId,
      relatedCode
    } = data
    return new ListProductReq(
      page,
      size,
      idList,
      codeList,
      barcode,
      serialNo,
      hidden,
      hiddenReason,
      openCompetition,
      available,
      ignoreInventory,
      bestSeller,
      vitrine,
      recent,
      future,
      soldOut,
      campaign,
      categoryId,
      brandId,
      relatedCode)
  }
}

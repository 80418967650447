import actions from 'redux/user/actions'
import User from 'model/user/User'
import UserList from 'model/user/UserList'
import RoleList from 'model/user/RoleList'

const initialState = {
  list: UserList.empty(),
  detail: User.empty(),
  roleList: RoleList.empty(),
  loading: false
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.SET_DETAIL_STATE: {
      const detail = { ...state.detail, ...action.payload }
      return { ...state, detail }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.user)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.username)
      return state
    }
    default:
      return state
  }
}

import Order from 'model/order/Order'

export default class OrderList {
  constructor(orderList, totalCount) {
    this.orderList = orderList
    this.totalCount = totalCount
  }

  add = order => {
    this.orderList.push(order)
    this.totalCount += 1

    return new OrderList(this.orderList, this.totalCount)
  }

  remove = orderCode => {
    this.orderList = this.orderList.filter(order => order.code !== orderCode)
    this.totalCount -= 1

    return new OrderList(this.orderList, this.totalCount)
  }

  static empty() {
    return new OrderList([], 0)
  }

  static fromData(data) {
    return new OrderList(
      data.orderList.map(d => Order.from(d)),
      data.totalCount,
    )
  }
}

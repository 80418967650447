import actions from 'redux/defective/actions'
import DefectiveClaim from 'model/defective/DefectiveClaim'
import DefectiveClaimList from 'model/defective/DefectiveClaimList'

const initialState = {
  list: DefectiveClaimList.empty(),
  detail: DefectiveClaim.empty(),
  loading: false
}

export default function defectiveReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      state.list = state.list.add(action.payload.claim)
      return state
    }
    case actions.DELETE_STATE: {
      state.list = state.list.remove(action.payload.claimId)
      return state
    }
    default:
      return state
  }
}

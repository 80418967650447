export default class CreateCustomerGroupReq {
  constructor(name, description) {
    this.name = name
    this.description = description
  }

  static of(data) {
    const { name, description } = data
    return new CreateCustomerGroupReq(name, description)
  }
}

import InventoryRegion from 'model/inventory/InventoryRegion'

export default class InventoryRegionList {
  constructor(regionList, totalCount) {
    this.regionList = regionList
    this.totalCount = totalCount
  }

  getByName = name => {
    const found = this.regionList.filter(p => p.name === name)
    if (found.length > 0) {
      return found[0]
    }
    return null
  }

  add = region => {
    this.regionList.push(region)
    this.totalCount += 1

    return new InventoryRegionList(this.regionList, this.totalCount)
  }

  remove = regionId => {
    this.regionList = this.regionList.filter(c => c.id !== regionId)
    this.totalCount -= 1

    return new InventoryRegionList(this.regionList, this.totalCount)
  }

  static empty() {
    return new InventoryRegionList([], 0)
  }

  static fromData(data) {
    return new InventoryRegionList(
      data.regionList.map(d => InventoryRegion.from(d)),
      data.totalCount,
    )
  }
}

export default class PhysicalGood {
  constructor(boxAmount, boxAmountDesc) {
    this.boxAmount = boxAmount
    this.boxAmountDesc = boxAmountDesc
  }

  static empty() {
    return new PhysicalGood(0, "")
  }

  static from(data) {
    const { boxAmount, boxAmountDesc } = data
    return new PhysicalGood(boxAmount, boxAmountDesc)
  }
}

import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'
import qs from "qs";

export async function listCategory(req) {
  return apiClient
    .get(urls.product.category.list, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function listFlatCategory(req) {
  return apiClient
    .get(urls.product.category.listFlat, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readCategory(categoryId) {
  const url = format(urls.product.category.read, [categoryId])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function searchCategory(req) {
  return apiClient
    .get(urls.product.category.search, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function exportCategory(req) {
  return apiClient
    .get(urls.product.category.export, {
      timeout: 60000,
      params: req,
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function createCategory(req) {
  return apiClient
    .post(urls.product.category.create, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function updateCategory(req) {
  return apiClient
    .put(urls.product.category.update, req)
    .then((response) => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function deleteCategory(categoryId) {
  const url = format(urls.product.category.delete, [categoryId])

  return apiClient
    .delete(url)
    .then((response) => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function readCategoryUserSettings(username) {
  const url = format(urls.product.category.readSettings, [username])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function saveCategoryUserSettings(req) {
  return apiClient
    .post(urls.product.category.saveSettings, req)
    .then((response) => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}


export function format(value, args) {
  let a = value
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const k in args) {
    a = a.replace(new RegExp(`\\{${k}\\}`, 'g'), args[k])
  }
  return a
}

export function translateToggle(value) {
  if (value) {
    return 'Aktif'
  }

  return 'Pasif'
}

export function searchInText(value, searchText) {
  return (
    replaceTurkishLetters(value)
      .toLowerCase()
      .indexOf(replaceTurkishLetters(searchText).toLowerCase()) >= 0
  )
}

export function replaceTurkishLetters(value) {
  return value
    .replace('Ğ', 'G')
    .replace('ğ', 'g')
    .replace('Ç', 'C')
    .replace('ç', 'c')
    .replace('Ş', 'S')
    .replace('ş', 's')
    .replace('Ü', 'U')
    .replace('ü', 'u')
    .replace('Ö', 'O')
    .replace('ö', 'o')
    .replace('ı', 'i')
    .replace('İ', 'I')
}

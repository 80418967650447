import ProductImage from 'model/order/ProductImage'

export default class LineProduct {
  constructor(id, code, name, categoryName, brandName, discountAmount, currency, image) {
    this.id = id
    this.code = code
    this.name = name
    this.categoryName = categoryName
    this.brandName = brandName
    this.image = image
  }

  static empty() {
    return new LineProduct(0, '', '', '', '', 0, '', ProductImage.empty())
  }

  static from(data) {
    const { id, code, name, categoryName, brandName, image } = data
    return new LineProduct(id, code, name, categoryName, brandName, image)
  }
}

import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'

export async function getDailyOrderStatistics() {
  return apiClient
    .get(urls.statistics.order.daily)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function getDailyCartStatistics() {
  return apiClient
    .get(urls.statistics.cart.daily)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

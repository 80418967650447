import { getAccessToken } from 'helpers/token'

export default function requestAccessTokenInterceptor(request) {
  const accessToken = getAccessToken()
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
}

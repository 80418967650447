export default class ProductUserSettings {
  constructor(
    username,
    hidePrice,
    hideAvailability,
    hideDiscount,
    hideOpenCompetition,
    hideCampaign,
    hideFuture,
    hideRecent,
    hideLedSearch,
    filterIfStockOut,
    filterIfOpenCompetition,
    allowMarketplaceExport,
  ) {
    this.username = username
    this.hidePrice = hidePrice
    this.hideAvailability = hideAvailability
    this.hideDiscount = hideDiscount
    this.hideOpenCompetition = hideOpenCompetition
    this.hideCampaign = hideCampaign
    this.hideFuture = hideFuture
    this.hideRecent = hideRecent
    this.hideLedSearch = hideLedSearch
    this.filterIfStockOut = filterIfStockOut
    this.filterIfOpenCompetition = filterIfOpenCompetition
    this.allowMarketplaceExport = allowMarketplaceExport
  }

  static empty() {
    return new ProductUserSettings(
      '',
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    )
  }

  static fromData(data) {
    return this.from(data.settings)
  }

  static from(data) {
    const {
      username,
      hidePrice,
      hideAvailability,
      hideDiscount,
      hideOpenCompetition,
      hideCampaign,
      hideFuture,
      hideRecent,
      hideLedSearch,
      filterIfStockOut,
      filterIfOpenCompetition,
      allowMarketplaceExport,
    } = data

    return new ProductUserSettings(
      username,
      hidePrice,
      hideAvailability,
      hideDiscount,
      hideOpenCompetition,
      hideCampaign,
      hideFuture,
      hideRecent,
      hideLedSearch,
      filterIfStockOut,
      filterIfOpenCompetition,
      allowMarketplaceExport,
    )
  }
}

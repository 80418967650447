import actions from 'redux/audit/actions'
import Audit from 'model/audit/Audit'
import AuditList from 'model/audit/AuditList'

const initialState = {
  list: AuditList.empty(),
  detail: Audit.empty(),
  loading: false
}

export default function auditReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.SET_DETAIL: {
      state.detail = state.list.getByCorrelationId(action.payload.correlationId)
      return state
    }
    default:
      return state
  }
}

export default class SocialAccount {
  constructor(type, url) {
    this.type = type
    this.url = url
  }

  static empty() {
    return new SocialAccount("", "")
  }

  static from(data) {
    const { type, url } = data
    return new SocialAccount(type, url)
  }
}

import actions from 'redux/product/actions'
import Product from 'model/product/Product'
import ProductList from 'model/product/ProductList'
import MediaList from 'model/product/MediaList'

const initialState = {
  list: ProductList.empty(),
  detail: Product.empty(),
  mediaList: MediaList.empty(),
  loading: false
}

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return { ...state, ...action.payload }
    }
    case actions.ADD_STATE: {
      if (action.payload.product) {
        state.list = state.list.add(action.payload.product)
      }

      if (action.payload.media) {
        state.mediaList = state.mediaList.add(action.payload.media)
      }

      return state
    }
    case actions.DELETE_STATE: {
      if (action.payload.productId) {
        state.list = state.list.remove(action.payload.productId)
      }

      if (action.payload.mediaId) {
        state.mediaList = state.mediaList.remove(action.payload.mediaId)
      }

      return state
    }
    default:
      return state
  }
}

export default class ListCategoryReq {
  constructor(idList, name, parentId, showRootCategory) {
    this.idList = idList
    this.name = name
    this.parentId = parentId
    this.showRootCategory = showRootCategory
  }

  static of(data) {
    const { idList, name, parentId, showRootCategory } = data
    return new ListCategoryReq(idList, name, parentId, showRootCategory)
  }
}

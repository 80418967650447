export default class FaultClassInfo {
  constructor(className, message) {
    this.className = className
    this.message = message
  }

  static empty() {
    return new FaultClassInfo("", "")
  }

  static from(data) {
    const { className, message } = data
    return new FaultClassInfo(className, message)
  }
}

export default class ListContactFormReq {
  constructor(page, size, name, email, subject, completed, createdStart, createdEnd) {
    this.page = page
    this.size = size
    this.name = name
    this.email = email
    this.subject = subject
    this.completed = completed
    this.createdStart = createdStart
    this.createdEnd = createdEnd
  }

  static of(data) {
    const { page, size, name, email, subject, completed, createdStart, createdEnd } = data
    return new ListContactFormReq(
      page,
      size,
      name,
      email,
      subject,
      completed,
      createdStart,
      createdEnd,
    )
  }
}

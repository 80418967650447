const actions = {
  LIST_ABOUT: 'about/LIST_ABOUT',
  READ_ABOUT: 'about/READ_ABOUT',
  CREATE_ABOUT: 'about/CREATE_ABOUT',
  UPDATE_ABOUT: 'about/UPDATE_ABOUT',
  DELETE_ABOUT: 'about/DELETE_ABOUT',
  SET_STATE: 'about/SET_STATE',
  ADD_STATE: 'about/ADD_STATE',
  DELETE_STATE: 'about/DELETE_STATE',
}

export default actions

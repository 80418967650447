export default class ProductImage {
  constructor(url, scaledList) {
    this.url = url
    this.scaledList = scaledList
  }

  static empty() {
    return new ProductImage('', {})
  }

  static from(data) {
    const { url, scaledList } = data
    return new ProductImage(url, scaledList)
  }
}

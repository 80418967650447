export default class CreateDefectiveClaimReq {
  constructor(reporter, product, itemCount, arrivalNote, note, arrival, claimDate) {
    this.reporter = reporter
    this.product = product
    this.itemCount = itemCount
    this.arrivalNote = arrivalNote
    this.note = note
    this.arrival = arrival
    this.claimDate = claimDate
  }

  static of(data) {
    const { reporter, product, itemCount, arrivalNote, note, arrival, claimDate } = data
    return new CreateDefectiveClaimReq(reporter, product, itemCount, arrivalNote, note, arrival, claimDate)
  }
}

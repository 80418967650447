export default class ScaledImageMedia {
  constructor(id, scale, url) {
    this.id = id
    this.scale = scale
    this.url = url
  }

  static empty() {
    return new ScaledImageMedia(0, "", "")
  }

  static scales = {
    LARGE: "LARGE",
    MEDIUM: "MEDIUM",
    SMALL: "SMALL",
  }

  static from(data) {
    const { id, scale, url } = data
    return new ScaledImageMedia(id, scale, url)
  }
}

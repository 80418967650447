export default class AssociateRecordToDefectiveClaimReq {
  constructor(process, count) {
    this.process = process
    this.count = count
  }

  static of(data) {
    const { process, count } = data
    return new AssociateRecordToDefectiveClaimReq(process, parseInt(count, 10))
  }
}

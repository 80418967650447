export default class DiscountRule {
  constructor(id, subjectType, subjectId, subjectName, objectType, objectId, objectName, discountAmount, discountType, condition, status) {
    this.id = id
    this.subjectType = subjectType
    this.subjectId = subjectId
    this.subjectName = subjectName
    this.objectType = objectType
    this.objectId = objectId
    this.objectName = objectName
    this.discountAmount = discountAmount
    this.discountType = discountType
    this.condition = condition
    this.status = status
  }

  static SUBJECT_TYPE = {
    CUSTOMER: "CUSTOMER",
    CUSTOMER_GROUP: "CUSTOMER_GROUP",
    ALL: "ALL"
  }

  static OBJECT_TYPE = {
    PRODUCT: "PRODUCT",
    CATEGORY: "CATEGORY",
    ALL: "ALL"
  }

  static DISCOUNT_TYPE = {
    PERCENTAGE: "PERCENTAGE",
    AMOUNT: "AMOUNT",
    FIXED: "FIXED"
  }

  static SUBJECT_TYPES = ["ALL", "CUSTOMER", "CUSTOMER_GROUP"]

  static OBJECT_TYPES = ["ALL", "PRODUCT", "CATEGORY"]

  static getDiscountTypes() {
    return [this.DISCOUNT_TYPE.PERCENTAGE, this.DISCOUNT_TYPE.AMOUNT, this.DISCOUNT_TYPE.FIXED]
  }

  static empty() {
    return new DiscountRule(0, "", 0, "", 0, "", "", 0, "",0, "")
  }

  static fromData(data) {
    return this.from(data.rule)
  }

  static from(data) {
    const { id, subjectType, subjectId, subjectName, objectType, objectId, objectName, discountAmount, discountType, condition, status } = data
    return new DiscountRule(id, subjectType, subjectId, subjectName, objectType, objectId, objectName, discountAmount, discountType, condition, status)
  }
}

export default class CreateNewsReq {
  constructor(title, content, beginDate, untilDate, prio) {
    this.title = title
    this.content = content
    this.beginDate = beginDate
    this.untilDate = untilDate
    this.prio = prio
  }

  static of(data) {
    const { title, content, beginDate, untilDate, prio } = data
    return new CreateNewsReq(title, content, beginDate, untilDate, prio)
  }
}

export default class UpdateProductReq {
  constructor(
    productId,
    code,
    name,
    desc,
    barcode,
    serialNo,
    hidden,
    hiddenReason,
    openCompetition,
    allowAddToCart,
    available,
    ignoreInventory,
    publicPrice,
    bestSellerPrio,
    vitrinePrio,
    recentPrio,
    futurePrio,
    soldOutPrio,
    campaignPrio,
    minQuantityInCart,
    categoryId,
    categoryPrio,
    brandId,
    brandPrio,
    price,
    tagList,
    relatedItemList,
  ) {
    this.productId = productId
    this.code = code
    this.name = name
    this.desc = desc
    this.barcode = barcode
    this.serialNo = serialNo
    this.hidden = hidden
    this.hiddenReason = hiddenReason
    this.openCompetition = openCompetition
    this.allowAddToCart = allowAddToCart
    this.available = available
    this.ignoreInventory = ignoreInventory
    this.publicPrice = publicPrice
    this.bestSellerPrio = bestSellerPrio
    this.vitrinePrio = vitrinePrio
    this.recentPrio = recentPrio
    this.futurePrio = futurePrio
    this.soldOutPrio = soldOutPrio
    this.campaignPrio = campaignPrio
    this.minQuantityInCart = minQuantityInCart
    this.categoryId = categoryId
    this.categoryPrio = categoryPrio
    this.brandId = brandId
    this.brandPrio = brandPrio
    this.price = price
    this.tagList = tagList
    this.relatedItemList = relatedItemList
  }

  static of(data) {
    const {
      productId,
      code,
      name,
      desc,
      barcode,
      serialNo,
      hidden,
      hiddenReason,
      openCompetition,
      allowAddToCart,
      available,
      ignoreInventory,
      bestSellerPrio,
      vitrinePrio,
      recentPrio,
      futurePrio,
      soldOutPrio,
      campaignPrio,
      minQuantityInCart,
      categoryId,
      categoryPrio,
      brandId,
      brandPrio,
      price,
      tagList,
      relatedItemList,
    } = data
    return new UpdateProductReq(
      productId,
      code,
      name,
      desc,
      barcode,
      serialNo,
      hidden,
      hiddenReason,
      openCompetition,
      allowAddToCart,
      available,
      ignoreInventory,
      false,
      bestSellerPrio,
      vitrinePrio,
      recentPrio,
      futurePrio,
      soldOutPrio,
      campaignPrio,
      minQuantityInCart,
      categoryId,
      categoryPrio,
      brandId,
      brandPrio,
      price,
      tagList,
      relatedItemList,
    )
  }
}

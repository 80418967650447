import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'

export async function listNews(req) {
  return apiClient
    .get(urls.news.list, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readNews(newsId) {
  const url = format(urls.news.read, [newsId])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function searchNews(req) {
  return apiClient
    .get(urls.news.search, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function createNews(req) {
  return apiClient
    .post(urls.news.create, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function updateNews(req) {
  return apiClient
    .put(urls.news.update, req)
    .then((response) => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function deleteNews(newsId) {
  const url = format(urls.news.delete, [newsId])

  return apiClient
    .delete(url)
    .then((response) => {
      return !!response
    })
    .catch(err => console.log(err))
}

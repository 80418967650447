import { all, call, put, takeEvery } from 'redux-saga/effects'

import actions from 'redux/tenant/actions'
import getTenant from 'services/tenant'
import Tenant from 'model/tenant/Tenant'
import { isTokenExist } from 'helpers/token'

export function* GET_DATA() {
  if (!isTokenExist()) return false

  const data = yield call(getTenant)
  if (data) {
    yield put({
      type: 'tenant/SET_STATE',
      payload: {
        detail: Tenant.fromData(data),
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DATA, GET_DATA),
    GET_DATA(), // run once on app load to fetch tenant data
  ])
}

const actions = {
  LIST_USER: 'user/LIST_USER',
  READ_USER: 'user/READ_USER',
  SEARCH_USER: 'user/SEARCH_USER',
  CREATE_USER: 'user/CREATE_USER',
  UPDATE_USER_PERSONAL: 'user/UPDATE_USER_PERSONAL',
  UPDATE_USER_ROLE: 'user/UPDATE_USER_ROLE',
  UPDATE_USER_PASSWORD: 'user/UPDATE_USER_PASSWORD',
  LOCK_USER: 'user/LOCK_USER',
  UNLOCK_USER: 'user/UNLOCK_USER',
  ENABLE_USER: 'user/ENABLE_USER',
  DISABLE_USER: 'user/DISABLE_USER',
  LIST_ROLE: 'user/LIST_ROLE',
  SET_STATE: 'user/SET_STATE',
  SET_DETAIL_STATE: 'user/SET_DETAIL_STATE',
  ADD_STATE: 'user/ADD_STATE',
  DELETE_STATE: 'user/DELETE_STATE',
}

export default actions

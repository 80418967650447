export default class News {
  constructor(id, title, content, beginDate, prettyBeginDate, untilDate, prettyUntilDate, prio) {
    this.id = id
    this.title = title
    this.content = content
    this.beginDate = beginDate
    this.prettyBeginDate = prettyBeginDate
    this.untilDate = untilDate
    this.prettyUntilDate = prettyUntilDate
    this.prio = prio
  }

  static empty() {
    return new News(0, "", "", "", "", "", "", 0)
  }

  static fromData(data) {
    return this.from(data.news)
  }

  static from(data) {
    const { id, title, content, beginDate, prettyBeginDate, untilDate, prettyUntilDate, prio } = data
    return new News(id, title, content, beginDate, prettyBeginDate, untilDate, prettyUntilDate, prio)
  }
}

import actions from 'redux/tenant/actions'
import Tenant from 'model/tenant/Tenant'

const initialState = {
  detail: Tenant.empty(),
}

export default function tenantReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default class LoadProductLegacyReq {
  constructor(file) {
    this.file = file
  }

  static of(payload) {
    const { file } = payload
    return new LoadProductLegacyReq(file)
  }
}
